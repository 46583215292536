import { Component, OnInit } from '@angular/core';
import { UserModule } from '../../models/user/user.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  private userData: any = {};
  public uriAdmin: any = '';
  public isVisible: any = false;

  constructor(
    private router: Router,
    private userModule: UserModule,
  ) { }

  ngOnInit() {
    this.userData = this.userModule.getData();
    this.uriAdmin = this.userModule.getUriAdmin();
    if (this.userData && this.userData.id) {

      sessionStorage.getItem('schedulingUriAdm');
      this.router.navigate(['admin', this.uriAdmin, 'agendas']);
    } else {
      this.isVisible = true;
    }
  }

}
