import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { LgpdOptionsModule } from 'src/app/modules/admin/general-settings/general-settings-page/components/lgpd-options/lgpd-options.module';
import { SettingsServicesModule } from '../../../../../../shared/models/settings-services/settings-services.module';
import { ServiceRegisterModule } from '../../service-register.module';
import { NoticeComponent } from '../../../../../../shared/components/notice/notice.component';

@Component({
  selector: 'app-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss'],
})
export class ServiceSettingsComponent implements OnInit {
  public settingsOptions: any;

  @Input() parentForm: FormGroup;
  @Input() parentComponent: MatStepper;

  public settingsServicesData: any = {};
  public lgpdLegalBases: any = {};
  public lgpdGeneralData: any = {};

  constructor(
    public settingsServicesModule: SettingsServicesModule,
    public serviceRegisterModule: ServiceRegisterModule,
    public lgpdOptionsModule: LgpdOptionsModule,
    public notice: NoticeComponent,
  ) { }

  ngOnInit() {
    this.lgpdGeneralData = this.lgpdOptionsModule.getLgpdGeneralData();
    this.settingsOptions = this.settingsServicesModule.getSettingsOptions();
    this.settingsServicesData = this.serviceRegisterModule.getData();
    if (this.settingsServicesData && !this.settingsServicesData.id) {
      this.getGeneralServiceSettings();
    }
    this.lgpdLegalBases = this.lgpdOptionsModule.getLegalBases();
    this.legalBaseRequiredControl();
  }

  getGeneralServiceSettings() {
    const generalServiceSettings = this.settingsServicesModule.getData();
    this.settingsServicesData.duration_id = generalServiceSettings.duration_id
      ? generalServiceSettings.duration_id
      : '';

    this.settingsServicesData.minimum_advance_scheduling_id = generalServiceSettings.minimum_advance_scheduling_id
      ? generalServiceSettings.minimum_advance_scheduling_id
      : '';

    this.settingsServicesData.limit_advance_scheduling_id = generalServiceSettings.limit_advance_scheduling_id
      ? generalServiceSettings.limit_advance_scheduling_id
      : '1';

    this.settingsServicesData.limit_months = generalServiceSettings.limit_months
      ? generalServiceSettings.limit_months
      : '';

    this.settingsServicesData.limit_days = generalServiceSettings.limit_days
      ? generalServiceSettings.limit_days
      : '';

    this.settingsServicesData.minimum_cancellation_period_id = generalServiceSettings.minimum_cancellation_period_id
      ? generalServiceSettings.minimum_cancellation_period_id
      : '';

    this.settingsServicesData.amount = generalServiceSettings.amount
      ? generalServiceSettings.amount
      : 1;
    this.settingsServicesData.allow_segmentation_link_with_student = generalServiceSettings.allow_segmentation_link_with_student
      ? generalServiceSettings.allow_segmentation_link_with_student
      : 0;
  }

  public getData: any = () => {
    return this.settingsServicesData;
  };

  legalBaseRequiredControl(showMessage = false) {
    if(this.settingsServicesData.allow_segmentation_link_with_student == 1 && this.lgpdGeneralData.ativado == 1) {
      this.parentForm.addControl('legal_base', new FormControl('', [Validators.required]));
    } else {
      this.parentForm.removeControl('legal_base');
    }
    this.requiredSegmentsMessage(showMessage)
  }

  requiredSegmentsMessage(showMessage) {
    if(this.settingsServicesData.allow_segmentation_link_with_student) {
      if(showMessage) {
        this.notice.showMessageWarning('Ao habilitar esta opção, se torna necessário incluir pelo menos 01 segmentação na guia de segmentações.');
      }
      this.serviceRegisterModule.setRequiredSegments(true);
    } else {
      this.serviceRegisterModule.setRequiredSegments(false);
    }
  }
}
