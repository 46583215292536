import { Component, OnInit, AfterViewInit, Renderer2} from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd} from '@angular/router';
import { LoaderService } from '../../../shared/components/loader/loader.service';
import { UserModule } from '../../../shared/models/user/user.module';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ColorPaletteGeneratorService } from 'rubeus-components';
import { RbDinamicFaviconService } from '../../../shared/services/rb-dinamic-favicon/rb-dinamic-favicon.service';
import { ScriptService } from "../../../shared/services/script/script.service";


const scriptPath = 'https://intervention.apprbs.com.br/block.js';
declare let blockCrm: any;

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss']
})
export class AdminRootComponent implements OnInit, AfterViewInit {
  title = 'agenda-v2';
  public isLogged: any = false;
  public jwtHelperService = new JwtHelperService();


  public scheduleSettings: any = {};
  public theme: any = {
    rbMainPalette: '#0DA6A6',
    rbAccentPalette: '#717171',
    reduced_institutional_logo: 'assets/img/favicon.png',
  };
  public primaryPaletteSettings: any = {
    colors: [],
    orig: [],
    base: this.theme.rbMainPalette,
    json: '',
    name: '',
    angularCodeString: '',
  };
  public accentPaletteSettings: any = {
    colors: [],
    orig: [],
    base: this.theme.rbAccentPalette,
    json: '',
    name: '',
    angularCodeString: '',
  };

  private jwtDecoded: any;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private userModule: UserModule,
    private rbDinamicFaviconService: RbDinamicFaviconService,
    private colorPaletteGeneratorService: ColorPaletteGeneratorService,
    private renderer: Renderer2,
    private scriptService: ScriptService
  ) { }

  ngOnInit() {
    this.scriptInitialization();
    this.themeManagement();
    this.faviconManagement();
    this.backgroundManagement();
    this.verifyUser();
  }

  ngAfterViewInit() {
    this.router.events
    .subscribe((event) => {
          this.verifyUser();
          if (event instanceof NavigationStart) {
              this.loaderService.setChangesLoaderState(true);
          } else if (
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel
              ) {
              this.loaderService.setChangesLoaderState(false);
          }
      });
  }

  verifyUser() {
    const rbScheduleAdmToken = localStorage.getItem('rbScheduleAdmToken');
    if (rbScheduleAdmToken && rbScheduleAdmToken !== 'undefined' && rbScheduleAdmToken !== 'null') {
      this.jwtDecoded = this.jwtHelperService.decodeToken(localStorage.getItem('rbScheduleAdmToken'));
      if (this.jwtDecoded && this.jwtDecoded.usuario) {
        this.userModule.setData(this.jwtDecoded.usuario);
        this.isLogged = this.userModule.isLogged();
      }
    }
  }

  scriptInitialization() {
    const scriptElement = this.scriptService.loadJsScript(this.renderer, scriptPath);
    scriptElement.onload = () => {
      this.rbxpBlockApp(this.jwtDecoded);
    }
    scriptElement.onerror = () => {
    }
  }


  rbxpBlockApp(jwtDecoded) {
    var blockInterval = setInterval( function () {
      if (typeof blockCrm  !== 'undefined' ) {
          if(jwtDecoded && jwtDecoded.usuario){
              blockCrm.init({ email: jwtDecoded.usuario.email, crm: jwtDecoded.iss });
              clearInterval(blockInterval);
          }
      }
    }, 50);
  }

  themeManagement() {
    this.theme.rbMainPalette = this.scheduleSettings.primary_color;
    this.primaryPaletteSettings.base = this.scheduleSettings.primary_color;
    if (this.primaryPaletteSettings && !this.primaryPaletteSettings.base || this.primaryPaletteSettings.base == 'undefined') {
      this.primaryPaletteSettings.base = '#0DA6A6';
    }
    this.colorPaletteGeneratorService.changePalette(0, this.theme, 'rbMainPalette', this, 'primaryPaletteSettings');
    this.colorPaletteGeneratorService.changePalette(0, this.theme, 'rbAccentPalette', this, 'accentPaletteSettings');
    this.defineTheme();
  }

  defineTheme() {
    const newTheme = {
      rbMainPalette: this.primaryPaletteSettings.colors,
      rbAccentPalette: this.accentPaletteSettings.colors,
    };
    this.colorPaletteGeneratorService.changeTheme(newTheme, this.theme);
  }

  faviconManagement() {
    if (this.scheduleSettings && this.scheduleSettings.reduced_institutional_logo) {
      this.rbDinamicFaviconService.setNewFavicon(this.scheduleSettings.reduced_institutional_logo);
    } else {
      this.rbDinamicFaviconService.setNewFavicon('assets/img/favicon.png');
    }
  }

  backgroundManagement() {
    document.documentElement.style.setProperty(`--rb-bg-color-body`, '#f1f1f1');
  }


}
