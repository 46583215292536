<div fxLayout="row wrap" fxLayoutAlign="center center">
  <div class="rb-padding-10x0" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" fxFlex.gt-sm="0 0 calc(34% - 10px)"
      fxFlex.xs="0 0 calc(100% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Nome do serviço</mat-label>
        <input matInput [formControlName]="'name'" required type="text" [(ngModel)]="settingsServicesData.name" />
        <mat-error>Informe um nome</mat-error>
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon"></mat-icon>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" fxFlex.gt-sm="0 0 calc(34% - 10px)"
      fxFlex.xs="0 0 calc(100% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Tempo de duração</mat-label>
        <mat-select matInput placeholder="Selecione uma opção" [formControlName]="'durationTime'" required
          [(ngModel)]="settingsServicesData.duration_id">
          <mat-option *ngFor="let option of settingsOptions.duration" [value]="option.id">
            {{ option.description }}
          </mat-option>
        </mat-select>
        <mat-hint>Tempo gasto em um atendimento</mat-hint>
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon"
        matTooltip="Será reservado para cada atendimento este período de tempo na agenda."
        [matTooltipPosition]="'above'" matTooltipClass="rb-custom-toolTip">help</mat-icon>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)" fxFlex="0 0 calc(50% - 10px)"
      fxFlex.gt-sm="0 0 calc(34% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Tempo de antecedência</mat-label>
        <mat-select matInput placeholder="Selecione uma opção" [formControlName]="'advanceTime'" required
          [(ngModel)]="settingsServicesData.minimum_advance_scheduling_id">
          <mat-option *ngFor="let option of settingsOptions.minimumAdvanceScheduling" [value]="option.id">
            {{ option.description }}
          </mat-option>
        </mat-select>
        <mat-hint>Tempo de antecedência para o agendamento</mat-hint>
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon"
        matTooltip="É o período mínimo necessário de antecedência para realizar o agendamento on-line."
        [matTooltipPosition]="'above'" matTooltipClass="rb-custom-toolTip">help</mat-icon>
    </div>
    <!-- <div class="rb-padding-10x0" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)" fxFlex="0 0 calc(50% - 10px)"
      fxFlex.gt-sm="0 0 calc(34% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Período limite</mat-label>
        <mat-select id="periodoLimite" matInput placeholder="Selecione uma opção" [formControlName]="'limitTime'"
           [(ngModel)]="settingsServicesData.limit_advance_scheduling_id">
          <mat-option *ngFor="let option of settingsOptions.limitAdvanceScheduling" [value]="option.id">
            {{ option.description }}
          </mat-option>
        </mat-select>
        <mat-hint>Período limite para o agendamento</mat-hint>
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon" matTooltip="Período máximo disponível para agendamentos futuros. Não serão permitidos
        agendamentos para datas após o período escolhido." [matTooltipPosition]="'above'"
        matTooltipClass="rb-custom-toolTip">help</mat-icon>
    </div> -->
    <div class="rb-padding-10x0 div-exclusive" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)" fxFlex="0 0 calc(50% - 10px)"
    fxFlex.gt-sm="0 0 calc(34% - 10px)"> 
      <div fxLayout="row" fxFlex.xs="0 0 calc(50% - 0px)" fxFlex="0 0 calc(50% - 0px)"
        fxFlex.gt-sm="0 0 calc(50% - 0px)">
        <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
          <mat-label class="rb-label-black">Período limite em meses</mat-label>
          <input 
          matInput 
          [formControlName]="'limitMonths'" 
          required type="number" 
          maxLength="3"
          [min]="0"
          [(ngModel)]="settingsServicesData.limit_months" />
        </mat-form-field>
        <mat-icon aria-label="ajuda" class="rb-help-message-icon" matTooltip="Período máximo disponível para agendamentos futuros. Não serão permitidos
          agendamentos para datas após o período escolhido." [matTooltipPosition]="'above'"
          matTooltipClass="rb-custom-toolTip"></mat-icon>
      </div>
      <label class="label-hint-exclusive">Período limite em meses e dias para o agendamento.</label>
      <div fxLayout="row" fxFlex.xs="0 0 calc(50% - 0px)" fxFlex="0 0 calc(50% - 0px)"
        fxFlex.gt-sm="0 0 calc(50% - 0px)">
        <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
          <mat-label class="rb-label-black">Período limite em dias.</mat-label>
          <input 
          matInput 
          [formControlName]="'limitDays'" 
          required type="number" 
          maxLength="2"
          min="0"
          [(ngModel)]="settingsServicesData.limit_days" />
        </mat-form-field>
        <mat-icon aria-label="ajuda" class="rb-help-message-icon" matTooltip="Período máximo disponível para agendamentos futuros. Não serão permitidos
          agendamentos para datas após o período escolhido." [matTooltipPosition]="'above'"
          matTooltipClass="rb-custom-toolTip">help</mat-icon>
      </div>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)" fxFlex="0 0 calc(50% - 10px)"
      fxFlex.gt-sm="0 0 calc(34% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Prazo para cancelamento</mat-label>
        <mat-select matInput placeholder="Selecione uma opção" [formControlName]="'cancellationTime'" required
          [(ngModel)]="settingsServicesData.minimum_cancellation_period_id">
          <mat-option *ngFor="let option of settingsOptions.minimumCancellationPeriod" [value]="option.id">
            {{ option.description }}
          </mat-option>
        </mat-select>
        <mat-hint>Período mínimo para o cancelamento</mat-hint>
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon"
        matTooltip="É o período mínimo necessário de antecedência para o cancelamento on-line do serviço."
        [matTooltipPosition]="'above'" matTooltipClass="rb-custom-toolTip">help</mat-icon>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)" fxFlex="0 0 calc(50% - 10px)"
      fxFlex.gt-sm="0 0 calc(34% - 10px)">
      <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
        <mat-label class="rb-label-black">Quantidade de agendamentos por horário</mat-label>
        <input matInput [formControlName]="'amount'" required type="number" [(ngModel)]="settingsServicesData.amount" />
      </mat-form-field>
      <mat-icon aria-label="ajuda" class="rb-help-message-icon" matTooltip="Defina a quantidade de atendimentos que cada atendente poderá fazer em cada horário. Horários de atendimentos que coincidirem com os de
        atividades cadastradas na Plataforma Rubeus, não estarão disponíveis para o responsável vinculado."
        [matTooltipPosition]="'above'" matTooltipClass="rb-custom-toolTip">help</mat-icon>
    </div>

    <div class="rb-padding-10x0 mb-20 mt-20" fxLayout="row" fxFlex.xs="0 0 calc(100% - 10px)"
      fxFlex="0 0 calc(50% - 10px)" fxFlex.gt-sm="0 0 calc(34% - 10px)" [formGroup]="parentForm">
      <mat-slide-toggle class="rb-break-spaces" 
        (change)="legalBaseRequiredControl(true);"
        color="primary" 
        [formControlName]="'allow_segmentation_link_with_student'"
        [(ngModel)]="settingsServicesData.allow_segmentation_link_with_student">Permitir vincular mais de uma
        segmentação e estudante
        por horário.
      </mat-slide-toggle>
    </div>
    <mat-divider class="rb-mat-divider mb-20" *ngIf="settingsServicesData.allow_segmentation_link_with_student == 1 && lgpdGeneralData.ativado == 1">
    </mat-divider>
    <div class="rb-padding-10x0" fxLayout="row wrap" fxFlex="100"
      *ngIf="settingsServicesData.allow_segmentation_link_with_student == 1 && lgpdGeneralData.ativado == 1">
      <div fxFlex="100">
        <mat-label class="rb-label-big-bold">Configurações de LGPD<span class="rbRequired">*</span></mat-label>
      </div>

      <div class="rb-padding-10x0" fxFlex="100">
        <span class="rbPrefixFieldGrey">
          Ao permitir vincular mais de uma segmentação e estudante, é necessário configurar uma
          base legal para os estudantes cadastrados na plataforma Rubeus.
        </span>
      </div>
      <div fxFlex="100" fxLayout="row">
        <div class="rb-padding-10x0" fxLayout="row" fxFlex.xs="0 0 calc(100% - 46px)" fxFlex="0 0 calc(50% - 46px)"
          fxFlex.gt-sm="0 0 calc(34% - 46px)">
          <mat-form-field appearance="fill" [floatLabel]="'always'" [formGroup]="parentForm" fxFlex="">
            <mat-label class="rb-label-black">Base legal</mat-label>
            <mat-select matInput placeholder="Selecione uma opção" 
              [formControlName]="'legal_base'" 
              [(ngModel)]="settingsServicesData.legal_base">
              <mat-option *ngFor="let option of lgpdLegalBases" [value]="option.id">
                {{ option.base }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
</div>
