import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SaveFieldRegisterService} from './services/save-field-register.service';
// import {SortPipeService} from '../../../../shared/services/sort-pipe/sort-pipe.service';


export interface RubeusFields {
  atributo: string;
  nome: string;
  regra: string;
  tipo: string;
}

@Component({
  selector: 'app-field-register-dialog',
  templateUrl: './field-register-dialog.component.html',
  styleUrls: ['./field-register-dialog.component.scss']
})
export class FieldRegisterDialogComponent implements OnInit {

  public fieldsFormGroup: FormGroup;
  public fieldData: any = {};
  public manualValueDefined: any = 0;
  public hideHintHelp: any = 0;
  public isCpfType: any = 0;
  filteredFields: Observable<RubeusFields[]>;

  availableFieldOptions: RubeusFields[] = new Array();
  constructor(
    public saveFieldRegisterService: SaveFieldRegisterService,
    // public sortPipeService: SortPipeService,
    private dialogRef: MatDialogRef<FieldRegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
    this.hideHintHelp = 0;
    this.isCpfType = 0;
    this.fieldsFormGroup = new FormGroup({
      attribute: new FormControl('', [Validators.required, this.requireMatch]),
      title: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      hint: new FormControl('', [Validators.maxLength(50)]),
      help: new FormControl('', [Validators.maxLength(100)]),
      required: new FormControl(''),
    });
    this.availableFieldOptions = this.sortArray(this.dataDialog.availableFieldOptions);
    this.fieldData = this.dataDialog.fieldData;

    if (this.fieldsFormGroup.get('attribute')) {
      this.filteredFields = this.fieldsFormGroup.get('attribute').valueChanges
      .pipe(
        startWith(''),
        map(option => option ? this.filterOptions(option) : this.availableFieldOptions.slice())
      );
    }
  }

  sortArray(array) {
    return array.filter(option =>
      option.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')).sort((a, b) => {
      if ( a.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
      b.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') ) {
        return -1;
      } else if ( a.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
      b.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ngOnInit() {
    if (this.fieldData && this.fieldData.attribute) {
      if (this.fieldData.attribute === 'evento.data') {
        this.hideHintHelp = 1;
      }
      if (this.fieldData.attribute === 'pessoa.cpf') {
        this.isCpfType = 1;
        this.fieldData.required = true;
      }
      this.findSelectedFieldOption();
      if (this.fieldData.cannot_delete || (this.fieldData && this.fieldData.id)) {
        this.fieldsFormGroup.get('attribute').disable();
      }
    }
  }

  findSelectedFieldOption() {
    let attr: any = null;
    for (const option of this.availableFieldOptions) {
      if (option.atributo === this.fieldData.attribute) {
        attr = option;
      }
    }
    if (attr) {
      this.fieldData.attribute = attr;
    }
  }

  saveField() {
    const dataSave = this.buildDataToSave(this.fieldData);
    this.saveFieldRegisterService.resolve(dataSave).then((response) => {
      if (response && response.success) {
        this.dialogRef.close(true);
      }
    });
  }

  buildDataToSave(data) {
    const response = JSON.parse(JSON.stringify(data));
    response.crm_custom_field_id = data.attribute.idCampoPersonalizado;
    response.name = data.attribute.nome;
    response.attribute = data.attribute.atributo;
    response.type = data.attribute.tipo;
    if (data.student) {
      response.student = data.student;
    }
    if (data.attribute.tipoTextoLongo) {
      response.type_long_text = data.attribute.tipoTextoLongo;
    }
    if (data.attribute.tipoSexo) {
      response.type_gender = data.attribute.tipoSexo;
    }
    if (data.attribute.tipoDataHora) {
      response.type_date_time = data.attribute.tipoDataHora;
    }
    if (data.attribute.tipoLogico) {
      response.type_logic = data.attribute.tipoLogico;
    }
    if (data.attribute.tipoData) {
      response.type_date = data.attribute.tipoData;
    }
    if (data.attribute.multiValorado) {
      response.multivalue = data.attribute.multiValorado;
    }
    if (data.attribute.temOpcoesPredefinidas) {
      response.has_predefined_options = data.attribute.temOpcoesPredefinidas;
    }
    if (data.attribute.opcoes) {
      response.options = data.attribute.opcoes;
    }
    response.rule = data.attribute.regra ? data.attribute.regra : null;
    response.required = data.required ? 1 : 0;
    return response;
  }

  filterOptions(value: string): RubeusFields[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      // return this.availableFieldOptions.filter(option => option.nome.toLowerCase().match(filterValue));
      return this.availableFieldOptions.filter(option =>
        option.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').match(filterValue)).sort((a, b) => {
        if ( a.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
        b.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') ) {
          return -1;
        } else if ( a.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
        b.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return [];
    }
  }

  displayFn(field?: RubeusFields): string | undefined {
    return field ? field.nome : undefined;
  }


  getSelectedAttributeType() {
    return typeof this.fieldData.attribute;
  }

  requireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
  }

  setManualValueDefined() {
    this.manualValueDefined = 1;
  }

  setTitleField(value) {
    if (value && typeof value === 'object' && value.nome && !this.fieldData.id && !this.manualValueDefined) {
      this.fieldData.title = value.nome;
    }
    if (value.atributo === 'pessoa.cpf') {
      this.isCpfType = 1;
      this.fieldData.required = true;
    } else {
      this.isCpfType = 0;
    }
  }
}
