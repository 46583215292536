import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

@Injectable({
  providedIn: 'root'
})
export class SchedulingFieldsModule {

  public schedulingFields: any = {
    stage1: new Array(),
    stage2: new Array(),
    stage3: new Array(),
  };

  constructor() {
  }

  setData(data: any[], stage) {
    if (stage) {
      if (data) {
        this.schedulingFields[stage] = data;
      } else {
        this.schedulingFields[stage] = new Array();
      }
    }
  }

  setStudentData(data: any[]) {
    if (data) {
      this.schedulingFields['fieldsOfStudent'] = data;
    }else{
      this.schedulingFields['fieldsOfStudent'] = new Array();
    }
  }

  setDefaultFieldsOfStudent(data: any[]) {
    if (data) {
      this.schedulingFields['defaultFieldsOfStudent'] = data;
    }else{
      this.schedulingFields['defaultFieldsOfStudent'] = new Array();
    }
  }

  getData() {
    return this.schedulingFields;
  }

  getStage1() {
    return this.schedulingFields.stage1;
  }

  getStage2() {
    return this.schedulingFields.stage2;
  }

  getStudentFieldsData() {
    return this.schedulingFields.fieldsOfStudent;
  }

  getDefaultFieldsOfStudent() {
    return this.schedulingFields.defaultFieldsOfStudent;
  }

  getStage3() {
    return this.schedulingFields.stage3;
  }

}






