<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">Atenção</h2>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <form fxLayout="row" (ngSubmit)="parentScope.saveStages()" [formGroup]="lgpdTermsForm"
      method="post" fxLayout="row wrap" fxFlex="100" style="overflow: auto; padding: 0 24px;">
      <!-- OPCOES LGPD -->
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="lgpdSettingsData.idCaixaVerificacao &&
    (lgpdSettingsData.consentimentoComunicacao
    || lgpdSettingsData.privacidade
    || lgpdSettingsData.consentimentoProcessar
    || lgpdSettingsData.rotuloConsentimentoProcesso
    || lgpdSettingsData.rodapeConsentimentoProcesso
    || (lgpdSettingsData.assinaturas
        && lgpdSettingsData.assinaturas.length
        && lgpdSettingsData.assinaturas[0].rotulo)
    )">
        <!-- <div fxFlex="100"> -->

          <p style="font-size: 20px; font-weight: 500;">Nossos termos e políticas de privacidade foram atualizados.</p>

          <div id="consentimentoComunicacaoPreview" *ngIf="lgpdSettingsData.consentimentoComunicacao"
            [innerHTML]="lgpdSettingsData.consentimentoComunicacao"></div>
          <div id="tipoAssinaturaPreview"
            *ngIf="lgpdSettingsData.assinaturas && lgpdSettingsData.assinaturas.length > 0 && lgpdSettingsData.idCaixaVerificacao != 3">
            <!-- <div *ngFor="let item of lgpdSettingsData.assinaturas" fxLayout="row" fxLayoutAlign="start start" class="preview-checkbox-container"> -->
            <div formArrayName="assinaturas"
              *ngFor="let item of lgpdTermsForm.get('assinaturas')['controls']; let i = index" fxLayout="row"
              fxLayoutAlign="start start" class="preview-checkbox-container">
              <div fxLayout="row wrap">
                <mat-checkbox color="primary" [formControlName]="i" [required]="lgpdSettingsData.assinaturas[i].obrigatorio"
                  [(ngModel)]="lgpdSettingsData.assinaturas[i].checked">
                </mat-checkbox>
              </div>
              <div class="preview-checkbox-label-container" fxFlex=""
                [innerHTML]="lgpdSettingsData.assinaturas[i].rotulo"></div>
            </div>
          </div>
          <div id="privacidadePreview" *ngIf="lgpdSettingsData.privacidade && lgpdSettingsData.idCaixaVerificacao==1"
            [innerHTML]="lgpdSettingsData.privacidade"></div>
          <div id="consentimentoProcessarPreview" *ngIf="lgpdSettingsData.consentimentoProcessar"
            [innerHTML]="lgpdSettingsData.consentimentoProcessar"></div>
          <div id="rotuloConsentimentoProcessoPreview" *ngIf="lgpdSettingsData.rotuloConsentimentoProcesso"
            fxLayout="row" fxLayoutAlign="start start" class="preview-checkbox-container">
            <mat-checkbox color="primary" [formControlName]="'consentToProcess'" required
              [(ngModel)]="lgpdSettingsData.consentToProcess">
            </mat-checkbox>
            <div class="preview-checkbox-label-container" [innerHTML]="lgpdSettingsData.rotuloConsentimentoProcesso">
            </div>
          </div>
          <div id="rodapeConsentimentoProcessoPreview" *ngIf="lgpdSettingsData.rodapeConsentimentoProcesso"
            [innerHTML]="lgpdSettingsData.rodapeConsentimentoProcesso"></div>
          <div id="privacidadePreview" *ngIf="lgpdSettingsData.privacidade && lgpdSettingsData.idCaixaVerificacao != 1"
            [innerHTML]="lgpdSettingsData.privacidade"></div>
        <!-- </div> -->
      </div>
    </form>
    <!-- FIM -->
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <div class="rbButtonsDivisor"></div>
      <button mat-raised-button color="primary" [disabled]="lgpdTermsForm.invalid" (click)="acceptLgpdTerms()">
        <span>Continuar</span>
      </button>
    </div>
  </div>
</div>
