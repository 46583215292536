<div class="app-content">
  <div class="router-output">
    <router-outlet></router-outlet>
  </div>
  <!-- <div [hidden]="loading" class="router-output">
    <router-outlet></router-outlet>
  </div> -->
  <!-- <div class="lds-all rbInitialLoader" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
      <div class="lds-rolling" fxLayout="row" fxLayoutAlign="center center">
          <mat-progress-spinner
            [color]="primary"
            [mode]="'indeterminate'">
          </mat-progress-spinner>
          <h1>Carregando...</h1>
      </div>
  </div> -->
</div>