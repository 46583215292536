import { Injectable } from '@angular/core';
import { Request } from '../../../../core/http/request';
import { HttpService } from '../../../../core/http/http.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { NoticeComponent } from '../../../components/notice/notice.component';
import { ClientLoaderService } from '../../../components/client-loader/client-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotService extends Request implements Resolve<ForgotService> {

  private response: any;
  constructor(
    // private user: UserModule,
    private http: HttpService,
    private clientLoaderService: ClientLoaderService,
    protected noticeComponent: NoticeComponent
  ) {
    super();
  }

  resolve(
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.clientLoaderService.setChangesLoaderState(true);
    this.url = 'user/forgot-my-password';
    this.data = parameters;

    return this.http.post(this)
      // .toPromise()
      .then((data) => {
        return this.response;
      });
  }

  successAction(data) {
    this.response = data;
    if (data && data.success) {
      this.noticeComponent.showMessageSuccess('E-mail enviado com sucesso!');
    } else {
      if (data.error) {
        this.noticeComponent.showMessageError(data.error);
      } else if (data.erro) {
        this.noticeComponent.showMessageError(data.erro);
      } else {
        this.noticeComponent.showMessageError(data.errors);
      }
    }
    this.clientLoaderService.setChangesLoaderState(false);
  }

  errorAction(error) {
    this.clientLoaderService.setChangesLoaderState(false);
    if (error.error) {
      this.noticeComponent.showMessageError(error.error);
    } else {
      this.noticeComponent.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }

}
