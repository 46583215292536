import { Component, OnInit } from '@angular/core';
import { LgpdOptionsModule } from 'src/app/modules/admin/general-settings/general-settings-page/components/lgpd-options/lgpd-options.module';
import { GeneralModule } from 'src/app/modules/admin/general-settings/general-settings-page/general.module';
import { environment } from '../../../../environments/environment';
import { UserModule } from '../../models/user/user.module';

@Component({
  selector: 'app-main-bar',
  templateUrl: './main-bar.component.html',
  styleUrls: ['./main-bar.component.scss']
})
export class MainBarComponent implements OnInit {

  public imagesPath = environment.imagesPath;
  public currentPage: any;
  public clientUri: any = '';
  public menuOptions: any = [];
  public requirementSettings: any;
  public requireLgpdSetting: boolean;
  public hasLgpdSelected: any = false;

  constructor(
    public userModule: UserModule,
    private generalModule: GeneralModule,
  ) {
    this.clientUri = '/' + this.userModule.getUriAdmin();
    this.menuOptions = [
      {id: 1, title: 'Agendas', link: '/admin' + this.clientUri + '/agendas', actived: 0},
      {id: 2, title: 'Horários excepcionais', link: '/admin' + this.clientUri + '/horarios-excepcionais', actived: 0},
      {id: 3, title: 'Configurações', link: '/admin' + this.clientUri + '/configuracoes-gerais', actived: 0},
    ];
  }

  ngOnInit() {
    this.requirementSettings = this.generalModule.getData();
    if (this.requirementSettings && this.requirementSettings.requireLgpdSettings) {
        this.requireLgpdSetting = this.requirementSettings.requireLgpdSettings;
    }
  }

}
