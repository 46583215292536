import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ScheduleSettingsModule {

  public scheduleId: any = {};
  public settingsData: any = {};
  public requireLgpdOnService: boolean = false;
  public bkpSettingsData: any = {};
  public hasChange = new Subject<any>();
  public updateSettingsData = new Subject<any>();

  setData(data) {
    if (data) {
      this.settingsData = data;
      this.bkpSettingsData = JSON.parse(JSON.stringify(data));
    } else {
      this.settingsData = {};
      this.bkpSettingsData = {};
    }
  }

  getData() {
    return this.settingsData;
  }

  getSchedulingSettings() {
    return this.settingsData.scheduleSettings ? this.settingsData.scheduleSettings : null;
  }

  getRequireLgpdOnSchedule() {
    return this.settingsData && this.settingsData.requireLgpdSettings ? 1 : 0;
  }

  setScheduleId(id) {
    this.scheduleId = id;
  }

  getScheduleId() {
    return this.scheduleId;
  }

  getInitialData() {
    return this.bkpSettingsData;
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  setRequireLgpdOnService(data) {
    if (data) {
      this.requireLgpdOnService = data;
    } else {
      this.requireLgpdOnService = false;
    }
  }

  getRequireLgpdOnService() {
    return this.requireLgpdOnService;
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }

  setUpdateSettingsData(value: any) {
    this.updateSettingsData.next(value);
  }

  getUpdateSettingsData(): Observable<any> {
    return this.updateSettingsData.asObservable();
  }

}
