import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ScheduleRegisterPageModule {

  public scheduleData: any = {};
  public updatedAt: any =  new Subject<any>();
  public publishable = new Subject<any>();
  public published = new Subject<any>();
  public updatedAtValue: any = null;


  setId(id) {
    this.scheduleData.id = id;
  }

  getId() {
    return this.scheduleData.id;
  }

  setPublished(value) {
    this.published.next(value);
  }

  getPublished(): Observable<any>  {
    return this.published.asObservable();
  }

  setPublishable(value) {
    this.publishable.next(value);
  }

  getPublishable(): Observable<any> {
    return this.publishable.asObservable();
  }

  setUpdated(value) {
    this.updatedAtValue = value;
    this.updatedAt.next(value);
  }

  getUpdated(): Observable<any>  {
    return this.updatedAt.asObservable();
  }

  getUpdateAtValue() {
    return this.updatedAtValue;
  }
}
