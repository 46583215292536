import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Request } from 'src/app/core/http/request';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';
import { ClientUserModule } from './client-user.module';

@Injectable({
  providedIn: 'root'
})
export class ClientUpdateDataService extends Request implements Resolve<ClientUpdateDataService> {

  private response: any;
  public loginParams: any;

  constructor(
    private clientUserModule: ClientUserModule,
    private http: HttpService
    ) {
      super();
    }


  resolve(
    parameters: any
    ): Observable<any> | Promise<any> | any {
      this.url = 'user/update-user-data';
      this.data = parameters;
      this.loginParams = parameters;

      return this.http.post(this)
      .then((data) => {
        return this.response;
      });
    }

    successAction(data: any): void {
      this.response = data;
    }

}
