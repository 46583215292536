import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Resolve} from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../http/request';
import { HttpService } from '../http/http.service';
import { NoticeComponent } from '../../shared/components/notice/notice.component';
import { LoaderService } from '../../shared/components/loader/loader.service';
import {
  ClientManageDomainPageModule
} from '../../shared/components/client-manage-domain/client-manage-domain/client-manage-domain-page.module';
import { ClientSettingsModule } from '../../shared/models/client-settings/client-settings.module';

@Injectable({
  providedIn: 'root'
})
export class ClientManageDomainService extends Request implements Resolve<ClientManageDomainService> {

  protected param = '';
  public url = '';
  public response: any;
  public data: any;
  constructor(
    private http: HttpService,
    private loaderService: LoaderService,
    protected notice: NoticeComponent,
    private platformLocation: PlatformLocation,
    public clientManageDomainPageModule: ClientManageDomainPageModule,
    private clientSettingsModule: ClientSettingsModule,
  ) {
    super();
  }


  resolve(
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    /*  Regra:
        Quando bater no manageDomain devemos retirar qualquer token de sessão,
        para que não corra o risco conflitar sessões e pegar dados de outros agendamentos. */

    // localStorage.removeItem('rbSchedulingClientToken');
    sessionStorage.removeItem('schedulingToken');

    this.url = 'manageDomain';
    this.data = {url: this.platformLocation.href};

    return this.http.post(this)
    .then((data) => {
      return this.response;
    });
  }

  successAction(data) {
    if (data && data.success) {
      this.clientManageDomainPageModule.setData(data);
      this.clientSettingsModule.initDataLayer();

      this.clientSettingsModule.scriptManager(data.scripts);
    } else {
      this.clientManageDomainPageModule.setData(null);
    }
    this.loaderService.setChangesLoaderState(false);
    this.response = data;
  }


  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
