import { Injectable } from '@angular/core';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SchedulingFieldsModule } from './scheduling-fields.module';

@Injectable({
  providedIn: 'root',
})
export class GetSchedulingFieldsService extends Request
  implements Resolve<GetSchedulingFieldsService> {
  private stageId: any = '1';

  constructor(
    private schedulingFieldsModule: SchedulingFieldsModule,
    private http: HttpService
  ) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    if (route.params) {
      this.stageId = this.getStage(route.params.stage);
    }
    this.url = 'client/data-field/' + this.stageId;

    return this.http
      .get(this)
      .then((data) => this.schedulingFieldsModule.getData());
  }

  getStage(stage: any) {
    switch (stage) {
      case 'etapa1':
        return '1';
        break;
      case 'etapa2':
        return '2';
        break;
      case 'etapa3':
        return '3';
        break;
      default:
        return '1';
        break;
    }
  }

  successAction(data) {
    if (data && data.fields) {
      this.schedulingFieldsModule.setData(data.fields, 'stage' + this.stageId);
      if (data && data.fieldsOfStudent) {
        this.schedulingFieldsModule.setStudentData(data.fieldsOfStudent);
        if (data.defaultFieldsOfStudent) {
          this.schedulingFieldsModule.setDefaultFieldsOfStudent(data.defaultFieldsOfStudent);
        }
      }
    } else {
      this.schedulingFieldsModule.setData(null, 'stage' + this.stageId);
    }
  }

  errorAction(error) {
    return false;
  }
}
