import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  GetGeneralIdentityDataService
} from './modules/admin/general-settings/general-settings-page/services/get-general-identity-data.service';
import {
  GetGeneralServicesDataService
} from './modules/admin/general-settings/general-settings-page/services/get-general-services-data.service';
import { GetSettingsServicesOptionsService } from './shared/models/settings-services/get-settings-services-options.service';
import {
  GetExceptionalHoursCrudDataService
} from './modules/admin/exceptional-hours/exceptional-hours-crud-page/services/get-exceptional-hours-crud-data.service';
import { GetScheduleCrudDataService } from './modules/admin/schedules/schedule-crud-page/services/get-schedule-crud-data.service';
import {
  GetExceptionalHoursDataService
} from './modules/admin/exceptional-hours/exceptional-hours-register-page/services/get-exceptional-hours-data.service';
import {
  GetExceptionalTimesActionService
} from './modules/admin/exceptional-hours/exceptional-hours-register-page/services/get-exceptional-times-action.service';
import {
  GetExceptionalTimesScheduleOptionsService
} from './modules/admin/exceptional-hours/exceptional-hours-register-page/services/get-exceptional-times-schedule-options.service';
import {
  GetScheduleDataService
} from './modules/admin/schedules/schedule-register-page/services/get-schedule-data.service';
import {
  GetScheduleSettingsService
} from './modules/admin/schedules/schedule-register-page/services/get-schedule-settings.service';
import {
  AuthenticationService
} from './core/authentication/authentication.service';
import {
  ClientManageDomainService
} from './core/client-manage-domain/client-manage-domain.service';
import {
  GetMySchedulesListDataService
} from './modules/client/my-schedules/my-schedules-page/services/get-my-schedules-list-data.service';
import {AdminRootComponent} from './modules/admin/admin-root/admin-root.component';
import {ClientRootComponent} from './modules/client/client-root/client-root.component';
import {GetClientUserDataService} from './shared/models/client-user/get-client-user-data.service';
import {ClientSettingsService} from './shared/models/client-settings/client-settings.service';
import {GetSchedulingFieldsService} from './shared/models/scheduling-fields/get-scheduling-fields.service';
import {GetSchoolDataService} from './shared/models/school/get-school-data.service';
import {
  CheckCrmClientTokenValidationService
} from './modules/client/crm-client-link-validator/crm-client-link-validator-page/services/check-crm-client-token-validation.service';
import {
  CrmClientLinkValidatorPageComponent
} from './modules/client/crm-client-link-validator/crm-client-link-validator-page/crm-client-link-validator-page.component';
import {
  ClientManageDomainComponent
} from './shared/components/client-manage-domain/client-manage-domain/client-manage-domain.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'validar-dominio', pathMatch: 'full' },
  { path: 'admin', redirectTo: '/admin/agendas', pathMatch: 'full' },

  /* Rotas Painel Admin */
  {
    path: 'admin',
    component: AdminRootComponent,
    children: [
      {
        path: 'autenticar',
        component: PageNotFoundComponent,
        resolve: {
          authenticationService: AuthenticationService,
        },
      },
      {
        path: 'autenticar/:crmInitialAdmToken',
        component: PageNotFoundComponent,
        resolve: {
          authenticationService: AuthenticationService,
        },
      },
      {
        path: ':clientUrl',
        loadChildren: () =>
        import('./modules/admin/admin-routing.module').then(m => m.AdminRoutingModule),
      },
      {
        path: 'usuario-nao-identificado',
        component: PageNotFoundComponent,
      },
    ],
  },

  /* Autenticacao Token Cliente*/
  {
    path: 'autenticacao',
    component: CrmClientLinkValidatorPageComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
      clientSettingsService: ClientSettingsService,
      crmClientTokenValidation: CheckCrmClientTokenValidationService,
      clientUserData: GetClientUserDataService,
    },
  },
  {
    path: 'autenticacao/:schedulingToken',
    component: CrmClientLinkValidatorPageComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
      clientSettingsService: ClientSettingsService,
      crmClientTokenValidation: CheckCrmClientTokenValidationService,
      clientUserData: GetClientUserDataService,
    },
  },
  {
    path: ':uri1/autenticacao/:schedulingToken',
    component: CrmClientLinkValidatorPageComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
      clientSettingsService: ClientSettingsService,
      crmClientTokenValidation: CheckCrmClientTokenValidationService,
      clientUserData: GetClientUserDataService,
    },
  },
  {
    path: ':uri1/:uri2/autenticacao/:schedulingToken',
    component: CrmClientLinkValidatorPageComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
      clientSettingsService: ClientSettingsService,
      crmClientTokenValidation: CheckCrmClientTokenValidationService,
      clientUserData: GetClientUserDataService,
    },
  },
  {
    path: ':uri1/:uri2/:uri3/autenticacao/:schedulingToken',
    component: CrmClientLinkValidatorPageComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
      crmClientTokenValidation: CheckCrmClientTokenValidationService,
      clientUserData: GetClientUserDataService,
    },
  },

  /* Autenticacao Token Cliente*/
  {
    path: 'validar-dominio',
    component: ClientManageDomainComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
    },
  },
  {
    path: ':uri1/validar-dominio',
    component: ClientManageDomainComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
    },
  },
  {
    path: ':uri1/:uri2/validar-dominio',
    component: ClientManageDomainComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
    },
  },
  {
    path: ':uri1/:uri2/:uri3/validar-dominio',
    component: ClientManageDomainComponent,
    resolve: {
      clientManageDomain: ClientManageDomainService,
    },
  },

  // /*Rotas área do cliente*/

  { path: ':uri1', redirectTo: ':uri1/validar-dominio', pathMatch: 'full'  },
  {
    path: ':uri1',
    component: ClientRootComponent,
    resolve: {
      clientSettingsService: ClientSettingsService,
      // clientUserData: GetClientUserDataService,
    },
    children: [
      {
        path: 'agenda',
        loadChildren: () =>
        import('./modules/client/client-routing.module').then(m => m.ClientRoutingModule),
      },
      {
        path: 'agendar',
        loadChildren: () =>
        import('./modules/client/validation-client-routing.module').then(m => m.ValidationClientRoutingModule),
      },
    ]
  },

  { path: ':uri1/:uri2', redirectTo: ':uri1/:uri2/validar-dominio', pathMatch: 'full'},
  {
    path: ':uri1/:uri2',
    component: ClientRootComponent,
    resolve: {
      clientSettingsService: ClientSettingsService,
      // clientUserData: GetClientUserDataService,
    },
    children: [
      {
        path: 'agenda',
        loadChildren: () =>
        import('./modules/client/client-routing.module').then(m => m.ClientRoutingModule),
      },
      {
        path: 'agendar',
        loadChildren: () =>
        import('./modules/client/validation-client-routing.module').then(m => m.ValidationClientRoutingModule),
      },
    ]
  },

  { path: ':uri1/:uri2/:uri3', redirectTo: ':uri1/:uri2/:uri3/validar-dominio', pathMatch: 'full'  },
  {
    path: ':uri1/:uri2/:uri3',
    component: ClientRootComponent,
    resolve: {
      clientSettingsService: ClientSettingsService,
      // clientUserData: GetClientUserDataService,
    },
    children: [
      {
        path: 'agenda',
        loadChildren: () =>
        import('./modules/client/client-routing.module').then(m => m.ClientRoutingModule),
      },
      {
        path: 'agendar',
        loadChildren: () =>
        import('./modules/client/validation-client-routing.module').then(m => m.ValidationClientRoutingModule),
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthenticationService,
    ClientManageDomainService,
    GetGeneralIdentityDataService,
    GetGeneralServicesDataService,
    GetSettingsServicesOptionsService,
    GetExceptionalHoursDataService,
    GetExceptionalTimesActionService,
    GetExceptionalTimesScheduleOptionsService,
    GetScheduleCrudDataService,
    GetScheduleDataService,
    GetScheduleSettingsService,
    GetExceptionalHoursCrudDataService,
    GetClientUserDataService,
    ClientSettingsService,
    GetMySchedulesListDataService,
    GetSchedulingFieldsService,
    GetSchoolDataService,
    CheckCrmClientTokenValidationService,
  ]
})
export class AppRoutingModule { }
