import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ]
})

@Injectable({
  providedIn: 'root'
})

export class GeneralModule {
  public refresh = new Subject<any>();
  public hasChange = new Subject<any>();
  public requirementsData: any;

  setData(data) {
    if (data) {
      this.requirementsData = data;
    }
  }

  getData() {
    return this.requirementsData;
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }


}
