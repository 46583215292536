<!-- <form (ngSubmit)="sendForgot()" [formGroup]="forgotForm" class="form-my-personal-data">
    <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
        <h2 fxFlex="">Esqueci minha senha</h2>
        <button mat-icon-button [mat-dialog-close]="true">
            <mat-icon aria-label="Fechar">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content fxLayout="row wrap">  
        <mat-form-field  appearance="fill" [floatLabel]="'always'"
         fxFlex="0 0 calc(100% - 10px)"
        *ngIf="!showFeedback">
            <mat-label>E-mail</mat-label>
            <input matInput
            [pattern]="emailPattern"
            type="text"
            formControlName="email"
            [(ngModel)]="forgotData.email"
            required>
            <mat-error>Informe seu e-mail</mat-error>
        </mat-form-field>
        <div *ngIf="showFeedback" >
            <p class="forgotPassSuccess">
                Um link de recuperação de senha foi gerado e enviado com sucesso para o endereço de e-mail cadastrado: <b>{{forgotData.email}}</b>
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="rbPaddingBottom" fxLayout="row" fxLayoutAlign="center center">
        <button *ngIf="!showFeedback" type="submit" fxFlex="60" class="rb-custom-button" mat-raised-button color="primary">Enviar</button>
        <button [mat-dialog-close]="true" *ngIf="showFeedback" fxFlex="60" class="rb-custom-button" mat-raised-button color="primary">Ok</button>
    </mat-dialog-actions>
</form> -->

<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">Esqueci minha senha</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <form (ngSubmit)="sendForgot()" [formGroup]="forgotForm">
    <mat-dialog-content class="rb-dialog-content">
        <div fxLayout="row wrap" fxLayoutAlign="center center"
        class="rb-padding-5x24" style="padding-top:24px;">
            <mat-form-field appearance="fill" [floatLabel]="'always'"
            fxFlex="0 0 calc(100% - 10px)"
            *ngIf="!showFeedback">
                <mat-label>E-mail</mat-label>
                <input matInput
                [pattern]="emailPattern"
                type="text"
                formControlName="email"
                [(ngModel)]="forgotData.email"
                required>
                <mat-error>Informe seu e-mail</mat-error>
            </mat-form-field>
            <div *ngIf="showFeedback" >
                <p class="forgotPassSuccess">
                    Um link de recuperação de senha foi gerado e enviado com sucesso 
                    para o endereço de e-mail cadastrado: <b>{{forgotData.email}}</b>
                </p>
            </div>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions class="rb-dialog-actions">
        <div class="rb-padding-10x0" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
          <button mat-raised-button *ngIf="!showFeedback" 
            color="primary"
            type="submit" 
            fxFlex="60" 
            class="rb-custom-button">
            Enviar
          </button>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
          <button mat-raised-button *ngIf="showFeedback"
            color="primary"
            [mat-dialog-close]="true" 
            fxFlex="60" 
            class="rb-custom-button">Ok</button>
        </div>
    </div>
  </form>
</div>