import { Component, OnInit, Input } from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {ListItemsDialogComponent} from '../../../../../../shared/components/list-items-dialog/list-items-dialog.component';
import {AttendanceHoursDialogComponent} from '../../../../../../shared/components/attendance-hours-dialog/attendance-hours-dialog.component';
import {AttendantsServicesModule} from '../../../../../../shared/models/attendants-services/attendants-services.module';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CrudModel } from 'rubeus-components';
import { ServiceRegisterModule } from '../../service-register.module';

@Component({
  selector: 'app-service-attendants',
  templateUrl: './service-attendants.component.html',
  styleUrls: ['./service-attendants.component.scss']
})
export class ServiceAttendantsComponent implements OnInit {

  @Input() parentComponent: MatStepper;
  @Input() parentScope: any;
  public attendantOptions = new Array();
  public attendantIds = new Array();
  public selectedAttendantOptions = new Array();
  public attendantDialogRef: any;
  public attendantHourDialogRef: any;


  public msgCrudEmpty: any = '';
  public fakeData: any[] = new Array();
  public crudSettings: any[] = new Array();
  public crudMetaCount: any = '';


  constructor(
    public attendantDialog: MatDialog,
    public attendantsServicesModule: AttendantsServicesModule,
    private serviceRegisterModule: ServiceRegisterModule,
    private crudModel: CrudModel,
  ) {
    // crudModel.changeStatusFilter(true);
  }


  ngOnInit() {
    this.attendantOptions = this.attendantsServicesModule.getOptions();
    // this.selectedAttendantOptions = this.buildListOptions(this.attendantIds, this.attendantOptions);
    this.selectedAttendantOptions = this.serviceRegisterModule.getSelectedAttendants();
    this.updateAttendantIds();
    this.buildCrud();
  }

  // buildListOptions(ids: any[], options: any[]) {
  //   const result = new Array();
  //   if (ids && ids.length && options && options.length) {
  //     for (const option of options) {
  //       for (const id of ids) {
  //         if (option.id === id) {
  //           result.push(option);
  //         }
  //       }
  //     }
  //   }
  //   return result;
  // }

  // removeAttendant(index) {
  //   if (this.selectedAttendantOptions && this.selectedAttendantOptions.length) {
  //     this.selectedAttendantOptions.splice(index, 1);
  //   }
  //   this.updateAttendantIds();
  // }

  updateAttendantIds() {
    this.attendantIds = new Array();
    if (this.selectedAttendantOptions && this.selectedAttendantOptions.length) {
      for (const option of this.selectedAttendantOptions) {
        this.attendantIds.push(option.id);
      }
    }
    this.setAttendantsCount(this.attendantIds.length);
  }

  addAttendant() {
    this.openAttendantDialog();
  }

  openAttendantDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.minWidth = '35%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'rb-dialog-container';
    dialogConfig.data = {
      mainTitleDialog: 'Adicionar atendente',
      settings: {
        // subtitles: [
        //   {label: 'Modalidade', key: 'modality'},
        //   {label: 'Local da oferta', key: 'offerLocation'},
        // ],
      },
      itemsArray: this.attendantOptions,
      selectedIds: this.attendantIds,
    };
    this.attendantDialogRef =  this.attendantDialog.open(ListItemsDialogComponent, dialogConfig);
    this.attendantDialogRef.beforeClosed().subscribe(
      data => this.updateSelectedAttendantOptions(data)
    );
  }


  updateSelectedAttendantOptions(data) {
    if (data && typeof data === 'object') {
      this.selectedAttendantOptions = JSON.parse(JSON.stringify(this.buildAttendantsCustomTime(data)));
      this.updateAttendantIds();
    }
  }

  buildAttendantsCustomTime (newSelecteds) {
    if(newSelecteds && newSelecteds.length) {
      if(this.selectedAttendantOptions && this.selectedAttendantOptions.length) {
        for (const attendant of newSelecteds) {
          for (const item of this.selectedAttendantOptions) {
            if(item.id == attendant.id) {
              if(item.custom_time && item.week) {
                attendant.custom_time = 1;
                attendant.week = item.week;
              }
            }
          }
        }
      }
    }
    return newSelecteds;
  }

  openAttendantHoursDialog(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.minWidth = '35%';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '75%';
    dialogConfig.panelClass = 'rb-dialog-container';
    dialogConfig.data = {
      mainTitleDialog: 'Horário de atendimento',
      selectedAttendant: this.getSelectedAttendant(id),
    };
    this.attendantHourDialogRef =  this.attendantDialog.open(AttendanceHoursDialogComponent, dialogConfig);
    this.attendantHourDialogRef.beforeClosed().subscribe(
      data => this.updateSelectedAttendantHours(data, id)
    );
  }

  updateSelectedAttendantHours(data, id) {
    if (data.week && typeof data.week === 'object') {
      for (const item of this.selectedAttendantOptions) {
        if (item.id == id) {
            item.week = JSON.parse(JSON.stringify(data.week));
            // item.individualHourDefined = data.individualHourDefined;
            item.custom_time = data.custom_time ?  1 : 0;
        }
      }
      for (const item of this.attendantOptions) {
        if (item.id == id) {
            item.week = JSON.parse(JSON.stringify(data.week));
            // item.individualHourDefined = data.individualHourDefined;
            item.custom_time = data.custom_time ?  1 : 0;
        }
      }
    }
  }


  setAttendantsCount(value: any) {
    this.parentScope.attendantCount = value;
  }

  getSelectedAttendant(id) {
    for (const item of this.selectedAttendantOptions) {
      if (item.id == id) {
        if (!item.custom_time) {
          item.week = JSON.parse(JSON.stringify(this.parentScope.mainWeek));
        }
        return item;
      }
    }
  }



  /**/

  buildCrud() {

    this.msgCrudEmpty = 'Nenhum atendente encontrado';

    this.crudSettings = [
      { columnDef: 'name', header: 'Atendente', dataName: (row: { name: any; }) => `${row.name}` },
      { columnDef: 'email', header: 'E-mail', dataName: (row: { email: any; }) => `${row.email}` },
      { columnDef: 'buttonEdit', dataName: row => `${row.id}`, isButton: true,
        buttonConfig: {
          header: 'Horário de atendimento', icon: 'edit', title: 'Horário de atendimento', action: this.editAttendantHours, isFixed: true,
        }
      },
      { columnDef: 'buttonDelete', dataName: row => `${row.id}`, isButton: true,
        buttonConfig: {
          header: 'Excluir', icon: 'delete', title: 'Excluir', action: this.deleteAttendant, isFixed: true,
        }
      },
    ];

    this.crudMetaCount = this.selectedAttendantOptions.length;
  }

  editAttendantHours(obj, parentScope) {
    parentScope.openAttendantHoursDialog(obj.id);
  }

  deleteAttendant(obj, parentScope) {
    if (parentScope && parentScope.selectedAttendantOptions) {
      for (const [index, item] of parentScope.selectedAttendantOptions.entries()) {
        if (obj.id == item.id) {
          item.week = new Array();
          item.individualHourDefined = false;
          item.custom_time = item.individualHourDefined ? 1 : 0;
          parentScope.selectedAttendantOptions.splice(index, 1);
          parentScope.crudModel.deleteRowData(obj.id);
        }
      }
      for (const [index, item] of parentScope.attendantIds.entries()) {
        if (item == obj.id) {
          parentScope.attendantIds.splice(index, 1);
        }
      }
      parentScope.clearWeekAttendantOptions(obj.id);
    }
  }

  clearWeekAttendantOptions(id) {
    for (const item of this.attendantOptions) {
      if (item.id == id) {
        item.week = new Array();
        item.individualHourDefined = false;
        item.custom_time = item.individualHourDefined ? 1 : 0;
      }
    }
  }

  public getSelectedOptions: any =  () => {
    const response = new Array();
    for (const item of this.selectedAttendantOptions) {
      response.push({
        id: item.id,
        name: item.name,
        email: item.email,
        week: this.getWeekOptions(item),
        custom_time: item.individualHourDefined || item.custom_time === 1 ? 1 : 0});
    }
    return response;
  }

  public getWeekOptions(item) {
    let week = new Array();
    // if (item && item.week) {
    if (item && (item.individualHourDefined || item.custom_time)) {
      week = this.buildCustomWeek(JSON.parse(JSON.stringify(item.week)), JSON.parse(JSON.stringify(this.parentScope.mainWeek)));
    } else {
      week = this.buildWeek(this.parentScope.mainWeek);
    }
    return week;
  }

  buildCustomWeek(week, mainWeek) {
    if(mainWeek && mainWeek.length) {
      if(week && week.length) {
        for (const mainDay of mainWeek) {
          delete mainDay.existingInterval;
          delete mainDay.formName;
          delete mainDay.hourInterval;
          delete mainDay.title;
          mainDay.active_day = 0;
          for (const day of week) {
            if(mainDay.id == day.id) {
              mainDay.active_day = day.active_day ? 1 : 0,
              mainDay.hours = day.hours;
            }
          }
        }
      }
    }
    
    return mainWeek;
  }


  buildWeek(weekArray) {
    const response = new Array();
    for (const item of weekArray) {
      response.push({id: item.id, active_day: item.active_day ? 1 : 0, hours: item.hours});
    }
    return response;
  }

}
