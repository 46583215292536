import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ClientSettingsModule } from '../../models/client-settings/client-settings.module';

@Component({
  selector: 'app-client-data-login',
  templateUrl: './client-data-login.component.html',
  styleUrls: ['./client-data-login.component.scss']
})
export class ClientDataLoginComponent implements OnInit {
  public dataFromCrm: any = {};
  public dataFromUser: any = {};
  public dataType: any = [];
  public stringDataChanged: any = [];
  public scheduleSettings: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientSettingsModule: ClientSettingsModule,
    private dataRef: MatDialogRef<ClientDataLoginComponent>,
    private router: Router
    ) { }

  ngOnInit() {
    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
    this.dataFromCrm = this.data.cpfEmailCrm;
    this.dataFromUser = this.data.cpfEmailUser;
    this.verifyDataCompatibility();
  }

  verifyDataCompatibility(){
    if(this.dataFromCrm.cpf != this.dataFromUser.cpf){
      this.dataType.push("CPF");
      this.stringDataChanged.push(this.dataFromCrm.cpf);
    }
    if(this.dataFromCrm.email != this.dataFromUser.email){
      this.dataType.push("E-mail");
      this.stringDataChanged.push(this.dataFromCrm.email);
    }
  }

  updateData(){
    this.close();
  }

  close() {
    this.dataRef.close();
  }

  goToMyChedules() {
    const uri = this.scheduleSettings && this.scheduleSettings.uri ? this.scheduleSettings.uri : '';
    this.router.navigate([uri, 'agenda', 'meus-agendamentos']);
    this.close();
  }
}
