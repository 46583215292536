import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

@Injectable({
  providedIn: 'root'
})
export class SchoolModule {

  public schoolData: any = {};

  constructor() {
  }

  setData(data) {
    if (data) {
      this.schoolData = data;
    } else {
      this.schoolData = {};
    }
  }

  getData() {
    return this.schoolData;
  }
}






