import { Injectable } from '@angular/core';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolModule } from './school.module';
import { LoaderService } from '../../components/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class GetSchoolDataService extends Request implements Resolve<GetSchoolDataService> {
  private response: any;
  constructor(
    private schoolModule: SchoolModule,
    private http: HttpService,
    private loaderService: LoaderService,
  ) {
    super();
  }

  resolve(
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    this.url = 'institution';

    return this.http.get(this)
      .then((data) => this.response);
  }

  successAction(data) {
    this.response = data;
    if (data && data.success) {
      if (data.settings) {
        this.schoolModule.setData(data.settings);
      } else {
        this.schoolModule.setData(null);
      }
    }
    this.loaderService.setChangesLoaderState(false);
  }

  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    return false;
  }
}
