import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class MySchedulesPageModule {


  public mySchedulesData: any[] = new Array();

  setData(data) {
    if (data) {
      this.mySchedulesData = data;
    } else {
      this.mySchedulesData = new Array();
    }
  }

  getData() {
    return this.mySchedulesData;
  }

}
