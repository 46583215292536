import { Injectable } from '@angular/core';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { ClientSettingsModule } from './client-settings.module';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService extends Request implements Resolve<ClientSettingsService> {

  public data: any;
  constructor(
    private clientSettingsModule: ClientSettingsModule,
    private http: HttpService,
    private platformLocation: PlatformLocation,
  ) {
    super();
  }

  resolve(
  ): Observable<any> | Promise<any> | any {
    this.url = 'client/settings';
    this.data = {url: this.platformLocation.href};

    return this.http.post(this)
      .then((data) => this.clientSettingsModule.getScheduleSettings());
  }

  successAction(data) {
    if (data && data.success) {
      if (data.settings) {
        this.clientSettingsModule.setScheduleSettings(data.settings);
        this.clientSettingsModule.scriptManager(data.scripts);
        if (data.gtm_event_params) {
          this.clientSettingsModule.setGtmEventParams(data.gtm_event_params);
        }
      } else {
        this.clientSettingsModule.setScheduleSettings({});
      }
    } else {
      return false;
    }
  }

  errorAction(error) {
    return false;
  }
}
