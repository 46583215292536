import { Component, OnInit } from '@angular/core';
import { ColorPaletteGeneratorService } from 'rubeus-components';
import { RbDinamicFaviconService } from '../../../shared/services/rb-dinamic-favicon/rb-dinamic-favicon.service';
import { ClientSettingsModule } from '../../../shared/models/client-settings/client-settings.module';
import { ClientSettingsService } from '../../../shared/models/client-settings/client-settings.service';
import {ClientManageDomainService} from '../../../core/client-manage-domain/client-manage-domain.service';

@Component({
  selector: 'app-client-root',
  templateUrl: './client-root.component.html',
  styleUrls: ['./client-root.component.scss']
})
export class ClientRootComponent implements OnInit {

  public scheduleSettings: any = {};
  public showContent: any = false;
  public theme: any = {
    rbMainPalette: '#0DA6A6',
    rbAccentPalette: '#717171',
    reduced_institutional_logo: 'assets/img/favicon.png',
  };
  public primaryPaletteSettings: any = {
    colors: [],
    orig: [],
    base: this.theme.rbMainPalette,
    json: '',
    name: '',
    angularCodeString: '',
  };
  public accentPaletteSettings: any = {
    colors: [],
    orig: [],
    base: this.theme.rbAccentPalette,
    json: '',
    name: '',
    angularCodeString: '',
  };

  constructor(
    private clientSettingsModule: ClientSettingsModule,
    private clientSettingsService: ClientSettingsService,
    private clientManageDomainService: ClientManageDomainService,
    private rbDinamicFaviconService: RbDinamicFaviconService,
    private colorPaletteGeneratorService: ColorPaletteGeneratorService,
  ) { }

  ngOnInit() {
    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
    this.injectGTMKey();
    this.injectFbKey();
    if (this.scheduleSettings && this.scheduleSettings.id) {
      this.themeManagement();
      this.faviconManagement();
      this.backgroundManagement();
      this.showContent = true;
    } else {
      this.callManageDomainService();
    }
  }

  callManageDomainService() {
    this.clientManageDomainService.resolve().then((response) => {
      this.callSettingsService();
    });
  }

  callSettingsService() {
    this.clientSettingsService.resolve().then((response) => {
      this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
      this.themeManagement();
      this.faviconManagement();
      this.backgroundManagement();
      this.showContent = true;
    });
  }


  themeManagement() {
    this.theme.rbMainPalette = this.scheduleSettings.primary_color;
    this.primaryPaletteSettings.base = this.scheduleSettings.primary_color;
    if (this.primaryPaletteSettings && !this.primaryPaletteSettings.base || this.primaryPaletteSettings.base == 'undefined') {
      this.primaryPaletteSettings.base = '#0DA6A6';
    }
    this.colorPaletteGeneratorService.changePalette(0, this.theme, 'rbMainPalette', this, 'primaryPaletteSettings');
    this.colorPaletteGeneratorService.changePalette(0, this.theme, 'rbAccentPalette', this, 'accentPaletteSettings');
    this.defineTheme();
  }

  defineTheme() {
    const newTheme = {
      rbMainPalette: this.primaryPaletteSettings.colors,
      rbAccentPalette: this.accentPaletteSettings.colors,
    };
    this.colorPaletteGeneratorService.changeTheme(newTheme, this.theme);
  }

  faviconManagement() {
    if (this.scheduleSettings && this.scheduleSettings.reduced_institutional_logo) {
      this.rbDinamicFaviconService.setNewFavicon(this.scheduleSettings.reduced_institutional_logo);
    }
  }

  backgroundManagement() {
    document.documentElement.style.setProperty(`--rb-bg-color`, this.scheduleSettings.background_opacity ? this.scheduleSettings.background_opacity : 'transparent');
    document.documentElement.style.setProperty(`--rb-bg-color-body`, this.scheduleSettings.background_opacity ? '#ffffff' : '#f1f1f1');
  }

  injectGTMKey() {
    if (!this.scheduleSettings.gtm_key) {
      return
    }
    if (document.getElementsByClassName('gtm_integration_script').length === 0) {
        const script = document.createElement('script');
        script.classList.add('gtm_integration_script');
        script.setAttribute('async', 'true');
        script.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${this.scheduleSettings.gtm_key}`);
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this.scheduleSettings.gtm_key}`);
        iframe.setAttribute('height', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('style', 'display:none;visibility:hidden');
        noscript.appendChild(iframe);
        document.body.appendChild(noscript);

        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    }
  }

  injectFbKey() {
    if (!this.scheduleSettings.pixel_key) {
      return
    }
    const script = document.createElement('script')
    script.innerHTML = `
      !function(f,b,e,v,n,t,s){
        if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
        //Insert Your Facebook Pixel ID below.
        fbq('init', ${this.scheduleSettings.pixel_key});
        fbq('track', 'PageView');
    `

    document.head.appendChild(script);
  }
}
