<div class="rb-default-dialog confirm-cancel-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">Desmarcar agendamento</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content confirm-cancel-schedule-dialog-content">
    <div fxLayout="row wrap" fxLayoutAlign="center center"
    class="rb-padding-5x24 confirm-action-message">
      <div fxFlex="100">
        <p>Deseja realmente desmarcar este agendamento?</p>
      </div>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <button mat-stroked-button 
      color="accent" 
      (click)="close()">
        <span>Cancelar</span>
      </button>
      <div class='rbButtonsDivisor'></div>
      <button mat-raised-button 
      color="warn" 
      (click)="comfirm()">
        <span>Desmarcar</span>
      </button>
    </div>
  </div>
</div>