import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ]
})

@Injectable({
  providedIn: 'root'
})

export class SettingsIdentityModule {
  public refresh = new Subject<any>();
  public hasChange = new Subject<any>();
  public settingsIdentityData = {
    institutional_logo: '',
    reduced_institutional_logo: '',
    background_image: '',
    primary_color: '',
    background_opacity: '',
  };

  settingsIdentityBackup: any = {};


  setInitialData(data) {
    if (data) {
      this.settingsIdentityData = data;
    }
    this.settingsIdentityBackup = JSON.parse(JSON.stringify(this.settingsIdentityData));
  }

  getData() {
    return this.settingsIdentityData;
  }

  refreshData() {
    this.settingsIdentityData = JSON.parse(JSON.stringify(this.settingsIdentityBackup));
    this.refresh.next(this.settingsIdentityData);
  }

  getRefreshData(): Observable<any> {
    return this.refresh.asObservable();
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }


}
