import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FlowSchedulingPageModule } from 'src/app/modules/client/flow-scheduling/flow-scheduling-page/flow-scheduling-page.module';
import { ClientSettingsModule } from '../../models/client-settings/client-settings.module';
import { AcceptLgpdTermsService } from '../../models/client-user/accept-lgpd-terms.service';
import { ClientLoginService } from '../../models/client-user/client-login.service';

@Component({
  selector: 'app-lgpd-terms-dialog',
  templateUrl: './lgpd-terms-dialog.component.html',
  styleUrls: ['./lgpd-terms-dialog.component.scss']
})
export class LgpdTermsDialogComponent implements OnInit {

  public lgpdSettingsData: any;
  public loginParams: any;
  @Input() parentScope: any;

  public lgpdTermsForm: FormGroup;
  public scheduleSettings: any = {};

  constructor(
    private dialogRef: MatDialogRef<LgpdTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private flowSchedulingPageModule: FlowSchedulingPageModule,
    private acceptLgpdTermsService: AcceptLgpdTermsService,
    private clientSettingsModule: ClientSettingsModule,
    private clientLoginService: ClientLoginService,
  ) {
    this.loginParams = this.dataDialog.loginParams;
    this.loginParams.personId = this.dataDialog.personId;

    this.lgpdTermsForm = this.formBuilder.group({
      name: new FormControl('',[]),
      assinaturas: new FormArray([]),
    });

  }

  ngOnInit() {
    this.lgpdSettingsData = JSON.parse(JSON.stringify(this.flowSchedulingPageModule.getLgpdSettings()));

    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();

    this.buildLgpdArray();
    this.buildConsentToProcess();
  }

  acceptLgpdTerms() {
    this.loginParams.legalBase = this.lgpdSettingsData.baseLegal;
    this.loginParams.assinaturas = this.lgpdSettingsData.assinaturas;

    this.acceptLgpdTermsService.resolve(this.loginParams).then((response) => {
      if (response && response.success) {
        this.clientLoginService.resolve(this.loginParams).then((response) => {
          if (response && response.success) {
            this.goToMyChedules();
          }
        });
      }
    })
  }

  goToMyChedules() {
    const uri = this.scheduleSettings && this.scheduleSettings.uri ? this.scheduleSettings.uri : '';
    this.router.navigate([uri, 'agenda', 'meus-agendamentos']);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  buildConsentToProcess() {
    if (this.lgpdSettingsData && this.lgpdSettingsData.rotuloConsentimentoProcesso) {
      this.lgpdTermsForm.addControl(
        'consentToProcess',
        new FormControl(this.lgpdSettingsData.consentToProcess, Validators.required)
      );
    }
  }

  buildLgpdArray() {
    if (this.lgpdSettingsData && this.lgpdSettingsData.assinaturas && this.lgpdSettingsData.assinaturas.length && this.lgpdSettingsData.idCaixaVerificacao != 3) {
        for (let i = 0; i < this.lgpdSettingsData.assinaturas.length; i++) {
          let assinatura = this.lgpdSettingsData.assinaturas[i];
          if (assinatura.obrigatorio == 1) {
            this.formArray.push(new FormControl(this.lgpdSettingsData.assinaturas[i].checked, [Validators.required]));
          } else {
            this.formArray.push(new FormControl(this.lgpdSettingsData.assinaturas[i].checked, []));
          }
        }
    }
  }

  get formArray() {
    return this.lgpdTermsForm.get('assinaturas') as FormArray;
  }

}
