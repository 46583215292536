import { Injectable } from '@angular/core';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientSettingsModule } from './client-settings.module';

@Injectable({
  providedIn: 'root'
})
export class GetCitiesService extends Request implements Resolve<GetCitiesService> {

  constructor(
    private clientSettingsModule: ClientSettingsModule,
    private http: HttpService,
  ) {
    super();
  }

  resolve(
  ): Observable<any> | Promise<any> | any {
    this.url = 'client/getCities';

    return this.http.get(this)
      .then((data) => this.clientSettingsModule.getCityOptions());
  }

  successAction(data) {
    if (data && data.success) {
      if (data.cities) {
        this.clientSettingsModule.setCityOptions(data.cities);
      }
    } else {
      return false;
    }
  }

  errorAction(error) {
    return false;
  }
}
