import { Component, OnInit } from '@angular/core';
import { ClientLoaderService } from './client-loader.service';

@Component({
  selector: 'app-client-loader',
  templateUrl: './client-loader.component.html',
  styleUrls: ['./client-loader.component.scss']
})
export class ClientLoaderComponent implements OnInit {

  public activeLoading = false;
  public controlLoaderChanges: any;

  constructor(
    public clientLoaderService: ClientLoaderService
  ) {
    this.controlLoaderChanges = this.clientLoaderService.getChangesLoaderState()
    .subscribe(value => this.activeLoading = value);
  }

  ngOnInit() {
  }
}
