import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {WeekDaysTabsComponent} from '../../../../../../shared/components/week-days-tabs/week-days-tabs.component';
import { ServiceRegisterModule } from '../../service-register.module';


@Component({
  selector: 'app-service-attendance-interval',
  templateUrl: './service-attendance-interval.component.html',
  styleUrls: ['./service-attendance-interval.component.scss']
})
export class ServiceAttendanceIntervalComponent implements OnInit, AfterViewInit {
  @Input() parentComponent: MatStepper;
  @Input() parentScope: any;
  @ViewChild('attendaceIntervalTabs', {static: false}) attendaceIntervalTabsElement: WeekDaysTabsComponent;

  public week: any[] = new Array();
  public initialWeekData: any[] = new Array();

  constructor(
    public serviceRegisterModule: ServiceRegisterModule,
  ) {
  }

  ngOnInit() {
    this.initialWeekData = this.serviceRegisterModule.getWeek();
  }

  ngAfterViewInit() {
    this.attendaceIntervalTabsElement.getUpdatedWeek().subscribe(value => {
      this.week = value;
      this.parentScope.mainWeek = value;
    });
  }


  public getDays: any =  () => {
    return this.buildOptionsToSave();
  }

  buildOptionsToSave() {
    const response = new Array();
    for (const item of this.week) {
      response.push({id: item.id, active_day: item.active_day ? 1 : 0, hours: item.hours});
    }
    return response;
  }

}
