import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return items.filter(item => {
      if (item.name && item.code) {
        return item.code.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText) || item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText);
      } else if (item.title) {
        return item.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText);
      } else if (item.name) {
        return item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText);
      }
    });
  }
}
