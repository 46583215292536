import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { AdminHttpService } from '../../../../../core/http/admin-http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { LoaderService } from '../../../../../shared/components/loader/loader.service';
import { LgpdOptionsModule } from '../components/lgpd-options/lgpd-options.module';

@Injectable({
  providedIn: 'root'
})
export class ApplyAllSchedulesWithoutLgpdService extends Request implements Resolve<ApplyAllSchedulesWithoutLgpdService> {

  private response: any;
  constructor(
    private lgpdOptionsModule: LgpdOptionsModule,
    private http: AdminHttpService,
    protected notice: NoticeComponent,
    private loaderService: LoaderService,
  ) {
    super();
  }

  resolve(
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    this.url = 'general-settings/save-lgpd-to-all-schedules/';
    this.data = parameters;

    return this.http.post(this)
      .then((data) => {
        return this.response;
      });
  }

  successAction(data) {
    super.successAction(data);
    this.response = data;
    if (data.success) {
      if (data.token) {
        localStorage.setItem('token', data.token);
      }
      // this.lgpdOptionsModule.setInitialData(data);
      this.lgpdOptionsModule.setInitialData(data.data);
      this.notice.showMessageSuccess('Alterações salvas.');
    } else {
      if (data.error) {
        this.notice.showMessageError(data.error);
      } else if (data.erro) {
        this.notice.showMessageError(data.erro);
      } else {
        this.notice.showMessageError(data.errors);
      }
    }
    this.loaderService.setChangesLoaderState(false);
  }

  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
