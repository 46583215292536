import { Component, OnInit, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ListItemsDialogComponent } from '../../../../../../shared/components/list-items-dialog/list-items-dialog.component';
import { SegmentationsServicesModule } from '../../../../../../shared/models/segmentations-services/segmentations-services.module';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ServiceRegisterModule } from '../../service-register.module';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-service-segmentations',
  templateUrl: './service-segmentations.component.html',
  styleUrls: ['./service-segmentations.component.scss']
})
export class ServiceSegmentationsComponent implements OnInit {
  @Input() parentComponent: MatStepper;
  @Input() parentScope: any;
  public segmentationOptions = new Array();
  public segmentationIds = new Array();
  public selectedSegmentationOptions = new Array();
  public segmentationDialogRef: any;
  public isDrag: boolean;
  public isRequiredSegments: boolean;

  constructor(
    public segmentationDialog: MatDialog,
    public segmentationsServicesModule: SegmentationsServicesModule,
    public serviceRegisterModule: ServiceRegisterModule,
  ) {
    this.serviceRegisterModule.getRequiredSegments().subscribe(value => {
      this.isRequiredSegments = value;
    });
  }

  ngOnInit() {
    this.segmentationOptions = this.segmentationsServicesModule.getOptions();
    this.selectedSegmentationOptions = this.serviceRegisterModule.getSelectedSegmentations();
    this.parentScope.segmentation_order_by_title = this.serviceRegisterModule.getSegmentationsOrder();
    this.updateSegmentationIds();
    // this.selectedSegmentationOptions = this.buildListOptions(this.segmentationIds, this.segmentationOptions);
  }

  // buildListOptions(ids: any[], options: any[]) {
  //   const result = new Array();
  //   if (ids && ids.length && options && options.length) {
  //     for (const option of options) {
  //       for (const id of ids) {
  //         if (option.id === id) {
  //           result.push(option);
  //         }
  //       }
  //     }
  //   }
  //   return result;
  // }

  removeSegmentation(index) {
    if (this.selectedSegmentationOptions && this.selectedSegmentationOptions.length) {
      this.selectedSegmentationOptions.splice(index, 1);
    }
    this.updateSegmentationIds();
  }

  updateSegmentationIds() {
    this.segmentationIds = new Array();
    if (this.selectedSegmentationOptions && this.selectedSegmentationOptions.length) {
      for (const option of this.selectedSegmentationOptions) {
        this.segmentationIds.push(option.id);
      }
    }
    this.setSegmentationsCount(this.segmentationIds.length);
  }

  goToNextStep() {
    this.parentComponent.next();
  }

  addSegmentation() {
    this.openSegmentationDialog();
  }

  openSegmentationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.minWidth = '35%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'rb-dialog-container';
    dialogConfig.data = {
      mainTitleDialog: 'Adicionar segmentação',
      settings: {
        subtitles: [
          { label: 'Código', key: 'code' },
          { label: 'Modalidade', key: 'modality' },
          { label: 'Local da oferta', key: 'offer_location' },
        ],
      },
      itemsArray: this.segmentationOptions,
      selectedIds: this.segmentationIds,
    };
    this.segmentationDialogRef = this.segmentationDialog.open(ListItemsDialogComponent, dialogConfig);
    this.segmentationDialogRef.beforeClosed().subscribe(
      data => this.updateSelectedSegmentationOptions(data)
    );
  }

  updateSelectedSegmentationOptions(data) {
    if (data && typeof data === 'object') {
      var auxSegmentationsSelected = new Array();
      this.selectedSegmentationOptions.map((item) => {
        for(var i = 0; i < data.length; i++){
          if(data[i].id == item.id){
            auxSegmentationsSelected.push(item);
            data.splice(i, 1);
          }
        }
      })
      if(data.length > 0){
        data.map((item) => {
          auxSegmentationsSelected.push(item);
        })
      }
      this.selectedSegmentationOptions = auxSegmentationsSelected;
      this.updateSegmentationIds();
    }
  }


  setSegmentationsCount(value: any) {
    this.parentScope.segmentationsCount = value;
    if(value >= 0){
      this.parentScope.closeNoticeSettings();
    }
  }


  public getSelectedOptions: any = () => {
    const response = new Array();
    for (const item of this.selectedSegmentationOptions) {
      response.push({ id: item.id, code: item.code, name: item.name, modality: item.modality,
        offer_location: item.offer_location, order: item.order });
    }
    return response;
  }

  canDragDrop() {
    if (this.parentScope.segmentation_order_by_title === true
      || this.parentScope.segmentation_order_by_title === 'true'
      || this.parentScope.segmentation_order_by_title === 1
      || this.parentScope.segmentation_order_by_title === '1') {
      return false;
    } else {
      return true;
    }
  }

  dragStarted() {
    this.isDrag = true;
  }

  dragEnded() {
    this.isDrag = false;
  }

  dropTable(event?) {
    moveItemInArray(this.selectedSegmentationOptions, event.previousIndex, event.currentIndex);
    this.updateIndexList();
  }

  updateIndexList() {
    for (const [index, item] of this.selectedSegmentationOptions.entries()) {
      item.order = index + 1;
    }
  }


  sortList() {
    this.selectedSegmentationOptions = this.selectedSegmentationOptions.sort(this.compare);
    this.updateIndexList();
  }

  compare(a: any, b: any) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  }

}
