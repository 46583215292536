import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {ColorPaletteGeneratorComponent} from './color-palette-generator.component';

@NgModule({
  declarations: [
    ColorPaletteGeneratorComponent,
  ],
  imports: [
    CommonModule,
    ColorPickerModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  exports: [
    ColorPaletteGeneratorComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ColorPaletteGeneratorModule { }