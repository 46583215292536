import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ClientUserModule {
  public userData;
  public dataForgotPass;
  public userLogged;
  public requireFullRegistration;

  isLogged() {
    return this.userLogged;
  }

  getFirstName() {
    return this.userData ? this.userData.firstName : false;
  }

  getData() {
    return this.userData;
  }

  setRequireFullRegistration(data) {
    if (data) {
      this.requireFullRegistration = data;
    }else{
      this.requireFullRegistration = false;
    }
  }

  getRequireFullRegistration() {
    return this.requireFullRegistration;
  }

  setData(data) {
    if (data) {
      this.userData = data;
      this.userLogged = true;
      if (this.userData.name) {
        this.defineFirstName();
      }
    } else {
      this.userData = {};
      this.userLogged = false;
    }
  }


  defineFirstName() {
    this.userData.firstName = (
      typeof this.userData.name === 'string' &&
      this.userData.name.split(' ').length ?
      this.userData.name.split(' ')[0] : this.userData.name);
  }

  setCode(data: any) {
    this.dataForgotPass = data;
  }
}
