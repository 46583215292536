import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Request } from './request';
import { environment } from '../../../environments/environment';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly API = `${environment.API}`;

  constructor(
    private httpClient: HttpClient,
    private platformLocation: PlatformLocation,
  ) { }

  get(request: Request) {
    /*
    Comentado todos 'localStorage.getItem('rbScheduleAdmToken')'
    por causa de ocorrência de error na API por identificação errado do Host_client
    */
    const authorization = '' //localStorage.getItem('rbScheduleAdmToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: authorization ? 'Bearer ' + authorization : '',
      })
    };


    /* Token cliente */
    const tokenStorage = this.tokenManagement();
    // const tokenStorage = this.getClientToken();
    const schedulingToken = sessionStorage.getItem('schedulingToken');
    let paramns = '';
    if (tokenStorage) {
      paramns = '?token=' + tokenStorage;
      if (schedulingToken && schedulingToken !== 'undefined') {
        paramns += '&schedulingToken=' + schedulingToken;
      }
    } else if (schedulingToken && schedulingToken !== 'undefined') {
      paramns = '?schedulingToken=' + schedulingToken;
    }
    /* ---- */

    paramns += (paramns && paramns.length ? '&' : '?') + 'url=' + this.platformLocation.href;


    const promise = this.httpClient.get(this.API + request.url + paramns, options).toPromise().then(
      res => {
        request.successAction(res);
        this.setClientToken(res);
      },
      msg => request.errorAction(msg));
    return promise;
  }

  post(request: Request) {
    const authorization = ''; //localStorage.getItem('rbScheduleAdmToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: authorization ? 'Bearer ' + authorization : '',
      })
    };

    /* Token cliente */
    const tokenStorage = this.tokenManagement();
    // const tokenStorage = this.getClientToken();
    const schedulingToken = sessionStorage.getItem('schedulingToken');
    if (tokenStorage && request.data) {
      request.data.token = tokenStorage;
      sessionStorage.setItem('defaultToken', tokenStorage);
    }
    if (schedulingToken && schedulingToken !== 'undefined' && request.data) {
      request.data.schedulingToken = schedulingToken;
    }
    /* ---- */

    if (request.data && typeof request.data === 'object') {
      request.data.url = this.platformLocation.href;
    } else {
      request.data = {
        url: this.platformLocation.href
      };
    }

    const promise = this.httpClient.post(this.API + request.url, request.data, options).toPromise().then(
      res => {
        request.successAction(res);
        this.setClientToken(res);
      },
      msg => request.errorAction(msg));
    return promise;
  }

  del(request: Request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('rbScheduleAdmToken'),
      })
    };

    const promise = this.httpClient.delete(this.API + request.url + '/' + request.data.id, options).toPromise().then(
      res => request.successAction(res),
      msg => request.errorAction(msg));
    return promise;
  }

  put(request: Request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'Bearer ' + localStorage.getItem('rbScheduleAdmToken'),
      })
    };

    if (request.data && typeof request.data === 'object') {
      request.data.url = this.platformLocation.href;
    } else {
      request.data = {
        url: this.platformLocation.href
      };
    }

    const promise = this.httpClient.put(this.API + request.url, request.data, options).toPromise().then(
      res => request.successAction(res),
      msg => request.errorAction(msg));
    return promise;
  }

  createParams(data: {}) {
    let httpParams = new HttpParams();
    for (const key in data) {
      if (typeof data[key] === 'object') {
        for (const obj of data[key]) {
          httpParams = httpParams.append(key + '[]', obj);
        }
      } else {
        httpParams = httpParams.set(key, data[key]);
      }
    }
    return httpParams;
  }


  postFile(request: Request) {
    const tokenStorage = this.tokenManagement();
    // const tokenStorage = this.getClientToken();
    if (tokenStorage && request.data) {
      request.data.token = tokenStorage;
    }

    const promise = this.httpClient.post(this.API + request.url, this.createParamsPostFile(request.data))
      .toPromise().then(
        res => request.successAction(res),
        msg => request.errorAction(msg));
    return promise;
  }

  createParamsPostFile(data: {}) {
    const httpParams = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        httpParams.append(key, data[key]);
      }
    }
    return httpParams;
  }

  setClientToken(data) {
    // if (data && data.token && data.uri && data.id) {
    //   // localStorage.setItem('rbSchedulingClientToken', data.token);
    //   localStorage.setItem('rb_' + data.uri + '_' + data.id, data.token);
    //   sessionStorage.setItem('rbScheduleSessionId', 'rb_' + data.uri + '_' + data.id);
    // }
    if (data && data.token) {
      if (sessionStorage.getItem('rbUseSessionStorage')) {
        sessionStorage.setItem('rbSchedulingClientToken', data.token);
      } else {
        localStorage.setItem('rbSchedulingClientToken', data.token);
      }
    }
  }

  tokenManagement() {
    return (sessionStorage.getItem('rbUseSessionStorage')
    ?
    sessionStorage.getItem('rbSchedulingClientToken')
    :
    localStorage.getItem('rbSchedulingClientToken'));
  }

  // getClientToken() {
  //   const sessionId = sessionStorage.getItem('rbScheduleSessionId');
  //   return localStorage.getItem(sessionId);
  // }
}
