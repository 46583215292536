import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { AdminHttpService } from '../../../../../core/http/admin-http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { LoaderService } from '../../../../../shared/components/loader/loader.service';
import { ExceptionalHoursRegisterPageModule } from '../exceptional-hours-register-page.module';

@Injectable({
  providedIn: 'root'
})
export class GetExceptionalTimesActionService extends Request implements Resolve<GetExceptionalTimesActionService> {

  constructor(
    private exceptionalHoursRegisterPageModule: ExceptionalHoursRegisterPageModule,
    private http: AdminHttpService,
    private loaderService: LoaderService,
    protected notice: NoticeComponent,
  ) {
    super();
  }

  private param = '';

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {

    this.url = 'exceptional-time/time-action';

    return this.http.get(this)
      .then((data) => this.exceptionalHoursRegisterPageModule);
  }

  successAction(data) {
    if (data && data.success) {
      this.exceptionalHoursRegisterPageModule.setActionOptions(data.data);
    }
    return this.exceptionalHoursRegisterPageModule.getData();
  }


  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
