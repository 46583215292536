import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ClientLoaderService } from '../client-loader/client-loader.service';
import { ClientLoginService } from '../../models/client-user/client-login.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import {ClientSettingsModule} from '../../models/client-settings/client-settings.module';
import { LgpdTermsDialogComponent } from '../lgpd-terms-dialog/lgpd-terms-dialog.component';
import { ClientDataLoginComponent } from '../client-data-login/client-data-login.component';
import { ClientUpdateDataService } from '../../models/client-user/client-update-data.service';

@Component({
  selector: 'app-client-login-dialog',
  templateUrl: './client-login-dialog.component.html',
  styleUrls: ['./client-login-dialog.component.scss']
})
export class ClientLoginDialogComponent implements OnInit {
  public loginForm: FormGroup;
  public loginData: any = {
    login: '' ,
    password: '',
    identifiedPerson: 0,
  };
  public scheduleSettings: any = {};

  constructor(
    private dialogRef: MatDialogRef<ClientLoginDialogComponent>,
    private dataRef: MatDialogRef<ClientDataLoginComponent>,
    private clientLoginService: ClientLoginService,
    private router: Router,
    private loginDialog: MatDialog,
    private dataDialog: MatDialog,
    private clientLoaderService: ClientLoaderService,
    private clientSettingsModule: ClientSettingsModule,
    private clientUpdateDataService: ClientUpdateDataService,
    private lgpdTermsDialog: MatDialog,
    // private shareDataService: ShareDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginForm = new FormGroup({
      'login': new FormControl(this.loginData.login, [
        Validators.required
      ]),
      'password': new FormControl(this.loginData.password, [
        Validators.required
      ])
    });
  }

  ngOnInit() {
    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
    if (this.data.cpf) {
      this.loginData.login = this.data.cpf;
    }
    if (this.data.email) {
      this.loginData.login = this.data.email;
    }
    if (this.data.identifiedPerson) {
      this.loginData.identifiedPerson = this.data.identifiedPerson;
    }
  }

  close() {
    this.dialogRef.close();
  }

  toAccess() {
    this.clientLoaderService.setChangesLoaderState(true);
    this.clientLoginService.resolve(this.loginData).then((response) => {
      if(response && response.success && response.dataUserSchedule && response.dataCrm) {
        this.clientLoaderService.setChangesLoaderState(false);
        this.incompatibleData(response.dataUserSchedule, response.dataCrm);
        this.goToMyChedules();
      } else if (response && response.success) {
        this.clientLoaderService.setChangesLoaderState(false);
        this.goToMyChedules();
      } 
      this.clientLoaderService.setChangesLoaderState(false);
    });
  }

  goToMyChedules() {
    const uri = this.scheduleSettings && this.scheduleSettings.uri ? this.scheduleSettings.uri : '';
    this.router.navigate([uri, 'agenda', 'meus-agendamentos']);
    this.close();
  }

  incompatibleData(dataUser, dataCrm){
    this.dataRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.width = '480px';
    dialogConfig.minHeight = '300px';
    dialogConfig.panelClass = ['rb-dialog-container', 'rb-login-dialog'];
    dialogConfig.data = {
      cpfEmailUser: dataUser,
      cpfEmailCrm: dataCrm
    };
    this.dataDialog.open(ClientDataLoginComponent, dialogConfig);
    this.clientUpdateDataService.resolve({
      cpf: dataCrm.cpf, 
      email: dataCrm.email,
      name: dataCrm.name,
      telephone: dataCrm.telephone,
      id: dataUser.id}).then((response) => {
      
    });
  }

  forgotPassword() {
    this.dialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.width = '420px';
    dialogConfig.minHeight = '250px';
    dialogConfig.panelClass = ['rb-dialog-container', 'rb-login-dialog'];
    this.clientLoaderService.setChangesLoaderState(true);
    this.loginDialog.open(ForgotPasswordDialogComponent, dialogConfig);
    this.clientLoaderService.setChangesLoaderState(false);
  }

}
