import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { NoticeComponent } from 'src/app/shared/components/notice/notice.component';
import { ApplyAllSchedulesWithoutLgpdService } from '../general-settings-page/services/apply-all-schedules-whitout-lgpd.service';

@Component({
  selector: 'app-lgpd-settings-dialog',
  templateUrl: './lgpd-settings-dialog.component.html',
  styleUrls: ['./lgpd-settings-dialog.component.scss']
})
export class LgpdSettingsDialogComponent implements OnInit {

  public optionValue: number;
  public settingsForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<LgpdSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private applyAllSchedulesWithoutLgpdService: ApplyAllSchedulesWithoutLgpdService,
    private loaderService: LoaderService,
    private notice: NoticeComponent
  ) {

  }

  public settingsOption: any[] = [
    {
      'label':'Utilizar essa alteração somente como configuração padrão para as próximas agendas.',
      'value':1
    },
    {
      'label':'Aplicar para todas as agendas sem configuração de LGPD.',
      'value':2
    }
  ];

  ngOnInit() {

    this.settingsForm = new FormGroup({
      settingOption: new FormControl(this.optionValue, [Validators.required])
    });

  }

  callSave() {
    if (this.optionValue == 2) {
      this.applyAllSchedulesWithoutLgpdService.resolve({}).then((response) => {
        if (response && response.success) {
            this.loaderService.setChangesLoaderState(false);
            this.notice.showMessageSuccess('Alterações salvas.');
            this.dialogRef.close(true);
        }
      });
    }else{
      this.dialogRef.close(true);
    }
  }

}
