<div class="rb-padding-24x0" fxLayout="row wrap" fxLayoutAlign="center center">


  <div fxFlex="100" *ngIf="!selectedSegmentationOptions.length">
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center"
    *ngIf="!isRequiredSegments">
      <div fxFlex.xs="80" fxFlex.sm="60" fxFlex="40" class="rbAlignCenter">
        <h1 class="rb-main-title">Este serviço tem segmentações?</h1>
        <p class="rb-main-subtitle">
          Este serviço ainda não possui segmentações vinculadas.
          Caso queira segmentar agora, utilize o botão "Adicionar uma segmentação".
        </p>
      </div>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center"
    *ngIf="isRequiredSegments">
      <div fxFlex.xs="80" fxFlex.sm="60" fxFlex="40" class="rbAlignCenter">
        <h1 class="rb-main-title">Este serviço ainda não possui segmentações</h1>
        <p class="rb-main-subtitle">
          A opção "Permitir vincular mais de uma segmentação e estudante por horário" está habilitada.
          Para prosseguir com esta opção é necessário vincular pelo menos 01 segmentação para este serviço.
        </p>
      </div>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
      <button mat-stroked-button matStepperPrevious color="primary" class="rb-btn-upload">
        <span>Voltar</span>
      </button>
      <div class='rbButtonsDivisor'></div>
      <button mat-raised-button color="primary" (click)="addSegmentation()">
        <span>Adicionar uma segmentação</span>
      </button>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center"
    *ngIf="!isRequiredSegments">
      <button mat-button class="rb-btn-skip" (click)="goToNextStep()">
        <span>Não preciso segmentar agora</span>
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>

  <div fxFlex="100" *ngIf="selectedSegmentationOptions.length" fxLayout="row wrap">
    <div class="button-segmentation rb-padding-top-24 rb-padding-bottom-10" fxFlex="100" fxLayout="row wrap">
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" (click)="addSegmentation()">
          <span>Adicionar uma segmentação</span>
        </button>
      </div>
      <div class="rb-padding-20x0x0" fxFlex="100" fxLayout="row"
        fxLayoutAlign="center center">
        <div fxFlex.xs="80" fxFlex.sm="60" fxFlex="40"
        style="padding: 0 22px;">
          <mat-slide-toggle
            class="rb-slide-toggle"
            color="primary"
            [(ngModel)]="parentScope.segmentation_order_by_title"
            [checked]="parentScope.segmentation_order_by_title==1"
            (change)="sortList()">
            Ordenar por ordem alfabética
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="rb-padding-10x0" fxFlex="100" fxLayout="row" 
    fxLayoutAlign="center center" style="margin-top: 84px;">
      <div fxFlex.xs="80" fxFlex.sm="60" fxFlex="40">
        <mat-list #rbListItem class="rbList" color="primary"
        style="padding-top: 0px;"
        cdkDropList
        class="rb-drag-list-active"
        (cdkDropListDropped)="dropTable($event)"
        [cdkDropListData]="selectedSegmentationOptions"
        [cdkDropListDisabled]="parentScope.segmentation_order_by_title">
          <mat-list-item *ngFor="let item of selectedSegmentationOptions; let itemIndex = index;"
          [ngClass]="{'rb-disabled-list-item': isDrag}"
          class="rb-drag-list-item"
          cdkDragLockAxis="y" 
          cdkDrag
          [cdkDragData]="item" 
          (cdkDragStarted)="dragStarted()" 
          (cdkDragEnded)="dragEnded()"
          cdkDragHandle>
            <div class="rb-drag-list-placeholder" *cdkDragPlaceholder></div>
            <div class="rb-drag-list-item-handle-container">
              <mat-icon class="rb-drag-list-item-handle"
              ng-if="!parentScope.segmentation_order_by_title">drag_indicator</mat-icon>
            </div>
            <div class="mat-list-text" fxFlex="">
              <h4 matLine class="rbListTitle rb-padding-bottom-5 ">{{item.name}}</h4>
              <p matLine class="rbListSubtitle">
                <span class="fontWeight500">Código:</span>
                <span>{{item.code}}</span>
              </p>
              <p matLine class="rbListSubtitle">
                <span class="fontWeight500">Modalidade:</span>
                <span>{{item.modality}}</span>
              </p>
              <p matLine class="rbListSubtitle">
                <span class="fontWeight500">Local de oferta:</span>
                <span>{{item.offer_location}}</span>
              </p>
            </div>
            <button mat-icon-button class="rbList-actions-button" (click)="removeSegmentation(itemIndex)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>