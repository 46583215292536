import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ScheduleServicesModule {

  public scheduleId: any = {};
  public servicesCrudData: any = new Array();
  public savedServicesData = new Subject<any>();
  public hasChange = new Subject<any>();
  public publishedServicesCount = new Subject<any>();
  public requiredLgpdOnServiceCount = new Subject<any>();

  setData(data) {
    if (data) {
      this.servicesCrudData = data;
    } else {
      this.servicesCrudData = new Array();
    }
  }

  getData() {
    return this.servicesCrudData;
  }

  getId() {
    return this.servicesCrudData.id;
  }

  setSavedData(value) {
    this.savedServicesData.next(value);
  }

  getSavedData(): Observable<any> {
    return this.savedServicesData.asObservable();
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }

  setPublishedServicesCount(value: any) {
    this.publishedServicesCount.next(value);
  }

  getPublishedServicesCount(): Observable<any> {
    return this.publishedServicesCount.asObservable();
  }

  setRequiredLgpdOnServiceCount(value: any) {
    this.requiredLgpdOnServiceCount.next(value);
  }

  getRequiredLgpdOnServiceCount(): Observable<any> {
    return this.requiredLgpdOnServiceCount.asObservable();
  }


}
