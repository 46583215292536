import { Component, OnInit, ViewChild, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSelectionList } from '@angular/material/list';
import { FilterPipe } from '../../services/filter-pipe/filter-pipe.service';

export interface ListItems {
  id: string;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-list-items-dialog',
  templateUrl: './list-items-dialog.component.html',
  styleUrls: ['./list-items-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemsDialogComponent implements OnInit {

  @ViewChild(MatSelectionList, { static: true }) rbListItem: MatSelectionList;

  public mainTitleDialog: any = '';
  public selectedCounter: any = 0;
  public itemsArray: ListItems[] = new Array();
  public selectedItems: ListItems[] = new Array();
  public selectedIds = new Array();
  public settings: any = {
    subtitles: []
  };
  public searchText: any = '';
  public checkAll: any = {
    id: '0',
    name: 'Todas',
    selected: false,
  };
  public showList: any = false;

  constructor(
    private dialogRef: MatDialogRef<ListItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
    this.selectedItems = new Array();
    this.itemsArray = new Array();
    this.selectedIds = new Array();
  }

  ngOnInit() {
    this.settings = this.dataDialog.settings;
    this.itemsArray = JSON.parse(JSON.stringify(this.dataDialog.itemsArray));
    this.selectedIds = this.dataDialog.selectedIds;
    this.mainTitleDialog = this.dataDialog.mainTitleDialog;
    this.buildSelecteds();
  }

  // gambi() {
  //   let index = 0;
  //   do {
  //     this.itemsArray = this.itemsArray.concat(this.itemsArray);
  //     index++;
  //   } while (index < 10);
  //   console.log('this.itemsArray', this.itemsArray);
  // }

  buildSelecteds() {
    for (const item of this.itemsArray) {
      for (const id of this.selectedIds) {
        if (item.id === id) {
          item.selected = true;
        }
      }
    }
    this.updateCounter();
  }

  addItems() {
    this.getSelectedItems();
    this.dialogRef.close(this.selectedItems);
  }

  getSelectedItems() {
    this.selectedIds = new Array();
    this.selectedItems = new Array();
    for (const item of this.itemsArray) {
      if (item.selected) {
        this.selectedIds.push(item.id);
        this.selectedItems.push(item);
      }
    }

  }

  selectAll(checkAll) {
    checkAll.selected = !checkAll.selected;
    const searchText = this.searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    for (const item of this.itemsArray) {
      if (this.searchText && this.searchText.length) {
        if (item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText)) {
          item.selected = checkAll.selected;
        }
      } else {
        item.selected = checkAll.selected;
      }
    }
  }

  updateCounter() {
    this.checkAll.selected = this.selectedItems.length === this.itemsArray.length ? true : false;
  }

  searchUpdateCounter() {
    this.onSelectedOptionsChange();
    setTimeout(() => {
      this.checkAll.selected = this.selectedItems.length === this.itemsArray.length ? true : false;
    }, 10);
  }


  clearFilter() {
    this.searchText = '';
    this.updateCounter();
  }

  public onSelectedOptionsChange() {
    setTimeout(() => {
      this.selectedItems = this.itemsArray.filter(option => {
        return option.selected;
      });
    });
  }

  changeItemValue(item) {
    item.selected = !item.selected;
    this.updateCounter();
  }

}
