import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

declare const RBTracking: any;

declare global {
  interface Window { dataLayer: any[]; }
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsModule {

  public scheduleSettings: any = {};
  public cityOptions: any[] = new Array();
  public applyedScripts: any[] = new Array();
  public gtmEventParams: any = {};

  constructor() {
  }

  setScheduleSettings(data) {
    this.scheduleSettings = data;
  }

  getScheduleSettings() {
    return this.scheduleSettings;
  }

  setCityOptions(data) {
    if (data) {
      this.cityOptions = data;
    }
  }

  getCityOptions() {
    return this.cityOptions;
  }

  initDataLayer() {
    const elem = document.createElement('script');
    elem.innerHTML = "dataLayer = [];"
    document.head.appendChild(elem)
  }

  setGtmEventParams(data) {
    this.gtmEventParams = data;
  }

  getGtmEventParams() {
    return this.gtmEventParams;
  }

  getDataLayerSetting(page: Number) {
    const filterGtmEventParam = eventParam => eventParam.page_id == page
    const getDataLayerObject = eventParam => eventParam.datalayer_object
    const gtmEventParams = this.getGtmEventParams()

    if (gtmEventParams != undefined && gtmEventParams && gtmEventParams.length > 0) {
      const dataLayerObject = gtmEventParams.filter(filterGtmEventParam).map(getDataLayerObject)
      return dataLayerObject[0] ? JSON.parse(dataLayerObject[0]) : undefined
    }
    return undefined
  }

  pushDataLayer(dataLayerObject) {
     if (dataLayerObject) {
       window.dataLayer.push(dataLayerObject)
     }
  }

  scriptManager(scripts) {
    // console.log('scripts', scripts);
    const newApplyedScripts = new Array();
    if (scripts && scripts.length) {
      for (const item of scripts) {
        let found = null;
        if (this.applyedScripts && this.applyedScripts.length) {
          found = this.applyedScripts.find((script) => {
            return (script.script_head && script.script_head === item.script_head)
            || (script.script_body && script.script_body === item.script_body);
          });
        }
        if (!found) {
          if (item.script_head) {
            this.chooseScriptType(item.type_script_id, item.script_head, 'head');
          }
          if (item.script_body) {
            this.chooseScriptType(item.type_script_id, item.script_body, 'body');
          }
        } else {
          if (typeof found === 'object' && found.script_head !== item.script_head ||
          (item.script_head && item.script_head.match('RBTracking') && typeof RBTracking === 'undefined')) {
            this.chooseScriptType(item.type_script_id, item.script_head, 'head');
          }
          if (typeof found === 'object' && found.script_body !== item.script_body ||
          (item.script_body && item.script_body.match('RBTracking') && typeof RBTracking === 'undefined')) {
            this.chooseScriptType(item.type_script_id, item.script_body, 'body');
          }
        }
        newApplyedScripts.push(item);
      }
    }
    this.applyedScripts = newApplyedScripts;
  }

  chooseScriptType(ScriptType, script, option) {
    switch (ScriptType) {
      // Tracking
      case '1': case 1:
        this.setUrlScriptIntoHtml(script, option);
        break;

      // GTM
      case '2': case 2:
        if (script.match('<script>') && script.match('</script>')) {
          this.appendFunctionScriptIntoHtml(script, option);
        }
        if (script.match('<noscript>') && script.match('</noscript>')) {
          this.appendGtmIframeIntoHtml(script, option);
        }
        break;

      default:
        this.setUrlScriptIntoHtml(script, option);
        break;
    }
  }

  setUrlScriptIntoHtml(script, option) {
    const regex = /<script.*?src="(.*?)"/;
    const elm = document.createElement('script');
    elm.type = 'text/javascript';
    // elm.src = script.replace('<script type="text/javascript" src="', '').replace('"></script>', '');
    elm.src = regex.exec(script)[1];
    // console.log('elm', elm);
    if (option === 'head') {
      document.head.appendChild(elm);
    } else if (option === 'body') {
      document.body.appendChild(elm);
    }
  }

  appendFunctionScriptIntoHtml(script, option) {
    const elm = document.createElement('script');
    elm.append(script.replace('<script>', '').replace('</script>', ''));
    // console.log('elm', elm);
    if (option === 'head') {
      document.head.appendChild(elm);
    } else if (option === 'body') {
      document.body.appendChild(elm);
    }
  }

  appendGtmIframeIntoHtml(script, option) {
    const regex = /<iframe.*?src="(.*?)"/;
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.height = '0';
    iframe.width = '0';
    iframe.src = regex.exec(script)[1];

    const noscript = document.createElement('noscript');
    noscript.append(iframe);
    // console.log('noscript', noscript);
    if (option === 'head') {
      document.head.appendChild(noscript);
    } else if (option === 'body') {
      document.body.appendChild(noscript);
    }
  }
}
