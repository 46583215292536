import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientManageDomainPageModule } from './client-manage-domain-page.module';
import {ClientSettingsModule} from '../../../models/client-settings/client-settings.module';

@Component({
  selector: 'app-client-manage-domain',
  templateUrl: './client-manage-domain.component.html',
  styleUrls: ['./client-manage-domain.component.scss']
})
export class ClientManageDomainComponent implements OnInit {

  public isVisible = false;
  public manageDomainData = null;
  public scheduleSettings: any = {};

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public clientManageDomainPageModule: ClientManageDomainPageModule,
    private clientSettingsModule: ClientSettingsModule,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.manageDomainData = this.clientManageDomainPageModule.getData();
    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
    if (this.manageDomainData && this.manageDomainData.token) {
      this.goToStage1();
    } else {
      this.renderer.addClass(document.body, 'rbPage404');
      // this.renderer.addClass(document.getElementsByTagName('app-client-main-bar')[0], 'rbPage404');
      this.isVisible = true;
    }
  }

  goToStage1() {
    const uri = this.scheduleSettings && this.scheduleSettings.uri ?
    this.scheduleSettings.uri :
    (this.manageDomainData && this.manageDomainData.uri ? this.manageDomainData.uri : '');

    let routeNavigate = uri.split('/');
    if (routeNavigate && routeNavigate.length) {
      routeNavigate.push('agenda');
      routeNavigate.push('etapa1');
    } else {
      routeNavigate =  ['agenda', 'etapa1'];
    }
    this.router.navigate(routeNavigate);
    // this.router.navigate([
    //   uri,
    //   'agenda',
    //   'etapa1'
    // ]);
  }

}
