import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class UserModule {

  public userData: any;
  public uriAdmin: any;

  setData(data) {
    if (data) {
      this.userData = data;
    } else {
      this.userData = {};
    }
  }

  getData() {
    return this.userData;
  }

  setUriAdmin(uriAdmin) {
    if (uriAdmin) {
      this.uriAdmin = uriAdmin;
    }
  }

  getUriAdmin() {
    if (!this.uriAdmin && sessionStorage.getItem('schedulingUriAdm')
    && sessionStorage.getItem('schedulingUriAdm') !== 'undefined') {
      this.uriAdmin = sessionStorage.getItem('schedulingUriAdm');
    }
    return this.uriAdmin;
  }

  isLogged() {
    if (this.userData && this.userData.id) {
      return true;
    } else {
      return false;
    }
  }


}
