<form class="rb-default-dialog" [formGroup]="fieldsFormGroup">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">{{fieldData.id ? 'Editar' : 'Adicionar'}} campo</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content rb-has-overflow">
    <div class="rb-padding-14x24" style="padding-right: 38px;" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" 
      fxFlex.xs="0 0 calc(100% - 10px)">
        <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
          <mat-label class="rb-label-black">Campo do Rubeus</mat-label>
          <input matInput
          placeholder="Selecione um campo"
          [matAutocomplete]="auto"
          required
          type="text"
          [formControlName]="'attribute'"
          [(ngModel)]="fieldData.attribute">
          <mat-autocomplete
          (optionSelected)="setTitleField(fieldData.attribute)"
          #auto="matAutocomplete"
          autoActiveFirstOption="true"
          [displayWith]="displayFn">
            <mat-option *ngFor="let field of filteredFields | async" [value]="field">
              <span>{{field.nome}}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error>Selecione o campo correspondente</mat-error>
        </mat-form-field>
      </div>
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" 
      fxFlex.xs="0 0 calc(100% - 10px)">
        <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
          <mat-label class="rb-label-black">Título do campo</mat-label>
          <input matInput #titleField
          [formControlName]="'title'"
          required
          type="text"
          maxlength="50"
          (change)="setManualValueDefined()"
          [(ngModel)]="fieldData.title">
          <mat-hint>Será o título do campo apresentado na agenda</mat-hint>
          <mat-hint align="end">{{titleField.value?.length || 0}}/{{titleField.maxLength}}</mat-hint>
          <mat-error>Informe um título para o campo</mat-error>
        </mat-form-field>
      </div>
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" 
      fxFlex.xs="0 0 calc(100% - 10px)"
      *ngIf="!hideHintHelp">
        <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
          <mat-label class="rb-label-black">Dica do campo</mat-label>
          <input matInput #hintField
          [formControlName]="'hint'"
          type="text"
          maxlength="50"
          [(ngModel)]="fieldData.hint">
          <mat-hint>Texto auxiliar abaixo do campo</mat-hint>
          <mat-hint align="end">{{hintField.value?.length || 0}}/{{hintField.maxLength}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" 
      fxFlex.xs="0 0 calc(100% - 10px)"
      *ngIf="!hideHintHelp">
        <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
          <mat-label class="rb-label-black">Texto de ajuda</mat-label>
          <input matInput #helpField
          [formControlName]="'help'"
          type="text"
          maxlength="100"
          [(ngModel)]="fieldData.help">
          <mat-hint>Texto escondido em um ícone de interrogação</mat-hint>
          <mat-hint align="end">{{helpField.value?.length || 0}}/{{helpField.maxLength}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(50% - 10px)" 
      fxFlex.xs="0 0 calc(100% - 10px)">
        <mat-slide-toggle
          class="rb-slide-toggle"
          color="primary"
          [formControlName]="'required'"
          [(ngModel)]="fieldData.required"
          [checked]="fieldData.required==1 || fieldData.required==true || isCpfType==1"
          [disabled]="fieldData.cannot_delete==1 || isCpfType==1">
          Campo obrigatório
        </mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <button mat-stroked-button
        [mat-dialog-close]="false"
        color="primary"
        class="rb-btn-upload">
        <span>Cancelar</span>
      </button>
      <div class='rbButtonsDivisor'></div>
      <button mat-raised-button
        [disabled]="fieldsFormGroup.invalid || getSelectedAttributeType()!=='object'"
        color="primary"
        (click)="saveField()">
        <span>Salvar</span>
      </button>
    </div>
  </div>
</form>


