import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { NoticeComponent } from '../../components/notice/notice.component';
import { ClientLoaderService } from '../../components/client-loader/client-loader.service';
import { ClientUserModule } from './client-user.module';
import { ClientManageDomainPageModule } from '../../components/client-manage-domain/client-manage-domain/client-manage-domain-page.module';
import { ClientSettingsModule } from '../client-settings/client-settings.module';
import { CrmClientLinkValidatorPageModule } from 'src/app/modules/client/crm-client-link-validator/crm-client-link-validator-page/crm-client-link-validator-page.module';

@Injectable({
  providedIn: 'root'
})
export class GetClientUserDataService extends Request implements Resolve<GetClientUserDataService> {

  private response: any;
  private manageDomainData: any;
  private clientSettings: any = {};
  public scheduleSettings: any;

  constructor(
    private clientUserModule: ClientUserModule,
    private http: HttpService,
    private clientLoaderService: ClientLoaderService,
    protected notice: NoticeComponent,
    private router: Router,
    public clientManageDomainPageModule: ClientManageDomainPageModule,
    public clientSettingsModule: ClientSettingsModule,
    public crmClientLinkValidatorPageModule: CrmClientLinkValidatorPageModule,
  ) {
    super();
  }

  private param = '';

  resolve(
  ): Observable<any> | Promise<any> | any {
    this.clientLoaderService.setChangesLoaderState(true);

    this.url = 'user/logged';

    return this.http.get(this)
      .then((data) => this.response);
  }

  successAction(data) {
    this.response = data;
    if (data && data.success) {
      this.clientUserModule.setData(data.user);
      this.clientUserModule.setRequireFullRegistration(data.requireFullRegistration);
      let dataLink = this.crmClientLinkValidatorPageModule.getData();
      if (dataLink && data.user && data.user.requireLgpdTerms == false) {
        dataLink.requireLgpdTerms = false;
        if (dataLink && dataLink.person && dataLink.person.requireLgpdTerms) {
          dataLink.person.requireLgpdTerms = false;
        }
        this.crmClientLinkValidatorPageModule.setData(dataLink);
      }

    } else {
      this.clientUserModule.setData(null);
    }
    this.clientLoaderService.setChangesLoaderState(false);
    return this.clientUserModule.getData();
  }

  errorAction(error) {
    this.clientLoaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
