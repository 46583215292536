import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ExceptionalHoursCrudPageModule {

  public exceptionalHoursArray: any[] = new Array();


  setData(data) {
    this.exceptionalHoursArray = data;
    this.buildTitles();
  }

  buildTitles() {
    if (this.exceptionalHoursArray && this.exceptionalHoursArray.length) {
      for (const item of this.exceptionalHoursArray) {
        if (item && item.all_groups) {
          item.group_name = 'Todos';
        }
        if (item && item.all_services) {
          item.service_name = 'Todos';
        }
        if (item && item.all_employee) {
          item.person_name = 'Todos';
        }
      }
    }
  }

  getData() {
    return this.exceptionalHoursArray;
  }

}