import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RbDinamicFaviconService {
  public favicon = null;
  public linkElement: any;

  constructor(
    ) {
      this.buildFavicon();
  }

  buildFavicon() {
    this.linkElement = document.createElement( 'link' );
    this.linkElement.setAttribute( 'rel', 'icon' );
    this.linkElement.setAttribute( 'type', 'image/png' );
    this.linkElement.setAttribute( 'href', this.favicon );
    document.head.appendChild( this.linkElement );
  }

  setNewFavicon(value) {
    this.favicon = value;
    this.buildFavicon();
  }

}
