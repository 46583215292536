import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FlowSchedulingPageModule {

  public stage1FormStatus = new Subject<any>();
  public stage2FormStatus = new Subject<any>();
  public stage3FormStatus = new Subject<any>();


  public flowSchedulingData: any = {
    person: {},
    scheduling: {},
    complementFields: [],
    stage1Completed: false,
    stage2Completed: false,
    stage3Completed: false,
    title_text_stage1: '',
    title_text_stage2: '',
    title_text_stage3: ''
  };

  public lgpdSettings: any;

  public availableServices: any[] = new Array();
  public availableDates: any[] = new Array();

  public backupStage2: any = null;
  public backupStage3: any = null;
  public backupComplementFieldStage2: any = null;
  public backupComplementFieldStage3: any = null;

  setData(data) {
    if (data && data.success) {
      this.flowSchedulingData.person = data.person ? data.person : {};
      this.flowSchedulingData.scheduling = data.scheduling ? data.scheduling : {};
      this.flowSchedulingData.complementFields = data.complementFields ? data.complementFields : [];
      this.flowSchedulingData.stage1Completed = data.stage1Completed ? data.stage1Completed : false;
      this.flowSchedulingData.stage2Completed = data.stage2Completed ? data.stage2Completed : false;
      this.flowSchedulingData.stage3Completed = data.stage3Completed ? data.stage3Completed : false;
    } else {
      this.flowSchedulingData = {
        person: {},
        scheduling: {},
        complementFields: [],
        stage1Completed: false,
        stage2Completed: false,
        stage3Completed: false,
      };
    }
    if(data && data.scheduleStageTitles != null){
      this.flowSchedulingData.title_text_stage1 = data.scheduleStageTitles.title_text_stage1 ? data.scheduleStageTitles.title_text_stage1 : 'Olá, preencha seus dados para realizar seu agendamento.';
      this.flowSchedulingData.title_text_stage2 = data.scheduleStageTitles.title_text_stage2 ? data.scheduleStageTitles.title_text_stage2 : 'Escolha um serviço para agendar';
      this.flowSchedulingData.title_text_stage3 = data.scheduleStageTitles.title_text_stage3 ? data.scheduleStageTitles.title_text_stage3 : '';
    } else {
      this.flowSchedulingData.title_text_stage1 = 'Olá, preencha seus dados para realizar seu agendamento.';
      this.flowSchedulingData.title_text_stage2 = 'Escolha um serviço para agendar';
      this.flowSchedulingData.title_text_stage3 = '';
      this.flowSchedulingData.logout = data.logout ? data.logout : true
    }
  }

  getData() {
    return this.flowSchedulingData;
  }

  setLgpdSettings(data) {
    if (data && data.success) {
      this.lgpdSettings = data.data;
    }else{
      this.lgpdSettings = {};
    }
  }

  getLgpdSettings() {
    return this.lgpdSettings;
  }

  getPersonData() {
    return this.flowSchedulingData.person;
  }

  getSchedulingData() {
    return this.flowSchedulingData.scheduling;
  }

  getComplementFieldsData() {
    return this.flowSchedulingData.complementFields;
  }

  setAvailableServices(data) {
    if (data) {
      this.availableServices = data;
    } else {
      this.availableServices = new Array();
    }
  }

  getAvailableServices() {
    return this.availableServices;
  }

  setAvailableDates(data) {
    if (data) {
      this.availableDates = data;
    } else {
      this.availableDates = new Array();
    }
  }

  getAvailableDates() {
    return this.availableDates;
  }

  setStage1FormStatus(value: any) {
    this.stage1FormStatus.next(value);
  }

  getStage1FormStatus(): Observable<any> {
    return this.stage1FormStatus.asObservable();
  }

  setStage2FormStatus(value: any) {
    this.stage2FormStatus.next(value);
  }

  getStage2FormStatus(): Observable<any> {
    return this.stage2FormStatus.asObservable();
  }

  setStage3FormStatus(value: any) {
    this.stage3FormStatus.next(value);
  }

  getStage3FormStatus(): Observable<any> {
    return this.stage3FormStatus.asObservable();
  }

  setBackupStage2(value) {
    this.backupStage2 = value;
  }

  getBackupStage2() {
    return this.backupStage2;
  }

  setBackupComplementFieldStage2(value) {
    this.backupComplementFieldStage2 = value;
  }

  getBackupComplementFieldStage2() {
    return this.backupComplementFieldStage2;
  }

  setBackupStage3(value) {
    this.backupStage3 = value;
  }

  getBackupStage3() {
    return this.backupStage3;
  }

  setBackupComplementFieldStage3(value) {
    this.backupComplementFieldStage3 = value;
  }

  getBackupComplementFieldStage3() {
    return this.backupComplementFieldStage3;
  }

  clearDataModel() {
    this.setData(null);
    this.setAvailableDates(null);
    this.setAvailableServices(null);
    this.setBackupStage2(null);
    this.setBackupStage3(null);
  }
}
