import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { HttpService } from '../../../../../core/http/http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { ClientLoaderService } from '../../../../../shared/components/client-loader/client-loader.service';
import { CrmClientLinkValidatorPageModule } from '../crm-client-link-validator-page.module';
import { ClientManageDomainPageModule } from 'src/app/shared/components/client-manage-domain/client-manage-domain/client-manage-domain-page.module';
import { ClientSettingsModule } from 'src/app/shared/models/client-settings/client-settings.module';
import { ClientUserModule } from 'src/app/shared/models/client-user/client-user.module';

@Injectable({
  providedIn: 'root'
})
export class CheckCrmClientTokenValidationService extends Request implements Resolve<CheckCrmClientTokenValidationService> {

  public url = '';
  public response: any;
  public manageDomainData: any = {};
  public clientSettings: any = {};

  constructor(
    private crmClientLinkValidatorPageModule: CrmClientLinkValidatorPageModule,
    private http: HttpService,
    private router: Router,
    private clientLoaderService: ClientLoaderService,
    protected notice: NoticeComponent,
    private platformLocation: PlatformLocation,
    public clientManageDomainPageModule: ClientManageDomainPageModule,
    private clientSettingsModule: ClientSettingsModule,
    private clientUserModule: ClientUserModule
  ) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    this.clientLoaderService.setChangesLoaderState(true);
    localStorage.removeItem('rbSchedulingClientToken')
    sessionStorage.removeItem('rbSchedulingClientToken')
    const sessionSchedulingToken = sessionStorage.getItem('schedulingToken');
    const urlTokenParam = route.paramMap.get('schedulingToken');

    // const path = route && route.routeConfig && route.routeConfig.path ? '/' + route.routeConfig.path : '';

    this.data = {
      // url: path ? this.platformLocation.href.replace(path, '') : this.platformLocation.href,
      url: this.platformLocation.href,
      schedulingToken: sessionSchedulingToken ? sessionSchedulingToken : (urlTokenParam ? urlTokenParam : '')
    };
    this.url = 'flow-scheduling/check-link';
    return this.http.post(this)
    .then((data) => {
      return this.response;
    });
  }

  successAction(data) {
    if (data && data.success) {
      if (data.schedulingToken) {
        sessionStorage.setItem('schedulingToken', data.schedulingToken);
      }
      sessionStorage.setItem('rbUseSessionStorage', 'true');
      this.crmClientLinkValidatorPageModule.setData(data.person);
      // this.clientUserModule.setData(data.person);
    } else {
      this.crmClientLinkValidatorPageModule.setData(null);

      if(data.typeError=='duplicate_email' || data.typeError=='duplicate_cpf') {
        this.notice.showMessageError('O e-mail ou cpf ja existem no sistema, por favor efetue login');
        this.goToStep1();
      }
    }
    this.response = data;
  }


  errorAction(error) {
    this.clientLoaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }


  goToStep1() {
    this.manageDomainData = this.clientManageDomainPageModule.getData();
    const uri = this.clientSettings && this.clientSettings.uri ?
    this.clientSettings.uri :
    (this.manageDomainData && this.manageDomainData.uri ? this.manageDomainData.uri : '');
    this.router.navigate([uri, 'agenda', 'etapa1']);
    let routeNavigate = uri.split('/');
    if (routeNavigate && routeNavigate.length) {
      routeNavigate.push('agenda');
      routeNavigate.push('etapa1');
    } else {
      routeNavigate =  ['agenda', 'etapa1'];
    }
    this.router.navigate(routeNavigate);
  }
}
