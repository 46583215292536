<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">Atenção</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="true" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <div fxFlex="100" fxLayout="row wrap">
      <div fxFlex="100" style="text-align: start; padding: 0 20px;">
        <p class="dialog-title">Você está alterando uma configuração geral.</p>
      </div>
      <form fxFlex="100" style="padding: 20px;" [formGroup]='settingsForm'>
        <p class="dialog-sub-title"><i>Como deseja que ela seja aplicada?</i></p>
        <mat-radio-group class="lgpd-settings-radio-group form-control" [(ngModel)]="optionValue"
          [formControlName]="'settingOption'" required color="primary">
          <mat-radio-button class="lgpd-settings-radio-button" *ngFor="let option of settingsOption"
            [value]="option.value">
            <span class="rb-label">{{ option.label }}</span>
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <button mat-stroked-button [mat-dialog-close]="true" color="primary" class="rb-btn-upload">
        <span>Cancelar</span>
      </button>
      <div class="rbButtonsDivisor"></div>
      <button mat-raised-button color="primary" [disabled]="settingsForm.invalid" (click)="callSave()">
        <span>Salvar</span>
      </button>
    </div>
  </div>
</div>
