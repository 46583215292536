<mat-toolbar class="mat-toolbar whiteBar mat-elevation-z3 rb-main-bar">
  <mat-toolbar-row>
    <div class="logo-navbar bar-logo" fxLayout="row" fxLayoutAlign="center center" fxHide.xs>
      <img src="{{scheduleSettings.institutional_logo}}"/>
    </div>
    <div class="logo-navbar-mobile bar-logo" fxHide.gt-xs fxLayout="row" fxLayoutAlign="center center">
      <img src="{{scheduleSettings.reduced_institutional_logo}}"/>
    </div>
    <div class="logo-title-divider">
      <span></span>
    </div>
    <div class="main-bar-description-title">
      {{scheduleSettings.schedule_name}}
    </div>
    <div fxFlex></div>
    <button mat-button 
      class="rb-main-bar-button"
      color="accent"
      *ngIf="!clientUserModule.isLogged() 
      && scheduleSettings.type_schedule_id==2"
      (click)="openLoginDialog(menu)">
        <span>Entrar</span>
    </button>
    <!-- <button mat-button 
      class="rb-main-bar-button"
      color="accent"
      *ngIf="clientUserModule.isLogged() && 
      (!validatedByGeneratedLink && scheduleSettings.type_schedule_id==2)" 
      [ngClass]="{selectedButton: menuTrigger.menuOpen}"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu">
        <span>{{clientUserModule.getFirstName()}}</span>
        <mat-icon aria-label="abrir menu">keyboard_arrow_down</mat-icon>
    </button> -->
    <button mat-button 
      class="rb-main-bar-button"
      color="accent"
      *ngIf="clientUserModule.isLogged() && scheduleSettings.type_schedule_id==2" 
      [ngClass]="{selectedButton: menuTrigger.menuOpen}"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu">
        <span>{{clientUserModule.getFirstName()}}</span>
        <mat-icon aria-label="abrir menu">keyboard_arrow_down</mat-icon>
    </button>
    <button mat-button 
    class="rb-main-bar-button"
    color="accent"
    *ngIf="(crmPersonData && crmPersonData.id && scheduleSettings.type_schedule_id==1 )"
    [disabled]="true" style="color: inherit !important;">
        <span *ngIf="crmPersonData.name">{{crmPersonData.nomeResponsavel}}</span>
    </button>
    <mat-menu #menu="matMenu" overlapTrigger="false">
      <button (click)="goToMySchedules()" [ngClass]="{rbActived: currentPage === '/agenda/meus-agendamentos'}" mat-menu-item>Meus Agendamentos</button>
      <button (click)="goToPersonalData()" [ngClass]="{rbActived: currentPage==='/agenda/dados-pessoais'}" mat-menu-item>Meus dados</button>
      <button (click)="goToChangePass()" [ngClass]="{rbActived: currentPage==='/agenda/alterar-senha'}" mat-menu-item>Alterar Senha</button>
      <button (click)="logout()" mat-menu-item>Sair</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>