<div class="rb-padding-24x0" fxLayout="row wrap" fxLayoutAlign="center center">
  <div fxFlex="100" *ngIf="!selectedAttendantOptions.length">
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.xs="80" fxFlex.sm="60" fxFlex="30" class="rbAlignCenter">
        <h1 class="rb-main-title">Quem atenderá a estes serviços?</h1>
        <p class="rb-main-subtitle">
          É necessário adicionar ao menos um atendente para poder criar um serviço.
        </p>
      </div>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
      <button mat-stroked-button matStepperPrevious
        color="primary"
        class="rb-btn-upload">
        <span>Voltar</span>
      </button>
      <div class='rbButtonsDivisor'></div>
      <button mat-raised-button
        color="primary"
        (click)="addAttendant()">
        <span>Adicionar um atendente</span>
      </button>
    </div>
  </div>
  <div fxFlex="100" *ngIf="selectedAttendantOptions.length">
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
      <button mat-raised-button
        color="primary"
        (click)="addAttendant()">
        <span>Adicionar um atendente</span>
      </button>
    </div>
    <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="100">
        <rb-crud
          [(receivedData)] = "selectedAttendantOptions"
          [(columns)] = "crudSettings"
          [(metaCount)] = "crudMetaCount"
          [msgCrudEmpty]="msgCrudEmpty"
          [parentScope]="this">
        </rb-crud>
      </div>
    </div>
  </div>
</div>

