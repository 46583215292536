import { Component, OnInit, Input } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-color-palette-generator',
  templateUrl: './color-palette-generator.component.html',
  styleUrls: ['./color-palette-generator.component.scss']
})

export class ColorPaletteGeneratorComponent implements OnInit {

  @Input() mainTitle: any;
  @Input() colorBaseModel: any;
  @Input() colorBaseObj: any;
  @Input() displayEditButton: boolean;
  @Input() mainColor: any;
  @Input() displayView: boolean;
  @Input() displayRefresh: boolean;
  @Input() outputFormat: any;
  @Input() alternativeCaption: string;
  @Input() displayCode: boolean;

  public hasChange = new Subject<any>();
  public displayedPalette = false;
  public paletteSettings: any = {};
  public colorBaseBackup = '';
  constructor(
    // private matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.setInitialColorBase();
  }

  setInitialColorBase() {
    if (!this.colorBaseObj[this.colorBaseModel]) {
      // this.colorBaseObj[this.colorBaseModel] = this.mainColor ? this.mainColor : '#10807f';
      this.colorBaseObj[this.colorBaseModel] = this.mainColor ? this.mainColor : null;
    }
    // this.paletteSettings.base = this.colorBaseObj[this.colorBaseModel];
    this.paletteSettings.base = this.convertColorString(this.colorBaseObj[this.colorBaseModel]);

    this.colorBaseBackup = this.paletteSettings.base;

    this.changePalette(0);
  }

  getPaletteSettings() {
    return this.paletteSettings;
  }

  getPaletteCode() {
    return this.paletteSettings.angularCodeString;
  }

  changePalette(value) {
    const colorCode =  this.convertColorString(this.paletteSettings.base);
    this.colorBaseObj[this.colorBaseModel] = colorCode;
    this.paletteSettings.colors =  this.buildNewPalette(colorCode);
    this.createPaletteStrCode(this.paletteSettings.colors);

    this.setHasChange(value);
  }

  convertColorString(color) {
    switch (this.outputFormat) {
      case 'hex':
        return new TinyColor(color).toHexString();
        break;

      case 'rgba':
        return new TinyColor(color).toRgbString();
        break;

      default:
        return new TinyColor(color).toHexString();
        break;
    }
  }

  buildNewPalette(hex) {
      const lightBase = new TinyColor('#ffffff');
      const darkBase = this.buildDarkBase(new TinyColor(hex).toRgb(), new TinyColor(hex).toRgb());
      const triadbase = new TinyColor(hex).tetrad();
      return [
        this.getColorObject(this.rbMixColor(lightBase, hex, 12), '50'),
        this.getColorObject(this.rbMixColor(lightBase, hex, 30), '100'),
        this.getColorObject(this.rbMixColor(lightBase, hex, 50), '200'),
        this.getColorObject(this.rbMixColor(lightBase, hex, 70), '300'),
        this.getColorObject(this.rbMixColor(lightBase, hex, 85), '400'),
        this.getColorObject(this.rbMixColor(lightBase, hex, 100), '500'),
        this.getColorObject(this.rbMixColor(darkBase, hex, 87), '600'),
        this.getColorObject(this.rbMixColor(darkBase, hex, 70), '700'),
        this.getColorObject(this.rbMixColor(darkBase, hex, 54), '800'),
        this.getColorObject(this.rbMixColor(darkBase, hex, 25), '900'),
        this.getColorObject(this.rbMixColor(darkBase, triadbase[4], 15).saturate(80).lighten(65), 'A100'),
        this.getColorObject(this.rbMixColor(darkBase, triadbase[4], 15).saturate(80).lighten(55), 'A200'),
        this.getColorObject(this.rbMixColor(darkBase, triadbase[4], 15).saturate(100).lighten(45), 'A400'),
        this.getColorObject(this.rbMixColor(darkBase, triadbase[4], 15).saturate(100).lighten(40), 'A700')
      ];
  }

  buildDarkBase(rgb1, rgb2) {
    rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
    rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
    rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
    return new TinyColor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  }

  getColorObject(value: any, name: any) {
    const c = new TinyColor(value);
    return {
      name: name,
      hex: c.toHexString(),
      hexValue: c.toHex(),
      rgbValue: c.toRgb(),
      rgbString: c.toRgbString(),
      darkContrast: c.isLight()
    };
  }

  rbMixColor(baseLight, hex, value) {
    return baseLight.mix(hex, value);
  }

  public refreshColor: any = (value) => {
    if (!value || value === '') {
      this.colorBaseObj[this.colorBaseModel] = '#10807f';
    } else {
      this.colorBaseObj[this.colorBaseModel] = value;
    }
    this.paletteSettings.base = this.colorBaseObj[this.colorBaseModel];
    this.changePalette(0);
  }

  showPalette() {
    this.displayedPalette = !this.displayedPalette;
  }

  showPaletteCode() {
    // const showPaletteCodeDialog = new MatDialogConfig();
    // showPaletteCodeDialog.autoFocus = false;
    // showPaletteCodeDialog.restoreFocus = false;
    // showPaletteCodeDialog.minWidth = '50%';
    // showPaletteCodeDialog.maxWidth = '95%';
    // showPaletteCodeDialog.minHeight = '25%';
    // showPaletteCodeDialog.maxHeight = '95%';
    // showPaletteCodeDialog.panelClass = 'rb-dialog-container';
    // showPaletteCodeDialog.data = this.paletteSettings.angularCodeString;
    // const showPaletteCodeComponentRef =  this.matDialog.open(ShowPaletteCodeComponent, showPaletteCodeDialog);
  }

  createPaletteStrCode(arrayColors) {
    // console.log('objectRef', arrayColors);
    const colors = [];
    let palleteCode = '$md-mcgpalette0:( ';
    palleteCode += `
        `;

    for (const item of arrayColors) {
      palleteCode += item.name + ' : ' + item.hex + ', ';
      palleteCode += `
        `;
    }
    palleteCode += 'contrast: (';
    palleteCode += `
          `;
    for (const item of arrayColors) {
      palleteCode += item.name + ' : ' + (item.darkContrast ? '#000000' : '#ffffff') + ', ';
      if ( item + 1 < arrayColors.length) {
        palleteCode += `
          `;
      } else {
        palleteCode += `
        `;
      }
    }
    palleteCode += `)
    );`;
    this.paletteSettings.angularCodeString = palleteCode;
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }
}


