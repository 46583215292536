import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { SaveTitleStageService } from './services/save-title-stage.service';

@Component({
  selector: 'app-header-stage-edit',
  templateUrl: './header-stage-edit.component.html',
  styleUrls: ['./header-stage-edit.component.scss']
})
export class HeaderStageEditComponent implements OnInit {

  public headerFormGroup: FormGroup;
  public description: any;
  public stage: any;
  public index: any;
  public group_id: any;

  constructor(
    public saveTitleStageService: SaveTitleStageService,
    private dialogRef: MatDialogRef<HeaderStageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,) {
      this.headerFormGroup = new FormGroup({
        stage: new FormControl({value: '', disabled: true}, [Validators.required]),
        description: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      });
      this.index = dataDialog.indexStage;
      this.group_id = dataDialog.group_id;
      this.stage = (dataDialog.indexStage+1)+'° Etapa';
      if(dataDialog.title == null){
        if(dataDialog.indexStage == 0){
          this.description = 'Olá, preencha seus dados para realizar seu agendamento.';
        } else if(dataDialog.indexStage == 1) {
          this.description = 'Escolha um serviço para agendar';
        } else {
          this.description = '';
        }
      } else {
        this.description = dataDialog.title;
      }
    }

  ngOnInit() {
    
  }

  saveTitleStage(){
    const dataSave = {
      index : this.index,
      description: this.description,
      id: this.group_id
    };
    this.saveTitleStageService.resolve(dataSave).then((response: any) => {
      if (response) {
        this.dialogRef.close(response);
      }
    });
  }

  textareaChange(item){
    this.description = item;
  }

}
