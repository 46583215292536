import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
@Injectable({
  providedIn: 'root',
})
export class ServiceRegisterModule {
  public serviceData: any = {};
  public requiredSegments = new Subject<any>();

  setData(data) {
    this.serviceData = data;
  }

  getData() {
    return this.serviceData;
  }

  getSelectedSegmentations() {
    return this.serviceData.segmentation
      ? this.serviceData.segmentation
      : new Array();
  }

  getWeek() {
    return this.serviceData.week ? this.serviceData.week : new Array();
  }

  getSelectedAttendants() {
    return this.serviceData.attendants
      ? this.serviceData.attendants
      : new Array();
  }

  setId(id) {
    if (!this.serviceData.id) {
      this.serviceData.id = id;
    }
  }

  setGroupId(id) {
    if (!this.serviceData.group_id) {
      this.serviceData.group_id = id;
    }
  }

  getSegmentationsOrder() {
    return this.serviceData.segmentation_order_by_title && this.serviceData.segmentation_order_by_title == 1 ? true : false;
  }


  
  setRequiredSegments(value) {
    this.requiredSegments.next(value);
  }

  getRequiredSegments(): Observable<any> {
    return this.requiredSegments.asObservable();
  }

}
