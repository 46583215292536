import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { NoticeComponent } from '../../components/notice/notice.component';
import { ClientLoaderService } from '../../components/client-loader/client-loader.service';
import { ClientUserModule } from './client-user.module';

@Injectable({
  providedIn: 'root'
})
export class ClientLogoutService extends Request implements Resolve <ClientLogoutService> {

  private response: any;

  constructor(
    private clientUserModule: ClientUserModule,
    private http: HttpService,
    private clientLoaderService: ClientLoaderService,
    protected notice: NoticeComponent
  ) {
    super();
  }

  resolve(
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.clientLoaderService.setChangesLoaderState(true);
    this.url = 'user/logout';
    this.data = parameters;

    return this.http.get(this)
      .then((data) => {
        return this.response;
      });
  }


  successAction(data) {
    this.response = data;
    if (data && data.success) {
      this.clientUserModule.setData(null);
      localStorage.removeItem('rbSchedulingClientToken');
      sessionStorage.removeItem('defaultToken');
      this.notice.showMessageSuccess('Sessão encerrada');
    } else {
      this.clientUserModule.setData(null);
    }
    this.clientLoaderService.setChangesLoaderState(false);
    return this.clientUserModule.getData();
  }


  errorAction(error) {
    this.clientLoaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }


}
