import { NgModule, Pipe, PipeTransform  } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule, MatProgressBarModule} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from '@angular/material';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';

import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { CrudModule } from 'rubeus-components';

import { ScheduleCrudPageComponent } from './schedule-crud-page/schedule-crud-page.component';
import { ScheduleRegisterPageComponent } from './schedule-register-page/schedule-register-page.component';
import { ScheduleIdentityComponent } from './schedule-register-page/components/schedule-identity/schedule-identity.component';
import { ScheduleServicesComponent } from './schedule-register-page/components/schedule-services/schedule-services.component';
import { ScheduleCustomFieldsComponent } from './schedule-register-page/components/schedule-custom-fields/schedule-custom-fields.component';
import { ScheduleSettingsComponent } from './schedule-register-page/components/schedule-settings/schedule-settings.component';
import { ColorPaletteGeneratorModule } from '../../../shared/components/color-palette-generator/color-palette-generator.module';
import { ImageUploadModule } from '../../../shared/components/image-upload/image-upload.module';
import { ServiceRegisterComponent } from './service-register-dialog/service-register.component';
import { ServiceSettingsComponent } from './service-register-dialog/components/service-settings/service-settings.component';
import { ServiceSegmentationsComponent } from './service-register-dialog/components/service-segmentations/service-segmentations.component';
import { ListItemsDialogComponent } from '../../../shared/components/list-items-dialog/list-items-dialog.component';
import { WeekDaysTabsComponent } from '../../../shared/components/week-days-tabs/week-days-tabs.component';
import { FilterPipe } from '../../../shared/services/filter-pipe/filter-pipe.service';
import { SortPipeService } from '../../../shared/services/sort-pipe/sort-pipe.service';
import { ServiceAttendantsComponent } from './service-register-dialog/components/service-attendants/service-attendants.component';
import {
  ServiceAttendanceIntervalComponent
} from './service-register-dialog/components/service-attendance-interval/service-attendance-interval.component';
import { AttendanceHoursDialogComponent } from '../../../shared/components/attendance-hours-dialog/attendance-hours-dialog.component';

import {NgxMaskModule} from 'ngx-mask';
import { FieldRegisterDialogComponent } from './field-register-dialog/field-register-dialog.component';
import { HeaderStageEditComponent } from './header-stage-edit/header-stage-edit.component';
import { ScheduleLinkDialogComponent } from '../../../shared/components/schedule-link-dialog/schedule-link-dialog.component';
import { ScheduleLgpdOptionsComponent } from './schedule-register-page/components/schedule-lgpd-options/schedule-lgpd-options.component';
import { RbCustomTinymceComponent } from './schedule-register-page/components/rb-custom-tinymce/rb-custom-tinymce.component';
import { EditorModule } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [
    ScheduleCrudPageComponent,
    ScheduleRegisterPageComponent,
    ScheduleIdentityComponent,
    ScheduleServicesComponent,
    ScheduleCustomFieldsComponent,
    ScheduleSettingsComponent,
    ServiceRegisterComponent,
    ServiceSettingsComponent,
    ServiceSegmentationsComponent,
    ListItemsDialogComponent,
    WeekDaysTabsComponent,
    FilterPipe,
    SortPipeService,
    ServiceAttendantsComponent,
    ServiceAttendanceIntervalComponent,
    AttendanceHoursDialogComponent,
    FieldRegisterDialogComponent,
    ScheduleLinkDialogComponent,
    ScheduleLgpdOptionsComponent,
    RbCustomTinymceComponent,
    HeaderStageEditComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatMenuModule,
    MatExpansionModule,
    MatTreeModule,
    ScrollingModule,
    DragDropModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTableModule,
    ColorPaletteGeneratorModule,
    ImageUploadModule,
    NgxMaskModule,
    CrudModule,
    EditorModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
  entryComponents: [
    ServiceRegisterComponent,
    ListItemsDialogComponent,
    AttendanceHoursDialogComponent,
    FieldRegisterDialogComponent,
    HeaderStageEditComponent,
    ScheduleLinkDialogComponent,
  ]
})
export class SchedulesModule { }
