import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ScheduleIdentityModule {

  public scheduleIdentityData: any = {};
  public bkpIdentityData: any = {};
  public savedIdentityData = new Subject<any>();
  public hasChange = new Subject<any>();


  setData(data) {
    if (data) {
      this.scheduleIdentityData = data;

      this.bkpIdentityData = JSON.parse(JSON.stringify(data));
      this.setHasChange(0);
    } else {
      this.scheduleIdentityData = {};
      this.bkpIdentityData = {};
    }
  }

  getData() {
    return this.scheduleIdentityData;
  }

  setSavedData(value) {
    this.savedIdentityData.next(value);
  }

  getSavedData(): Observable<any> {
    return this.savedIdentityData.asObservable();
  }

  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }

  getInitialData() {
    return this.bkpIdentityData;
  }


}
