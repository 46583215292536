import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ClientManageDomainPageModule {


  public manageDomainData: any = null;

  setData(data) {
    if (data) {
      this.manageDomainData = data;
    } else {
      this.manageDomainData = null;
    }
  }

  getData() {
    return this.manageDomainData;
  }

}
