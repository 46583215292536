<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">{{data.title}}</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content unavailable-time-message">
      <div fxLayout="row wrap" fxLayoutAlign="center center"
      class="rb-padding-5x24" style="padding-top:24px;">
        <div fxFlex="0 0 calc(100% - 10px)">
          <p>{{data.message}}</p>
        </div>  
      </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
      <div class="rb-padding-10x0" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <button mat-raised-button 
          color="primary"
          (click)="close()"
          fxFlex="60" 
          class="rb-custom-button">
          Ok
        </button>
      </div>
  </div>
</div>
