<mat-toolbar class="mat-toolbar whiteBar mat-elevation-z3 rb-main-bar">
  <mat-toolbar-row>
    <button mat-icon-button class="logo-navbar bar-logo">
      <img src="{{imagesPath}}/logoMin.png" />
    </button>
    <div class="logo-title-divider">
      <span></span>
    </div>
    <div class="main-bar-description-title">
      Rubeus Agenda
    </div>
    <div fxFlex="">
    </div>
    <div fxLayout="row">
      <button mat-button class="rb-main-bar-button" routerLinkActive="selectedButton" color="accent"
        routerLink="{{option.link}}" *ngFor="let option of menuOptions">
        {{option.title}}
        <mat-icon class="rb-alert-icon-color alert-icon-tabs" aria-label="Atenção"
          *ngIf="requireLgpdSetting && option.id == 3">
          error
        </mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
