<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">Entrar</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <form (ngSubmit)="toAccess()" [formGroup]="loginForm">
    <mat-dialog-content class="rb-dialog-content">
        <div fxLayout="row wrap" fxLayoutAlign="center center"
        class="rb-padding-5x24" style="padding-top:24px;">
            <div *ngIf="data.typeError" fxFlex="0 0 calc(100% - 10px)">
                <p *ngIf="data.typeError==='duplicate_cpf'" ><b>Atenção:</b> este CPF já se encontra cadastrado no sistema. Por favor, efetue o login para unificar o cadastro.</p>
                <p *ngIf="data.typeError==='duplicate_email'"><b>Atenção:</b> este e-mail já se encontra cadastrado no sistema. Por favor, tente acessar sua conta antes de realizar o agendamento ou utilize outro e-mail válido.</p>
            </div>  
            <mat-form-field appearance="fill" [floatLabel]="'always'"
            fxFlex="0 0 calc(100% - 10px)">
                <mat-label>CPF ou e-mail</mat-label>
                <input matInput
                type="text"
                formControlName="login" 
                required 
                [(ngModel)]="loginData.login">
                <mat-error>Informe seu CPF ou e-mail</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" [floatLabel]="'always'"
            fxFlex="0 0 calc(100% - 10px)">
                <mat-label>Senha</mat-label>
                <input matInput
                type="password"
                formControlName="password" 
                required
                [(ngModel)]="loginData.password">
                <mat-error>Insira sua senha</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions class="rb-dialog-actions">
        <div class="rb-padding-10x0" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
          <button mat-raised-button 
            color="primary"
            type="submit" 
            fxFlex="60" 
            class="rb-custom-button">
            Entrar
          </button>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
          <a (click)="forgotPassword()">Esqueci minha senha</a>
        </div>
    </div>
  </form>
</div>
