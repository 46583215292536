import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrmClientLinkValidatorPageModule } from './crm-client-link-validator-page.module';
import { ClientSettingsModule } from '../../../../shared/models/client-settings/client-settings.module';
import {
  ClientManageDomainPageModule
} from '../../../../shared/components/client-manage-domain/client-manage-domain/client-manage-domain-page.module';

@Component({
  selector: 'app-crm-client-link-validator-page',
  templateUrl: './crm-client-link-validator-page.component.html',
  styleUrls: ['./crm-client-link-validator-page.component.scss']
})
export class CrmClientLinkValidatorPageComponent implements OnInit {

  public personalData: any = {};
  public clientSettings: any = {};
  public manageDomainData: any = {};
  public isVisible = false;

  constructor(
    private router: Router,
    private crmClientLinkValidatorPageModule: CrmClientLinkValidatorPageModule,
    private clientSettingsModule: ClientSettingsModule,
    public clientManageDomainPageModule: ClientManageDomainPageModule,
    public activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) {
  }


  ngOnInit() {
    this.personalData = this.crmClientLinkValidatorPageModule.getData();
    this.clientSettings = this.clientSettingsModule.getScheduleSettings();
    if (this.personalData && this.personalData.id) {
      if (this.clientSettings && this.clientSettings.type_schedule_id === 1 && !this.personalData.requireLgpdTerms) {
        this.goToStep2();
      } else {
        this.goToStep1();
      }
    } else {
      this.renderer.addClass(document.body, 'rbPage404');
      // this.renderer.addClass(document.getElementsByTagName('app-client-main-bar')[0], 'rbPage404');
      this.isVisible = true;
    }
  }

  goToStep1() {
    this.manageDomainData = this.clientManageDomainPageModule.getData();
    const uri = this.clientSettings && this.clientSettings.uri ?
    this.clientSettings.uri :
    (this.manageDomainData && this.manageDomainData.uri ? this.manageDomainData.uri : '');
    this.router.navigate([uri, 'agenda', 'etapa1']);
    let routeNavigate = uri.split('/');
    if (routeNavigate && routeNavigate.length) {
      routeNavigate.push('agenda');
      routeNavigate.push('etapa1');
    } else {
      routeNavigate =  ['agenda', 'etapa1'];
    }
    this.router.navigate(routeNavigate);
  }

  goToStep2() {
    this.manageDomainData = this.clientManageDomainPageModule.getData();
    const uri = this.clientSettings && this.clientSettings.uri ?
    this.clientSettings.uri :
    (this.manageDomainData && this.manageDomainData.uri ? this.manageDomainData.uri : '');
    this.router.navigate([uri, 'agenda', 'etapa2']);
    let routeNavigate = uri.split('/');
    if (routeNavigate && routeNavigate.length) {
      routeNavigate.push('agenda');
      routeNavigate.push('etapa2');
    } else {
      routeNavigate =  ['agenda', 'etapa2'];
    }
    this.router.navigate(routeNavigate);
  }

  // goToStep2() {
  //   console.log('==>> this.clientSettings', this.clientSettings);
  //   console.log('==>> this.clientSettings.uri', this.clientSettings.uri);
  //   // const uri = this.clientSettings && this.clientSettings.uri ? this.clientSettings.uri : '';
  //   this.router.navigate([uri, 'agenda', 'etapa2']);
  // }

}
