import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule, MatProgressBarModule} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatDividerModule} from '@angular/material/divider';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCheckboxModule} from '@angular/material/checkbox';


import { MatTableModule } from "@angular/material/table";
import { MatNativeDateModule, MatPaginatorIntl } from "@angular/material";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSliderModule } from "@angular/material/slider";


import { ColorPaletteGeneratorModule } from '../../../shared/components/color-palette-generator/color-palette-generator.module';
import { ColorPaletteGeneratorComponent} from '../../../shared/components/color-palette-generator/color-palette-generator.component';
import { ImageUploadModule } from '../../../shared/components/image-upload/image-upload.module';
import { ImageUploadComponent } from '../../../shared/components/image-upload/image-upload.component';
import { GeneralSettingsPageComponent } from './general-settings-page/general-settings-page.component';
import { SettingsIdentityComponent } from './general-settings-page/components/settings-identity/settings-identity.component';
import { SettingsServicesComponent } from './general-settings-page/components/settings-services/settings-services.component';
import { LgpdOptionsComponent } from './general-settings-page/components/lgpd-options/lgpd-options.component';
import { SettingsComponent } from './general-settings-page/components/settings/settings.component';
import { RbCustomTinymceComponent } from './general-settings-page/components/rb-custom-tinymce/rb-custom-tinymce.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LgpdSettingsDialogComponent } from './lgpd-settings-dialog/lgpd-settings-dialog.component';

@NgModule({
  declarations: [
    GeneralSettingsPageComponent,
    SettingsIdentityComponent,
    SettingsServicesComponent,
    LgpdOptionsComponent,
    SettingsComponent,
    RbCustomTinymceComponent,
    LgpdSettingsDialogComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCardModule,
    MatExpansionModule,
    MatTreeModule,
    MatDividerModule,
    DragDropModule,
    MatCheckboxModule,
    MatTableModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSliderModule,
    ColorPaletteGeneratorModule,
    ImageUploadModule,
    EditorModule,
  ],
  exports: [
    MatAutocompleteModule,
    MatChipsModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCardModule,
    MatExpansionModule,
    MatTreeModule,
    MatDividerModule,
    DragDropModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [ColorPaletteGeneratorComponent, ImageUploadComponent],
  entryComponents: [
    LgpdSettingsDialogComponent
  ]
})
export class GeneralSettingsModule { }
