<!-- <div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">{{mainTitleDialog}}</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <div class="rb-list-items-dialog-search" fxLayout="row">
      <div class="rb-list-items-search" fxLayout="row" fxFlex="100">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput fxFlex="100" type="text" placeholder="Pesquisar..." [(ngModel)]="searchText"
          (keyup)="searchUpdateCounter()">
        <button mat-icon-button matSuffix *ngIf="searchText.length" (click)="clearFilter()"
          aria-label="Limpar pesquisa">
          <mat-icon matSuffix>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="rb-list-items-dialog-container">
      <mat-selection-list #rbListItemAll *ngIf="rbListItem.options && rbListItem.options.length" color="primary">
        <mat-list-option [value]="checkAll" selected="{{checkAll.selected===true}}" (click)="selectAll(checkAll)"
          checkboxPosition="before">
          <h4 matLine class="rbListTitle">Todas</h4>
        </mat-list-option>
      </mat-selection-list>
      <mat-selection-list #rbListItem [disableRipple]="true" class="rbList" [(ngModel)]="selectedItems"
        (selectionChange)="onSelectedOptionsChange()" color="primary">
        <mat-list-option *ngFor="let item of itemsArray | filter : searchText" checkboxPosition="before"
          (click)="changeItemValue(item)" [value]="item" [selected]="item.selected"
          [ngClass]="{'rb-align-check': settings.subtitles}">
          <h4 matLine [ngClass]="{'rb-padding-bottom-10': settings.subtitles}" class="rbListTitle">{{item.name}}</h4>
          <p matLine class="rbListSubtitle" *ngFor="let subtitle of settings.subtitles">
            <span class="fontWeight500">{{subtitle.label}}:</span>
            <span>{{item[subtitle.key]}}</span>
          </p>
        </mat-list-option>
      </mat-selection-list>
      <div class="rb-no-results-message" *ngIf="!rbListItem.options || !rbListItem.options.length">
        <span>Nenhum resultado</span>
      </div>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="addItems()">
        <span>Adicionar</span>
      </button>
    </div>
  </div>
</div> -->

<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">{{mainTitleDialog}}</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <div class="rb-list-items-dialog-search" fxLayout="row">
      <div class="rb-list-items-search" fxLayout="row" fxFlex="100">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput fxFlex="100" type="text" placeholder="Pesquisar..." [(ngModel)]="searchText"
          (keyup)="searchUpdateCounter()">
        <button mat-icon-button matSuffix *ngIf="searchText.length" (click)="clearFilter()"
          aria-label="Limpar pesquisa">
          <mat-icon matSuffix>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="rb-list-items-dialog-container">
      <mat-selection-list #rbListItemAll *ngIf="itemsArray && itemsArray.length" color="primary">
        <mat-list-option [value]="checkAll" selected="{{checkAll.selected===true}}" (click)="selectAll(checkAll)"
          checkboxPosition="before">
          <h4 matLine class="rbListTitle">Todas</h4>
        </mat-list-option>
      </mat-selection-list>
      <mat-selection-list #rbListItem [disableRipple]="true" 
        class="rbList" [ngClass]="{rbListHeightAll: itemsArray && itemsArray.length}"
        [(ngModel)]="selectedItems"
        (selectionChange)="onSelectedOptionsChange()" color="primary">
        <cdk-virtual-scroll-viewport itemSize="50" class="rb-infinitListScroll-viewport"
        (scrolledIndexChange)="getSelectedItems()">
          <mat-list-option *cdkVirtualFor="let item of itemsArray | filter : searchText" checkboxPosition="before"
            (click)="changeItemValue(item)" [value]="item" [selected]="item.selected"
            [ngClass]="{'rb-align-check': settings.subtitles}">
            <h4 matLine [ngClass]="{'rb-padding-bottom-10': settings.subtitles}" class="rbListTitle">{{item.name}}</h4>
            <p matLine class="rbListSubtitle" *ngFor="let subtitle of settings.subtitles">
              <span class="fontWeight500">{{subtitle.label}}:</span>
              <span>{{item[subtitle.key]}}</span>
            </p>
          </mat-list-option>
        </cdk-virtual-scroll-viewport>
      </mat-selection-list>
      <div class="rb-no-results-message" *ngIf="!itemsArray || !itemsArray.length">
        <span>Nenhum resultado</span>
      </div>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="addItems()">
        <span>Adicionar</span>
      </button>
    </div>
  </div>
</div>