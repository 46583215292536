import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ServiceSegmentationsComponent } from './components/service-segmentations/service-segmentations.component';
import { ServiceSettingsComponent } from './components/service-settings/service-settings.component';
import { ServiceAttendanceIntervalComponent } from './components/service-attendance-interval/service-attendance-interval.component';
import { ServiceAttendantsComponent } from './components/service-attendants/service-attendants.component';
import { ServiceRegisterModule } from './service-register.module';
import { SaveServiceRegisterService } from './services/save-service-register.service';
import { ServicePublishService } from '../schedule-register-page/services/service-publish.service';

@Component({
  selector: 'app-service-register',
  templateUrl: './service-register.component.html',
  styleUrls: ['./service-register.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: false },
    },
  ],
})
export class ServiceRegisterComponent implements OnInit, AfterViewInit {
  public serviceData: any = {};
  public settingsFormGroup: FormGroup;
  @ViewChild('dialogStepper', { static: true }) dialogStepper: MatStepper;
  @ViewChild('serviceSettings', { static: false })
  serviceSettingsComponent: ServiceSettingsComponent;
  @ViewChild('serviceSegmentations', { static: false })
  serviceSegmentationsComponent: ServiceSegmentationsComponent;
  @ViewChild('serviceAttendanceInterval', { static: false })
  serviceAttendanceIntervalComponent: ServiceAttendanceIntervalComponent;
  @ViewChild('serviceAttendants', { static: false })
  serviceAttendantsComponent: ServiceAttendantsComponent;
  public hasDialogStepper: any = 0;
  public weekDaysCheckedCount: any = 0;
  public segmentationsCount: any = 0;
  public attendantCount: any = 0;
  public mainWeek: any = 0;
  public segmentation_order_by_title: any = 0;
  public isRequiredSegments: boolean;

  constructor(
    private dialogRef: MatDialogRef<ServiceRegisterComponent>,
    public serviceRegisterModule: ServiceRegisterModule,
    public saveServiceRegisterService: SaveServiceRegisterService,
    public servicePublishService: ServicePublishService
  ) {
    this.serviceRegisterModule.getRequiredSegments().subscribe(value => {
      this.isRequiredSegments = value;
    });
    this.settingsFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      durationTime: new FormControl('', Validators.required),
      advanceTime: new FormControl('', Validators.required),
      limitMonths: new FormControl('', [Validators.required, Validators.min(0)]),
      limitDays: new FormControl('', [Validators.required, Validators.min(0)]),
      cancellationTime: new FormControl('', Validators.required),
      amount: new FormControl(1, [Validators.required, Validators.min(1)]),
      allow_segmentation_link_with_student: new FormControl(0),
    });
  }

  ngOnInit() {
    this.serviceData = this.serviceRegisterModule.getData();
  }

  ngAfterViewInit() {
    this.hasDialogStepper = 1;
  }

  backStage() {
    this.dialogStepper.previous();
  }

  nextStage() {
    this.dialogStepper.next();
  }

  saveService() {
    const dataSave = this.buildDataToSave();
    this.saveServiceRegisterService.resolve(dataSave).then((response) => {
      if (response && response.success) {
        if (
          !this.serviceData.id ||
          (this.serviceData.id && this.serviceData.active_service)
        ) {
          this.publishService(response.id);
        } else {
          this.dialogRef.close(true);
        }
      }
    });
  }

  closeNoticeSettings(){
    if(this.serviceSettingsComponent.notice != undefined && this.serviceSettingsComponent != undefined){
      this.serviceSettingsComponent.notice.close()
    }
  }

  buildDataToSave() {
    const response = this.serviceSettingsComponent.getData();
    response.group_id = this.serviceData.group_id;
    response.segmentation = this.serviceSegmentationsComponent.getSelectedOptions();
    response.week = this.serviceAttendanceIntervalComponent.getDays();
    response.attendants = this.serviceAttendantsComponent.getSelectedOptions();
    response.segmentation_order_by_title = this.segmentation_order_by_title ? 1 : 0;
    return response;
  }

  publishService(id) {
    const data: any = {};
    data.id = id;
    data.active_service = 1;
    this.servicePublishService.resolve(data).then((response) => {
      if (response && response.success) {
        this.dialogRef.close(true);
      }
    });
  }
}
