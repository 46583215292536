import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { HttpService } from '../../../../../core/http/http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { ClientLoaderService } from '../../../../../shared/components/client-loader/client-loader.service';
import { MySchedulesPageModule } from '../my-schedules-page.module';

@Injectable({
  providedIn: 'root'
})
export class GetMySchedulesListDataService extends Request implements Resolve<GetMySchedulesListDataService> {

  constructor(
    private mySchedulesPageModule: MySchedulesPageModule,
    private http: HttpService,
    private loaderService: ClientLoaderService,
    protected notice: NoticeComponent,
  ) {
    super();
  }

  private param = '';
  private response: any;

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);

    this.url = 'scheduling';

    return this.http.get(this)
      .then((data) => this.response);
  }

  successAction(data) {
    this.response = data;
    if (data && data.success) {
      this.mySchedulesPageModule.setData(data.scheduling);
    } else {
      this.mySchedulesPageModule.setData(null);
    }
    this.loaderService.setChangesLoaderState(false);
  }


  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
