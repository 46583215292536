import { Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { LoadingComponent } from '../../../view/component/loading/loading.component';
import { ForgotService } from './services/forgot.service';
// import { environment } from '../../../../environments/environment';
// import { ShareDataService } from 'src/app/services/share-data.service';

@Component({
    selector: 'app-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss']
  })
  export class ForgotPasswordDialogComponent implements OnInit {
    public showFeedback = false;
    public forgotForm: FormGroup;
    public forgotData: any = {
      email: '' ,
      // link: environment.link + 'recuperar-senha',
      link: 'http://127.0.0.1:4200/' + 'recuperar-senha', // apagar
    };
    public emailPattern = '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+[ ]*$';


    constructor(
        private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private loginDialog: MatDialog,
        private router: Router,
        public forgotService: ForgotService,
        // private shareDataService: ShareDataService,
        // @Inject(MAT_DIALOG_DATA) public parentScope: LoadingComponent
      ) {
        this.forgotForm = new FormGroup({
          'email': new FormControl(this.forgotData.login, [
            Validators.required
          ])
        });
      }

    ngOnInit() {
    }

    close() {
      this.dialogRef.close();
    }

    sendForgot() {
      this.forgotService.resolve(this.forgotData).then((response) => {
          if (response && response.success) {
            this.showFeedback = true;
          }
      });
    }
  }
