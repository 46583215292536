import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { Request } from '../../../core/http/request';
import { HttpService } from '../../../core/http/http.service';
import { NoticeComponent } from '../../components/notice/notice.component';
import { ClientLoaderService } from '../../components/client-loader/client-loader.service';
import { ClientUserModule } from './client-user.module';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LgpdTermsDialogComponent } from '../../components/lgpd-terms-dialog/lgpd-terms-dialog.component';
import { FlowSchedulingModule } from 'src/app/modules/client/flow-scheduling/flow-scheduling.module';
import { FlowSchedulingPageModule } from 'src/app/modules/client/flow-scheduling/flow-scheduling-page/flow-scheduling-page.module';

@Injectable({
  providedIn: 'root'
})
export class ClientLoginService extends Request implements Resolve<ClientLoginService> {

  private response: any;
  public loginParams: any;

  constructor(
    private clientUserModule: ClientUserModule,
    private http: HttpService,
    private clientLoaderService: ClientLoaderService,
    protected notice: NoticeComponent,
    private platformLocation: PlatformLocation,
    private lgpdTermsDialog: MatDialog,
  ) {
    super();
  }

  // get() {
  //   return this.user;
  // }


  resolve(
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.url = 'user/login';
    this.data = parameters;
    this.loginParams = parameters;
    // if (this.data && typeof this.data === 'object') {
    //   this.data.url = this.platformLocation.href;
    // }

    return this.http.post(this)
      .then((data) => {
        return this.response;
      });
  }


  successAction(data) {
    this.response = data;
    if (data && data.success) {
      if (data.requestLgpdTerms) {
        this.openLgpdTermsDialog(data.personId);
      } else {
        this.clientUserModule.setData(data.user);
        this.notice.showMessageSuccess('Usuário autenticado com sucesso!');
      }
    } else {
      this.clientUserModule.setData(null);
      if (data.error) {
        this.notice.showMessageError(data.error);
      } else {
        this.notice.showMessageError('Não foi possível efetuar o login.');
      }
    }
    this.clientLoaderService.setChangesLoaderState(false);
    return this.clientUserModule.getData();
  }


  errorAction(error) {
    this.clientLoaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }

  openLgpdTermsDialog(personId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.width = '518px';
    dialogConfig.height = '418px';
    dialogConfig.panelClass = 'rb-dialog-container';
    dialogConfig.disableClose = true;
    const data = {
      loginParams: this.loginParams,
      personId: personId
    }
    dialogConfig.data = data;
    const lgpdTermsDialog = this.lgpdTermsDialog.open(LgpdTermsDialogComponent, dialogConfig);
  }


}
