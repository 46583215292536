import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {ImageUploadService} from './services/image-upload.service';
import { Observable, Subject } from 'rxjs';
import { NoticeComponent } from '../notice/notice.component';


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @ViewChild('rbFileDropComponent', {static: false}) rbFileDropComponent: ElementRef;

  public imagesPath = environment.imagesPath;

  @Input() mainTitle: any;
  @Input() maxWidth: any;
  @Input() maxHeight: any;
  @Input() maxSize: any;
  @Input() fileFolder: any;
  @Input() parentObject: any;
  @Input() rbFileDropModel: any;

  public files: FileSystemFileEntry[] = [];
  public isLoading: any;
  public hasChange = new Subject<any>();

  public uploadData = {
    image: '',
  };

  private backupImage: any;

  constructor(
    private imageUploadService: ImageUploadService,
    private notice: NoticeComponent
  ) {
  }

  ngOnInit() {
    this.uploadData.image = this.parentObject[this.rbFileDropModel];
    this.backupImage = this.uploadData.image;
  }

  removeImage(e) {
    this.parentObject[this.rbFileDropModel] = null;
    this.uploadData.image = null;
    this.setHasChange(1);
  }

  onFileDropped(files: NgxFileDropEntry[]) {
    this.isLoading = true;
    if (files && files.length === 1) {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile && this.validateImageFile(droppedFile.fileEntry)) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              this.sendImageToServer(file);
            });
        } else {
          this.isLoading = false;
          // this.notice.showMessageWarning(`Este não é um arquivo válido`);
          this.notice.showMessageWarning(`O arquivo selecionado não contém uma extensão válida para este upload.
          Formatos aceitos: PNG, JPG ou JPEG.`);
        }
      }
    } else {
      this.isLoading = false;
      this.notice.showMessageWarning(`Você deve selecionar apenas um arquivo de imagem.`);
    }
  }

  validateImageFile(file) {
    if (file.size <= 1000000 || file.isFile) {
      if (file && file.name) {
        const arrayName = file.name.split('.');
        const extension = arrayName[arrayName.length - 1];
        if (
          // extension.toLocaleLowerCase() === 'ICO'.toLocaleLowerCase() ||
          extension.toLocaleLowerCase() === 'PNG'.toLocaleLowerCase() ||
          extension.toLocaleLowerCase() === 'JPG'.toLocaleLowerCase() ||
          extension.toLocaleLowerCase() === 'JPEG'.toLocaleLowerCase()
          // extension.toLocaleLowerCase() === 'SVG'.toLocaleLowerCase() ||
          // extension.toLocaleLowerCase() === 'GIF'.toLocaleLowerCase()
          ) {
            return true;
          } else {
            // this.notice.showMessageWarning(`Este não é um arquivo de imagem válido`);
            this.notice.showMessageWarning(`O arquivo selecionado não contém uma extensão válida para este upload.
            Formatos aceitos: PNG, JPG ou JPEG.`);
            return false;
          }
      } else {
        // this.notice.showMessageWarning(`Este não é um arquivo de imagem válido`);
        this.notice.showMessageWarning(`O arquivo selecionado não contém uma extensão válida para este upload.
        Formatos aceitos: PNG, JPG ou JPEG.`);
        return false;
      }
    } else {
      this.notice.showMessageWarning(`O arquivo é muito grande, insira uma imagem de até 1 MB`);
      return false;
    }
  }

  sendImageToServer(file) {
    const dataToSubmit = {
      image: file,
    };

    this.imageUploadService.resolve(dataToSubmit, this.fileFolder).then((response) => {
      if (response && response.success) {
        this.updateImage(response.filePath);
      }
      this.isLoading = false;
    });
  }

  updateImage(filePath) {
      this.parentObject[this.rbFileDropModel] = filePath;
      this.uploadData.image = filePath;
      this.setHasChange(1);
  }

  public refreshState() {
    this.uploadData.image = JSON.parse(JSON.stringify(this.backupImage));
    this.setHasChange(0);
  }


  setHasChange(value: any) {
    this.hasChange.next(value);
  }

  getHasChange(): Observable<any> {
    return this.hasChange.asObservable();
  }

}
