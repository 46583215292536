import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {WeekDaysTabsComponent} from '../week-days-tabs/week-days-tabs.component';

@Component({
  selector: 'app-attendance-hours-dialog',
  templateUrl: './attendance-hours-dialog.component.html',
  styleUrls: ['./attendance-hours-dialog.component.scss']
})
export class AttendanceHoursDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('attendaceIntervalTabs', {static: false}) attendaceIntervalTabsElement: WeekDaysTabsComponent;

public mainTitleDialog: any = '';
public weekDaysCheckedCount: any = 0;
public settings: any = {
  subtitles: []
};

public attendanceData: any = {};
public hasChange: any = 0;


constructor(
  private dialogRef: MatDialogRef<AttendanceHoursDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public dataDialog: any,
) {

}

ngOnInit() {
  this.settings = this.dataDialog.settings;
  this.mainTitleDialog = this.dataDialog.mainTitleDialog;
  this.attendanceData = this.dataDialog.selectedAttendant;
}

ngAfterViewInit() {
  this.attendaceIntervalTabsElement.getHasChangeValue().subscribe(value => {
    this.hasChange = value;
  });
}

saveChanges() {
  const response = {individualHourDefined: true, week: [], custom_time: 1};
  // const response = {week: [], custom_time: 1};
  response.week = this.attendaceIntervalTabsElement.getDaysOfWeekOptions();
  this.dialogRef.close(response);
}


}
