import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class AttendantsServicesModule {
  public attendantsOptions: any = [];

  setOptions(data) {
    if (data) {
      this.attendantsOptions = data;
    }
  }

  getOptions() {
    return this.attendantsOptions;
  }


}
