<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
      <h2 fxFlex="">{{ serviceData.id ? "Editar" : "Adicionar" }} serviço</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="true" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <mat-horizontal-stepper class="RbHorizontalStepper" linear="false" #dialogStepper>
      <mat-step state="{{ settingsFormGroup.valid ? 'done' : 'number' }}" [completed]="settingsFormGroup.valid"
        [editable]="true">
        <form class="rb-padding-20x0" [formGroup]="settingsFormGroup">
          <ng-template matStepLabel>Configurações gerais</ng-template>
          <app-service-settings #serviceSettings [parentForm]="settingsFormGroup" [parentComponent]="dialogStepper">
          </app-service-settings>
        </form>
      </mat-step>
      <mat-step class="rb-padding-20x0" #segmentationStep state="{{
          (segmentationStep.interacted && !isRequiredSegments) || segmentationsCount >= 1
            ? 'done'
            : 'number'
        }}" [completed]="segmentationsCount >= 1" [editable]="true">
        <ng-template matStepLabel>
          <div style="display: flex !important; align-items: center !important;">
            Segmentações
            <mat-icon class="rb-alert-icon-color alert-icon-tabs" aria-label="Atenção"
            style="margin-left: 3px;"
            *ngIf="isRequiredSegments && !segmentationsCount">
              error
            </mat-icon>
          </div>
        </ng-template>
        <app-service-segmentations #serviceSegmentations [parentComponent]="dialogStepper" [parentScope]="this">
        </app-service-segmentations>
      </mat-step>
      <mat-step class="rb-padding-20x0" state="{{ weekDaysCheckedCount >= 1 ? 'done' : 'number' }}"
        [completed]="weekDaysCheckedCount >= 1" [editable]="true">
        <ng-template matStepLabel>Intervalos de atendimento</ng-template>
        <app-service-attendance-interval #serviceAttendanceInterval [parentComponent]="dialogStepper"
          [parentScope]="this"></app-service-attendance-interval>
      </mat-step>
      <mat-step class="rb-padding-20x0" state="{{ attendantCount >= 1 ? 'done' : 'number' }}"
        [completed]="attendantCount >= 1" [editable]="true">
        <ng-template matStepLabel>Atendentes</ng-template>
        <app-service-attendants #serviceAttendants [parentComponent]="dialogStepper" [parentScope]="this">
        </app-service-attendants>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions" *ngIf="hasDialogStepper === 1" [style.visibility]="
      (dialogStepper.selectedIndex == 1 && segmentationsCount < 1) ||
      (dialogStepper.selectedIndex == 3 && attendantCount < 1)
        ? 'hidden'
        : 'visible'
    ">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <button mat-stroked-button *ngIf="dialogStepper.selectedIndex == 0" [mat-dialog-close]="true" color="primary"
        class="rb-btn-upload">
        <span>Cancelar</span>
      </button>
      <button mat-stroked-button *ngIf="dialogStepper.selectedIndex > 0" color="primary" class="rb-btn-upload"
        (click)="backStage()">
        <span>Voltar</span>
      </button>
      <div class="rbButtonsDivisor"></div>
      <button mat-raised-button *ngIf="dialogStepper.selectedIndex < dialogStepper.steps.length - 1" [disabled]="
          (dialogStepper.selectedIndex === 0 && settingsFormGroup.invalid) ||
          (dialogStepper.selectedIndex === 2 && weekDaysCheckedCount < 1)
        " color="primary" (click)="nextStage()">
        <span>Próximo</span>
      </button>
      <button
        mat-raised-button
        *ngIf="dialogStepper.selectedIndex == dialogStepper.steps.length - 1"
        [disabled]="settingsFormGroup.invalid
        || weekDaysCheckedCount < 1
        || attendantCount < 1
        || (isRequiredSegments && segmentationsCount<1)"
        color="primary"
        (click)="saveService()">
        <span *ngIf="!serviceData.id || (serviceData.id && serviceData.active_service)">Publicar</span>
        <span *ngIf="serviceData.id && !serviceData.active_service">Concluir</span>
      </button>
    </div>
  </div>
</div>
