import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { AdminHttpService } from '../../../../../core/http/admin-http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { LoaderService } from '../../../../../shared/components/loader/loader.service';
import { ScheduleServicesModule } from '../../schedule-register-page/components/schedule-services/schedule-services.module';
import {ScheduleRegisterPageModule} from '../../schedule-register-page/schedule-register-page.module';

@Injectable({
  providedIn: 'root'
})
export class SaveServiceRegisterService extends Request implements Resolve<SaveServiceRegisterService> {

  private response: any;
  constructor(
    private http: AdminHttpService,
    protected notice: NoticeComponent,
    private loaderService: LoaderService,
    private scheduleServicesModule: ScheduleServicesModule,
    public scheduleRegisterPageModule: ScheduleRegisterPageModule,
  ) {
    super();
  }

  resolve(
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    this.url = 'schedule/create-service/';
    this.data = parameters;

    return this.http.post(this)
      // .toPromise()
      .then((data) => {
        return this.response;
      });
  }

  successAction(data) {
    super.successAction(data);
    this.response = data;
    if (data.success) {
      if (data.token) {
        localStorage.setItem('token', data.token);
      }
      this.scheduleServicesModule.setData(data.schedules);
      this.scheduleRegisterPageModule.setUpdated(data.updated_at);
      this.notice.showMessageSuccess('Serviço salvo.');
    } else {
      if (data.error) {
        this.notice.showMessageError(data.error);
      } else if (data.erro) {
        this.notice.showMessageError(data.erro);
      } else {
        this.notice.showMessageError(data.errors);
      }
    }
    this.loaderService.setChangesLoaderState(false);
  }

  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
