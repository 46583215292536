<form class="rb-default-dialog" [formGroup]="headerFormGroup">
    <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">Editar descrição</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
        <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
        </button>
    </div>
    </div>
    <mat-dialog-content class="rb-dialog-content rb-has-overflow">
        <div class="rb-padding-14x24" fxLayout="column">
          <div fxLayout="row" fxFlex="100%">
            <mat-form-field fxFlex="100%" appearance="fill">
              <mat-label class="rb-label-black">Etapa</mat-label>
              <input matInput
              required
              [disabled]
              [formControlName]="'stage'"
              [(ngModel)]="stage"
              type="text">
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="100%">
            <mat-form-field appearance="fill" fxFlex="100%">
              <mat-label class="rb-label-black">Descrição</mat-label>
              <textarea matInput
              (change)="textareaChange(description)"
              required
              type="text"
              [formControlName]="'description'"
              [(ngModel)]="description"
              maxlength="200">
              </textarea>
              <mat-hint align="end">
                {{description && description.length ? description.length : 0}} / 200
              </mat-hint>
              <mat-error>Informe um título para o campo</mat-error>
            </mat-form-field>
            </div>
        </div>
      </mat-dialog-content>
      <div mat-dialog-actions class="rb-dialog-actions">
        <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
          <button mat-stroked-button
            [mat-dialog-close]="false"
            color="primary"
            class="rb-btn-upload">
            <span>Cancelar</span>
          </button>
          <div class='rbButtonsDivisor'></div>
          <button mat-raised-button
            (click)="saveTitleStage()"
            color="primary">
            <span>Salvar</span>
          </button>
        </div>
      </div>
</form>