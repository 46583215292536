import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ClientLoginDialogComponent } from '../client-login-dialog/client-login-dialog.component';
import { ClientLogoutService } from '../../models/client-user/client-logout.service';
import { ClientUserModule } from '../../models/client-user/client-user.module';
import { ClientSettingsModule } from '../../models/client-settings/client-settings.module';
import {
  CrmClientLinkValidatorPageModule
} from '../../../modules/client/crm-client-link-validator/crm-client-link-validator-page/crm-client-link-validator-page.module';

declare const RBTracking: any;

@Component({
  selector: 'app-client-main-bar',
  templateUrl: './client-main-bar.component.html',
  styleUrls: ['./client-main-bar.component.scss']
})
export class ClientMainBarComponent implements OnInit {

  private parent: any;
  public currentPage: any;
  // public validatedByGeneratedLink: any = false;
  public userData: any = {};
  public crmPersonData: any;
  public scheduleSettings: any = {};
  public uri: any = '';

  constructor(
    private router: Router,
    private loginDialog: MatDialog,
    private clientLogoutService: ClientLogoutService,
    private clientSettingsModule: ClientSettingsModule,
    public clientUserModule: ClientUserModule,
    public crmClientLinkValidatorPageModule: CrmClientLinkValidatorPageModule,
  ) {
  }

  setParent(parentObject) {
    this.parent = parentObject;
  }

  ngOnInit() {
    this.userData = this.clientUserModule.getData();
    this.crmPersonData = this.crmClientLinkValidatorPageModule.getData();

    if (this.crmPersonData && this.crmPersonData.responsavel && this.crmPersonData.responsavel.name) {
      this.crmPersonData.nomeResponsavel = this.crmPersonData.responsavel.name;
    }else {
      this.crmPersonData.nomeResponsavel = this.crmPersonData.name;
    }


    // this.validatedByGeneratedLink = this.clientSettingsModule.getValidatedByGeneratedLink();
    this.scheduleSettings = this.clientSettingsModule.getScheduleSettings();
    this.uri = this.scheduleSettings && this.scheduleSettings.uri ? this.scheduleSettings.uri : '';
    this.getCurrentPage();
  }

  getCurrentPage() {
    this.currentPage = this.router.url ? this.router.url.replace('/' + this.uri, '') : '';
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentPage = this.router.url ? this.router.url.replace('/' + this.uri, '') : '';
        }
    });
  }

  openLoginDialog(menu) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.minWidth = '40%';
    dialogConfig.maxWidth = '500px';
    dialogConfig.minHeight = '375px';
    dialogConfig.panelClass = ['rb-dialog-container', 'rb-login-dialog'];
    dialogConfig.data = {};
    this.loginDialog.open(ClientLoginDialogComponent, dialogConfig);
  }

  logout() {
    this.clientLogoutService.resolve({}).then((response) => {
      if (response && response.success) {
        if (typeof RBTracking === 'object' && typeof RBTracking.clearSession === 'function') {
          sessionStorage.removeItem('rb_tracking_session_id');
          RBTracking.clearSession();
        }

        this.goToDomainvalidation();
      }
    });
  }

  goToStep1() {
    this.router.navigate([this.uri, 'agenda', 'etapa1']);
  }

  goToMySchedules() {
    this.router.navigate([this.uri, 'agenda', 'meus-agendamentos']);
  }

  goToPersonalData() {
    this.router.navigate([this.uri, 'agenda', 'dados-pessoais']);
  }

  goToChangePass() {
    this.router.navigate([this.uri, 'agenda', 'alterar-senha']);
  }

  goToDomainvalidation() {
    this.router.navigate([this.uri, 'validar-dominio']);
  }

}
