import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})

export class CrmClientLinkValidatorPageModule {

  public personalDataUser: any = {};
  public jwtHelperService = new JwtHelperService();

  setData(data) {
    if (data) {
      this.personalDataUser = data;
    } else {
      this.personalDataUser = {};
    }
  }

  getData() {
    return (this.personalDataUser && this.personalDataUser.id ? this.personalDataUser : this.decodeToken());
  }


  decodeToken() {
    const tokenData = sessionStorage.getItem('schedulingToken');
    if (tokenData) {
      const decode = this.jwtHelperService.decodeToken(tokenData);
      if (decode && decode.dados) {
        return decode.dados;
      }
    }
    return {};
  }

}
