import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Request } from './request';
import { environment } from '../../../environments/environment';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AdminHttpService {

  private readonly API = `${environment.API}`;

  constructor(
    private httpClient: HttpClient,
    private platformLocation: PlatformLocation,
  ) { }

  get(request: Request) {
    const authorization = localStorage.getItem('rbScheduleAdmToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: authorization ? 'Bearer ' + authorization : '',
      })
    };

    /* Token admin */
    const schedulingAdmToken = localStorage.getItem('schedulingAdmToken');
    const schedulingUrlAdm = sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href;

    let paramns = '';
    // paramns = '?tokenAdmin=' + schedulingAdmToken;

    if (schedulingAdmToken && schedulingAdmToken !== 'undefined') {
        paramns = '?tokenAdmin=' + schedulingAdmToken;
    }
    if (schedulingUrlAdm && schedulingUrlAdm !== 'undefined') {
      paramns += (paramns && paramns.length ? '&' : '?') + 'urlAdmin=' + schedulingUrlAdm;
    }
    /* ---- */

    const promise = this.httpClient.get(this.API + request.url + paramns, options).toPromise().then(
      res => {
        request.successAction(res);
      },
      msg => {
        request.errorAction(msg)
        setTimeout(() => {
          if (msg.error.status === 'fail') {
            document.location.href = localStorage.getItem('redirectUrl')
          };
        }, 3000);
      });
    return promise;
  }

  post(request: Request) {
    const authorization = localStorage.getItem('rbScheduleAdmToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: authorization ? 'Bearer ' + authorization : '',
      })
    };

    /* Token admin */
    const schedulingAdmToken = localStorage.getItem('schedulingAdmToken');
    if (request.data && typeof request.data === 'object') {
      request.data.tokenAdmin = schedulingAdmToken;
      request.data.urlAdmin = sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href;
    } else {
      request.data = {
        tokenAdmin: schedulingAdmToken,
        urlAdmin: sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href,
      };
    }
    /* ---- */

    const promise = this.httpClient.post( this.API + request.url, request.data, options).toPromise().then(
      res => {
        request.successAction(res);
      },
      msg => {
        request.errorAction(msg)
        setTimeout(() => {
          if (msg.error.status === 'fail') {
            document.location.href = localStorage.getItem('redirectUrl')
          };
        }, 3000);
      });
    return promise;
  }

  del(request: Request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('rbScheduleAdmToken'),
      })
    };


    /* Token admin */
    const schedulingAdmToken = localStorage.getItem('schedulingAdmToken');
    const schedulingUrlAdm = sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href;
    let paramns = '';
    // paramns = '?tokenAdmin=' + schedulingAdmToken;

    if (schedulingAdmToken && schedulingAdmToken !== 'undefined') {
        paramns = '?tokenAdmin=' + schedulingAdmToken;
    }
    if (schedulingUrlAdm && schedulingUrlAdm !== 'undefined') {
      paramns += (paramns && paramns.length ? '&' : '?') + 'urlAdmin=' + schedulingUrlAdm;
    }
    /* ---- */

    const promise = this.httpClient.delete( this.API + request.url + '/' + request.data.id + paramns, options).toPromise().then(
      res => request.successAction(res),
      msg => {
        request.errorAction(msg)
        setTimeout(() => {
          if (msg.error.status === 'fail') {
            document.location.href = localStorage.getItem('redirectUrl')
          };
        }, 3000);
      });
    return promise;
  }

  put(request: Request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('rbScheduleAdmToken'),
      })
    };

    /* Token admin */
    const schedulingAdmToken = localStorage.getItem('schedulingAdmToken');
    if (request.data && typeof request.data === 'object') {
      request.data.tokenAdmin = schedulingAdmToken;
      request.data.urlAdmin = sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href;
    } else {
      request.data = {
        tokenAdmin: schedulingAdmToken,
        urlAdmin: sessionStorage.getItem('schedulingUrlAdm') ? sessionStorage.getItem('schedulingUrlAdm') : this.platformLocation.href,
      };
    }
    /* ---- */

    if (request.data && typeof request.data === 'object') {
      request.data.url = this.platformLocation.href;
    } else {
      request.data = {
        url: this.platformLocation.href
      };
    }

    const promise = this.httpClient.put( this.API + request.url, request.data, options).toPromise().then(
      res => request.successAction(res),
      msg => {
        request.errorAction(msg)
        setTimeout(() => {
          if (msg.error.status === 'fail') {
            document.location.href = localStorage.getItem('redirectUrl')
          };
        }, 3000);
      });
    return promise;
  }

  createParams(data: {}) {
    let httpParams = new HttpParams();
    for ( const key in data) {
      if ( typeof data[key] === 'object') {
        for (const obj of data[key]) {
          httpParams = httpParams.append(key + '[]', obj);
        }
      } else {
        httpParams = httpParams.set(key, data[key]);
      }
    }
    return httpParams;
  }


  postFile(request: Request) {

    const promise = this.httpClient.post(this.API + request.url, this.createParamsPostFile(request.data))
    .toPromise().then(
      res => request.successAction(res),
      msg => {
        request.errorAction(msg)
        setTimeout(() => {
          if (msg.error.status === 'fail') {
            document.location.href = localStorage.getItem('redirectUrl')
          };
        }, 3000);
      });
    return promise;
  }

  createParamsPostFile(data: {}) {
    const httpParams = new FormData();

    for ( const key in data) {
      if (data.hasOwnProperty(key)) {
        httpParams.append(key, data[key]);
      }
    }
    return httpParams;
  }
}

