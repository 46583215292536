import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import { NgxFileDropModule } from 'ngx-file-drop';
import {ImageUploadComponent} from './image-upload.component';

@NgModule({
  declarations: [
    ImageUploadComponent,
  ],
  imports: [
    CommonModule,
    NgxFileDropModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  exports: [
    ImageUploadComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ImageUploadModule { }