import { NgModule, Injectable } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Observable, Subject } from "rxjs";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ]
})

@Injectable({
  providedIn: 'root'
})

export class LgpdOptionsModule{

  public LgpdOptions: any[] = [];
  public OptionsLgpdDefaultData: any[] = [];
  public subscriptions: any[] = [];
  public legalBases: any[] = [];
  public controlLgpdSelected = new Subject<any>();
  public controlChangeSettings = new Subject<any>();
  public lgpdGeneralData: any = {};
  public lgpdSettingsData: {};

  setInitialData(data) {   
    if (data && data.editorOpcoesLgpd) {
      this.lgpdSettingsData = data.editorOpcoesLgpd;
    } else {
      this.lgpdSettingsData = {};
    }

    if (data && data.opcoesLgpdDadosPadroes) {
      this.OptionsLgpdDefaultData = data.opcoesLgpdDadosPadroes;
    } else {
      this.OptionsLgpdDefaultData = [];
    }

  }

  setLgpdOptions(data) {
    if (data && data.length) {
      this.LgpdOptions = data;
    }else{
      this.LgpdOptions = new Array();
    }
  }

  getLgpdOptions() {
    return this.LgpdOptions;
  }

  getOptionsLgpdDefaultData() {
    return this.OptionsLgpdDefaultData;
  }

  setSubscriptions(data) {
    if (data && data.length) {
      this.subscriptions = data;
    }else{
      this.subscriptions = new Array();
    }
  }

  getSubscriptions() {
    return this.subscriptions;
  }

  getLegalBases() {
    return this.legalBases;
  }

  setLegalBases(data) {
    if(data && data.length) {
      this.legalBases = data;
    } else {
      this.legalBases = new Array();
    }
  }

  setLgpdSelected(value: any) {
    this.controlLgpdSelected.next(value);
  }

  getLgpdSelected(): Observable<any> {
    return this.controlLgpdSelected.asObservable();
  }

  setControlChangeSettings(value: any) {
    this.controlChangeSettings.next(value);
  }

  getControlChangeSettings(): Observable<any> {
    return this.controlChangeSettings.asObservable();
  }

  setLgpdGeneralData(data) {
    if (data) {
      this.lgpdGeneralData = data;
    } else {
      this.lgpdGeneralData = {};
    }
  }

  getLgpdGeneralData() {
    return this.lgpdGeneralData;
  }

  getLgpdSettingsTabData() {
    return this.lgpdSettingsData;
  }

  getVerificationBoxId() {
    return this.lgpdSettingsData && this.lgpdSettingsData['idCaixaVerificacao'] ? this.lgpdSettingsData['idCaixaVerificacao'] : 0
  }

}
