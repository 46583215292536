<mat-tab-group mat-stretch-tabs disableRipple
class="rb-week-days-tabs rb-padding-top-24" [formGroup]="hourIntervalForm">
  <mat-tab formArrayName="daysForm"
  *ngFor="let dayForm of hourIntervalForm.get('daysForm')['controls']; let i = index;">
    <ng-template mat-tab-label class="rb-week-days-tabs-header">
      <div [formGroupName]="i">
        <mat-slide-toggle
          [formControlName]="'active_day'"
          class="example-margin"
          color="primary"
          (change)="setIntervalsCount(); setHasChangeValue(1)"
          [(ngModel)]="daysOfWeekOptions[i].active_day"
          [disabled]="!daysOfWeekOptions[i].hours.length">
        </mat-slide-toggle>
      </div>
      <div class="rbButtonsDivisor"></div>
      {{daysOfWeekOptions[i].title}}
    </ng-template>
    <div class="rb-week-days-tabs-content" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="100" fxFlex.sm="75" fxFlex.gt-sm="50">
        <div class="rb-no-results-message" *ngIf="!daysOfWeekOptions[i].hours.length">
          <span>Adicione ao menos um intervalo de atendimento para poder ativar esse dia da semana.</span>
        </div>
        <div [formGroupName]="i">
          <div class="rb-padding-0x5" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(33% - 10px)" 
            fxFlex.xs="0 0 calc(50% - 10px)">
              <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
                <mat-label class="rb-label-black">Horário inicial</mat-label>
                <input matInput #initialHourForm
                mask="00:00"
                placeholder="hh:mm"
                [formControlName]="'start_time'"
                type="text"
                (change)="addIntervalValidation(dayForm)"
                (keyup)="submitEvent($event, addIntervalButton, initialHourForm)"
                [(ngModel)]="daysOfWeekOptions[i].hourInterval.start_time">
                <mat-icon matSuffix class="material-icons-outlined">watch_later</mat-icon>
                <mat-error>
                  <span *ngIf="dayForm.controls['start_time'].errors &&
                  dayForm.controls['start_time'].errors.notBefore">
                    Valor maior que horário final 
                  </span>
                  <span *ngIf="dayForm.controls['start_time'].errors &&
                  !dayForm.controls['start_time'].errors.notBefore">
                    Informe um horário válido
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="rb-padding-10x0" fxLayout="row" fxFlex="0 0 calc(33% - 10px)" 
            fxFlex.xs="0 0 calc(50% - 10px)">
              <mat-form-field appearance="fill" [floatLabel]="'always'" fxFlex="">
                <mat-label class="rb-label-black">Horário final</mat-label>
                <input matInput
                mask="00:00"
                placeholder="hh:mm"
                [formControlName]="'end_time'"
                (change)="addIntervalValidation(dayForm)"
                (keyup)="submitEvent($event, addIntervalButton, initialHourForm)"
                type="text"
                [(ngModel)]="daysOfWeekOptions[i].hourInterval.end_time">
                <mat-icon matSuffix class="material-icons-outlined">watch_later</mat-icon>
                <mat-error>
                  <span *ngIf="dayForm.controls['end_time'].errors &&
                  dayForm.controls['end_time'].errors.notAfter">
                    Valor menor que horário inicial 
                  </span>
                  <span *ngIf="dayForm.controls['end_time'].errors &&
                  !dayForm.controls['end_time'].errors.notAfter">
                    Informe um horário válido
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="rb-padding-10x0 rb-week-days-tabs-add-button-container" fxLayout="row" fxFlex="100% 100% calc(34% - 10px)"
            fxFlex.xs="0 0 calc(100% - 10px)" fxLayoutAlign.xs="end center">
              <button mat-raised-button #addIntervalButton
                [disabled]="dayForm.invalid || !daysOfWeekOptions[i].hourInterval.start_time || !daysOfWeekOptions[i].hourInterval.end_time || daysOfWeekOptions[i].existingInterval==1"
                color="primary"
                (click)="addNewInterval(daysOfWeekOptions[i], dayForm)">
                <span>Adicionar intervalo</span>
              </button>
            </div>
            <div *ngIf="daysOfWeekOptions[i].existingInterval" 
            class="rb-padding-10x0 rb-hour-existing" fxLayout="row"
            fxFlex="100" fxLayoutAlign="center center">
              <mat-icon>error_outline</mat-icon><span>Já existe um intervalo entre estes horários </span>
            </div>
          </div>
        </div>
        <div class="rb-padding-10x0" fxLayout="row" fxLayoutAlign="center center">
          <mat-list #rbListHours
          fxFlex="100"
          class="rbList"
          color="primary">
            <mat-list-item *ngFor="let hour of daysOfWeekOptions[i].hours; let indexHour = index;">
              <p matLine class="rbListSubtitle"> 
                <span>De </span> 
                <span class="fontWeight500">{{hour.start_time | slice:0:5}}</span>
                <span>às </span> 
                <span class="fontWeight500">{{hour.end_time | slice:0:5}}</span>
              </p>
              <button mat-icon-button class="rbList-actions-button"
                (click)="removeHourInterval(daysOfWeekOptions[i], indexHour)">
                  <mat-icon>delete</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
          <button [disabled]="daysOfWeekOptions[i].hours.length == 0" mat-icon-button class="margin-left" color="primary"
            (click)="mirrorHourInterval(daysOfWeekOptions[i], dayForm)">
              <mat-icon>content_copy</mat-icon><span>Copiar diariamente</span>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
