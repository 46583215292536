import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import 'hammerjs';
import localePt from '@angular/common/locales/pt';
import {
  CrmClientLinkValidatorPageComponent
} from './modules/client/crm-client-link-validator/crm-client-link-validator-page/crm-client-link-validator-page.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CommonModule, registerLocaleData} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import { EditorModule } from "@tinymce/tinymce-angular";

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material';
import {MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from '@angular/material';

import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { RbNoticeBarComponent } from './shared/components/notice/notice.component';
import { MainBarComponent } from './shared/components/main-bar/main-bar.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { GeneralSettingsModule } from './modules/admin/general-settings/general-settings.module';
import { ExceptionalHoursModule } from './modules/admin/exceptional-hours/exceptional-hours.module';
import { SchedulesModule } from './modules/admin/schedules/schedules.module';
import { UserPersonalDataModule } from './modules/client/user-personal-data/user-personal-data.module';
import { ClientManageDomainModule } from './shared/components/client-manage-domain/client-manage-domain.module';

import {NgxMaskModule, IConfig} from 'ngx-mask';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ConfirmActionDialogComponent } from './shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { getDutchPaginatorIntl } from 'rubeus-components/';
import { ClientMainBarComponent } from './shared/components/client-main-bar/client-main-bar.component';
import { AdminRootComponent } from './modules/admin/admin-root/admin-root.component';
import { ClientRootComponent } from './modules/client/client-root/client-root.component';
import { ClientLoaderComponent } from './shared/components/client-loader/client-loader.component';
import { ClientLoginDialogComponent } from './shared/components/client-login-dialog/client-login-dialog.component';
import { ForgotPasswordDialogComponent } from './shared/components/forgot-password-dialog/forgot-password-dialog.component';
import { ConfirmCancelDialogComponent } from './modules/client/my-schedules/confirm-cancel-dialog/confirm-cancel-dialog.component';
import {
  UnavailableTimeMessageDialogComponent
} from './modules/client/flow-scheduling/flow-scheduling-page/components/unavailable-time-message-dialog/unavailable-time-message-dialog.component';
import { LgpdTermsDialogComponent } from './shared/components/lgpd-terms-dialog/lgpd-terms-dialog.component';
import { ClientDataLoginComponent } from './shared/components/client-data-login/client-data-login.component';
import { RbCustomFieldsComponent } from './shared/components/rb-custom-fields/rb-custom-fields.component';

registerLocaleData(localePt);
export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CrmClientLinkValidatorPageComponent,
    AppComponent,
    RbNoticeBarComponent,
    MainBarComponent,
    LoaderComponent,
    PageNotFoundComponent,
    ConfirmActionDialogComponent,
    ClientMainBarComponent,
    AdminRootComponent,
    ClientRootComponent,
    ClientLoaderComponent,
    ClientLoginDialogComponent,
    ForgotPasswordDialogComponent,
    ConfirmCancelDialogComponent,
    UnavailableTimeMessageDialogComponent,
    LgpdTermsDialogComponent,
    ClientDataLoginComponent
  ],
  imports: [
    MatDatepickerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    GeneralSettingsModule,
    ExceptionalHoursModule,
    SchedulesModule,
    UserPersonalDataModule,
    ClientManageDomainModule,

    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    EditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxMaskModule.forRoot(options),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  entryComponents: [
    RbNoticeBarComponent,
    ConfirmActionDialogComponent,
    ClientLoginDialogComponent,
    ClientDataLoginComponent,
    ForgotPasswordDialogComponent,
    ConfirmCancelDialogComponent,
    UnavailableTimeMessageDialogComponent,
    LgpdTermsDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
