<form class="rb-default-dialog">
    <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">Atualização de dados</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
        <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
        </button>
    </div>
    </div>
    <mat-dialog-content class="rb-dialog-content rb-has-overflow">
        <div class="rb-padding-14x24" fxLayout="column">
          <div fxLayout="row" fxFlex="100%">
              <label class="rb-label-black">Olá {{dataFromCrm.name}}, algumas informações foram alteradas!</label>
          </div>
          <div fxLayout="row" fxFlex="100%">
            <label class="rb-label-black-title">Alterações no cadastro:</label>
        </div>
        <div fxLayout="row" fxFlex="100%"
        *ngFor="let data of stringDataChanged; let i=index;">
            <label class="rb-label-info"><strong>{{dataType[i]}}</strong>: {{data}}</label>
        </div>
        
        <div fxLayout="row" fxFlex="100%">
            <label class="rb-label-black">A partir de agora, você utilizará os dados informados para efetuar login na agenda.</label>
        </div>
        </div>
      </mat-dialog-content>
      <div mat-dialog-actions class="rb-dialog-actions">
        <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="center center">
          <button mat-raised-button
            color="primary"
            (click)="updateData()">
            <span>Continuar</span>
          </button>
        </div>
      </div>
</form>