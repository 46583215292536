import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
@Injectable({
  providedIn: 'root',
})
export class SettingsServicesModule {
  public refresh = new Subject<any>();
  public settingsServicesData: any = {};

  settingsServicesBackup: any = {};

  public settingsOptions = {
    duration: [],
    limitAdvanceScheduling: [],
    minimumAdvanceScheduling: [],
    minimumCancellationPeriod: [],
  };

  setInitialData(data) {
    if (data) {
      this.settingsServicesData = data;
      this.settingsServicesBackup = JSON.parse(
        JSON.stringify(this.settingsServicesData)
      );
    }
  }

  getData() {
    return this.settingsServicesData;
  }

  refreshData() {
    this.settingsServicesData = JSON.parse(
      JSON.stringify(this.settingsServicesBackup)
    );
    this.refresh.next(this.settingsServicesData);
  }

  getRefreshData(): Observable<any> {
    return this.refresh.asObservable();
  }

  setSettingsOptions(data) {
    this.settingsOptions = data;
  }
  getSettingsOptions() {
    return this.settingsOptions;
  }
}
