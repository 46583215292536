import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../../../../core/http/request';
import { AdminHttpService } from '../../../../../core/http/admin-http.service';
import { NoticeComponent } from '../../../../../shared/components/notice/notice.component';
import { LoaderService } from '../../../../../shared/components/loader/loader.service';
import { SettingsIdentityModule } from '../components/settings-identity/settings-identity.module';

@Injectable({
  providedIn: 'root'
})
export class GetGeneralIdentityDataService extends Request implements Resolve<GetGeneralIdentityDataService> {

  constructor(
    private settingsIdentityModule: SettingsIdentityModule,
    private http: AdminHttpService,
    private loaderService: LoaderService,
    protected notice: NoticeComponent,
  ) {
    super();
  }

  private param = '';

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    // this.param = route.paramMap.get('idCanalPersonalizado');

    this.url = 'general-settings/get-identity';

    return this.http.get(this)
      .then((data) => this.settingsIdentityModule);
  }

  successAction(data) {
    if (data && data.success) {
      this.settingsIdentityModule.setInitialData(data.identitySetting);
      // this.settingsIdentityModule.setInitialData(data.dados);
    }
    this.loaderService.setChangesLoaderState(false);
    return this.settingsIdentityModule.getData();
  }


  errorAction(error) {
    this.loaderService.setChangesLoaderState(false);
    if (error.error) {
      this.notice.showMessageError(error.error);
    } else if (error.erro) {
      this.notice.showMessageError(error.erro);
    } else if (error.errors) {
      this.notice.showMessageError(error.errors);
    } else {
      this.notice.showMessageError('Algo inesperado ocorreu, estamos tentando conectar com o servidor!');
    }
  }
}
