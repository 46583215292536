<div class="rb-default-dialog">
  <div mat-dialog-title class="rb-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="rb-dialog-titleContainer">
        <h2 fxFlex="">{{dialogMessages.mainTitleDialog}}</h2>
    </div>
    <div class="rb-dialog-closeButtonContainer">
      <button mat-icon-button [mat-dialog-close]="false" title="Cancelar e fechar">
        <mat-icon aria-label="Fechar">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="rb-dialog-content">
    <div class="rb-padding-5x24 confirm-action-message">
      <p>{{dialogMessages.messageDialog}}</p>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="rb-dialog-actions">
    <div class="rb-padding-10x0" fxLayout="row" fxFlex="" fxLayoutAlign="end center">
      <button mat-stroked-button 
        [mat-dialog-close]="false"
        color="primary"
        class="rb-btn-upload">
        <span>Cancelar</span>
      </button>
      <div class='rbButtonsDivisor'></div>
      <button mat-raised-button
        color="primary"
        [mat-dialog-close]="true">
        <span>Continuar</span>
      </button>
    </div>
  </div>
</div>

