import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, FormArray, FormBuilder} from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import * as moment from 'moment';

@Component({
  selector: 'app-week-days-tabs',
  templateUrl: './week-days-tabs.component.html',
  styleUrls: ['./week-days-tabs.component.scss']
})
export class WeekDaysTabsComponent implements OnInit, AfterViewInit {

  @Input() parentScope: any;
  @Input() initialWeekData: any;
  @ViewChild('addIntervalButton', {static: false}) addIntervalButton: ElementRef;

  public disabled = false;
  public hourInterval: any = {start_time: '', end_time: ''};
  public hourIntervalForm: FormGroup;
  public week = new Subject<any>();
  public hasChange = new Subject<any>();


  public existingInterval: any = 0;

  public daysOfWeekOptions: any[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.initOptions();
    this.hourIntervalForm = new FormGroup(
      {
        daysForm: this.formBuilder.array([])
      }
    );
    this.buildFormArray();
  }


  ngOnInit() {
    // if (this.parentScope && this.parentScope.week) {
    //   console.log('opaaaasasasasasasasasasasas', this.parentScope.week);
    // }
  }

  initOptions() {
    this.daysOfWeekOptions = [
      {id: '2', title: 'Segunda', formName: 'monday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '3', title: 'Terça', formName: 'tuesday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '4', title: 'Quarta', formName: 'wednesday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '5', title: 'Quinta', formName: 'thursday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '6', title: 'Sexta', formName: 'friday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '7', title: 'Sábado', formName: 'saturday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
      {id: '1', title: 'Domingo', formName: 'sunday', active_day: false,
      hourInterval: {start_time: '', end_time: ''}, hours: new Array(), existingInterval: 0},
    ];
  }

  ngAfterViewInit() {
    this.buildInitialValues();
  }

  buildInitialValues() {
    if (this.initialWeekData && this.initialWeekData.length) {
      const initialWeekData = JSON.parse(JSON.stringify(this.initialWeekData));
      if (initialWeekData) {
        for (const item of initialWeekData) {
          for (const day of this.daysOfWeekOptions) {
            if (item.id == day.id) {
              day.active_day = item.active_day;
              day.hours = item.hours;
              day.existingInterval = item.existingInterval;
            }
          }
        }
      }
    }
    setTimeout(() => {
      this.setIntervalsCount();
    }, 100);
  }

  buildFormArray() {
    if (this.daysOfWeekOptions && this.daysOfWeekOptions.length) {
      for (const day of this.daysOfWeekOptions) {
        this.hourIntervalForm.get('daysForm')['controls'].push(this.buildObjectForm(day));
      }
    }
  }

  buildObjectForm(day) {
    const formDay = new FormGroup(
      {
        start_time: new FormControl('', []),
        end_time: new FormControl('', []),
        active_day: new FormControl('', []),
      }
    );

    formDay.valueChanges.subscribe(value => this.controlBetweenHours(value, day));
    return formDay;
  }

  public comparisonValidator(dayForm): ValidatorFn {
    return (): ValidationErrors => {
       const control1 = dayForm.controls[`start_time`];
       const control2 = dayForm.controls[`end_time`];
       if (control1 && control2) {
        if (control1.value && control2.value) {
          if (this.hourValidator(control1) && this.hourValidator(control2)) {
            if (moment(control2.value, 'HH:mm').isAfter(moment(control1.value, 'HH:mm'))) {
              control1.setErrors(null);
              control2.setErrors(null);
            } else {
              control1.setErrors({notBefore: true});
              control2.setErrors({notAfter: true});
            }
          } else {
            this.hourValidator(control1);
            this.hourValidator(control2);
          }
        } else {
          this.hourValidator(control1);
          this.hourValidator(control2);
        }
      }
       return;
    };
  }

  hourValidator(control) {
    if (moment(control.value, 'HH:mm').isValid()) {
      return true;
    } else {
      control.setErrors({invalidHour: true});
    }
  }

  addIntervalValidation(dayForm) {
    dayForm.setValidators(this.comparisonValidator(dayForm));
  }

  addNewInterval(dayObject, dayForm) {
    const hour = {
      start_time: moment(dayObject.hourInterval.start_time, 'HH:mm').format('HH:mm'),
      end_time: moment(dayObject.hourInterval.end_time, 'HH:mm').format('HH:mm'),
    };
    dayObject.hours.push(hour);
    dayForm.clearValidators();
    dayForm.controls.start_time.reset();
    dayForm.controls.end_time.reset();
    dayObject.hours = this.sortArray(dayObject.hours);
    if (dayObject.hours && dayObject.hours.length === 1) {
      dayObject.active_day = true;
    }
    this.setHasChangeValue(1);
    this.setIntervalsCount();
  }

  controlBetweenHours(formReference, dayObject) {
    dayObject.existingInterval = 0;
    if (moment(formReference.start_time, 'HH:mm').isValid() && moment(formReference.end_time, 'HH:mm').isValid()) {
      if (dayObject.hours && dayObject.hours.length) {
        for (const hour of dayObject.hours) {
          if (
            moment(formReference.start_time, 'HH:mm').isBetween(
              moment(hour.start_time, 'HH:mm'), moment(hour.end_time, 'HH:mm')
            ) ||
            moment(formReference.end_time, 'HH:mm').isBetween(
              moment(hour.start_time, 'HH:mm'), moment(hour.end_time, 'HH:mm')
            ) ||
            moment(hour.start_time, 'HH:mm').isBetween(
              moment(formReference.start_time, 'HH:mm'), moment(formReference.end_time, 'HH:mm')
            ) ||
            moment(hour.end_time, 'HH:mm').isBetween(
              moment(formReference.start_time, 'HH:mm'), moment(formReference.end_time, 'HH:mm')
            ) ||
            (
              moment(hour.start_time, 'HH:mm').isSame(moment(formReference.start_time, 'HH:mm')) &&
              moment(hour.end_time, 'HH:mm').isSame(moment(formReference.end_time, 'HH:mm'))
            )
          ) {
            dayObject.existingInterval = 1;
          }
        }
      }
    }
  }


  removeHourInterval(dayObject, index) {
    if (dayObject && dayObject.hours) {
      dayObject.hours.splice(index, 1);
    }
    if (dayObject && !dayObject.hours.length) {
      dayObject.active_day = false;
    }
    this.setHasChangeValue(1);
    this.setIntervalsCount();
  }

  mirrorHourInterval(weekDay, dayForm){
    var hours = new Array();
    weekDay.hours.map((item) => {
      hours.push({
        start_time: moment(item.start_time, 'HH:mm').format('HH:mm'),
        end_time: moment(item.end_time, 'HH:mm').format('HH:mm')
      });
    });
    for(var i = 0; i < 5; i++){
      var day = this.daysOfWeekOptions[i];
      hours.map((item) => {
        if(weekDay.id != day.id){
          if(day.hours.length > 0){
            var push = true;
            day.hours.map((itemDay) => {
              if(!this.betweenInterval(item, itemDay)){
                push = false;
              }
            })
            if(push){
              day.hours.push(item);
            }
          } else {
            day.hours.push(item);
            day.active_day = true;
          }
        }
      })
    }
    dayForm.clearValidators();
    dayForm.controls.start_time.reset();
    dayForm.controls.end_time.reset();
    this.setHasChangeValue(1);
    this.setIntervalsCount();
  }

  betweenInterval(item, itemDay){
    if(moment(item.start_time, 'HH:mm').isBetween(moment(itemDay.start_time, 'HH:mm'), moment(itemDay.end_time, 'HH:mm'))
      ||moment(item.end_time, 'HH:mm').isBetween(moment(itemDay.start_time, 'HH:mm'), moment(itemDay.end_time, 'HH:mm'))
      ||moment(itemDay.start_time, 'HH:mm').isBetween(moment(item.start_time, 'HH:mm'), moment(item.end_time, 'HH:mm')) 
      ||moment(itemDay.end_time, 'HH:mm').isBetween(moment(item.start_time, 'HH:mm'), moment(item.end_time, 'HH:mm'))
      ||(moment(itemDay.start_time, 'HH:mm').isSame(moment(item.start_time, 'HH:mm')) && moment(itemDay.end_time, 'HH:mm').isSame(moment(item.end_time, 'HH:mm')))
      ){
        return false;
      }
    return true;
  }

  sortArray(dates: any) {
    if (dates && dates.length) {
      dates.sort(this.compare);
    }
    return dates;
  }

  compare(a, b) {
    if (moment(a.start_time, 'HH:mm').isAfter(moment(b.start_time, 'HH:mm'))) {
      return 1;
    }
    if (moment(a.start_time, 'HH:mm').isBefore(moment(b.start_time, 'HH:mm'))) {
      return -1;
    }
    return 0;
  }

  public getDaysOfWeekOptions: any =  () => {
    return this.daysOfWeekOptions;
  }

  setIntervalsCount() {
    this.parentScope.weekDaysCheckedCount = 0;
    if (this.daysOfWeekOptions && this.daysOfWeekOptions.length) {
      for ( const day of this.daysOfWeekOptions) {
        if (day.active_day) {
          this.parentScope.weekDaysCheckedCount++;
        }
      }
    }
    this.setUpdatedWeek(this.daysOfWeekOptions);
  }

  setUpdatedWeek(value: any) {
    this.week.next(value);
  }

  getUpdatedWeek(): Observable<any> {
    return this.week.asObservable();
  }

  submitEvent(e, button, elementForm) {
    if (e.keyCode === 13) {
      button._elementRef.nativeElement.click();
      elementForm.focus();
    }
  }

  setHasChangeValue(value: any) {
    this.hasChange.next(value);
  }

  getHasChangeValue(): Observable<any> {
    return this.hasChange.asObservable();
  }

}
