import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NoticeComponent } from '../notice/notice.component';

@Component({
  selector: 'app-schedule-link-dialog',
  templateUrl: './schedule-link-dialog.component.html',
  styleUrls: ['./schedule-link-dialog.component.scss']
})
export class ScheduleLinkDialogComponent implements OnInit {

  // @ViewChild('scheduleLinkUrl', {static: false}) scheduleLinkUrlElement: ElementRef;

  public dialogDataMessage: any = {
    link: '',
  };

  constructor(
    private dialogRef: MatDialogRef<ScheduleLinkDialogComponent>,
    protected notice: NoticeComponent,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
  }

  ngOnInit() {
    this.dialogDataMessage = this.dataDialog;
  }

  copyLink(elm) {
    elm.select();
    document.execCommand('copy');
    elm.blur();
    this.notice.showMessageSuccess('Link copiado');
  }

}
