import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class ExceptionalHoursRegisterPageModule {

  public exceptionalHoursData: any = {
    employees: [],
    hour_intervals: []
  };

  public daysOfWeekOptions: any[] = [];

  public selectOptions = {
    schedule: [],
    services: [],
    timeAction: [],
    employees: [],
    hour_intervals: []
  };

  setInitialData(data) {
    if (data) {
      this.exceptionalHoursData = data;
    }
    if (!this.exceptionalHoursData.options_when_exactly_exceptional_times_id) {
      this.exceptionalHoursData.options_when_exactly_exceptional_times_id = 1;
    }
    this.buildDaysOfWeekOptions();
  }

  getData() {
    return this.exceptionalHoursData;
  }

  setSelectOptions(data) {
    this.selectOptions = data;
  }

  getSelectOptions() {
    return this.selectOptions;
  }

  getDaysOfWeekOptions() {
    return this.daysOfWeekOptions;
  }

  setActionOptions(data) {
    this.selectOptions.timeAction = data;
  }
  setScheduleOptions(data) {
    this.selectOptions.schedule = data;
  }
  setAttendantOptions(data) {
    this.selectOptions.employees = data;
  }

  getActionOptions() {
    return this.selectOptions.timeAction;
  }

  buildDaysOfWeekOptions() {
    this.daysOfWeekOptions = [
      {id: 1, title: 'Domingo', formName: 'sunday', value: null},
      {id: 2, title: 'Segunda feira', formName: 'monday', value: null},
      {id: 3, title: 'Terça feira', formName: 'tuesda', value: null},
      {id: 4, title: 'Quarta feira', formName: 'wednesday', value: null},
      {id: 5, title: 'Quinta feira', formName: 'thursday', value: null},
      {id: 6, title: 'Sexta feira', formName: 'friday', value: null},
      {id: 7, title: 'Sábado', formName: 'saturday', value: null},
    ];
  }
}
